/* Draggable */

.draggable {
	position: fixed;
	width: 31%;
	bottom: 10px;
	right: 180px;
	animation: move-to-corner 0.25s ease;
	display: flex;
	flex-direction: column;
}

.draggable div {
	width: 100%;
}

@keyframes move-to-corner {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}

/* Lightbox */


.shade {
	background-color: rgba(25, 25, 25, 0.68);
	position: fixed;
	width: 100vw;
	height: 100vh;
	bottom: 0px;
	left: 0px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Josefin Sans', sans-serif;
	overflow: hidden;
	z-index: 4;
}

.shade .section {
	max-width: 60vw;
	background-color: lightgray;
	border-radius: 40px;
	overflow-y: auto;
	overflow-x: hidden;
	animation: vertical-split 0.5s ease;
	color: black;
	text-shadow: none;
}
@keyframes vertical-split {
	from {
		max-height: 0px;
		overflow: hidden;
	}
	to {
		max-height: 100%;
		overflow: hidden;
	}
}
@media only screen and (max-width: 700px) {
	.shade .section {
		max-width: unset;
		max-height: 60vh;
	}
}
.lightBoxContent {
	box-sizing: border-box;
	padding: 25px;
	word-break: break-word;
}
.lightBoxContent button, .lightBoxContent input, .lightBoxContent textarea {
	border: none;
	outline: none;
	color: var(--col-accent1);
	font: unset;
	border-radius: 5px;
	cursor: pointer;
}
.lightBoxContent input, .lightBoxContent textarea {
	margin: 10px;
	cursor: unset;
	width: calc(100% - 20px);
	box-sizing: border-box;
}
.lightBoxContent textarea {
	min-height: 20vh;
}
.lightBoxContent button:hover {
	background-color: var(--col-accent1);
	color: white;
}

.lightBoxTitle {
	display: flex;
	justify-content: center;
	font-weight: 600;
	background-color: orange;
	padding: 5px;
	cursor: pointer;
}
.lightBoxTitle.error {
	background-color: var(--col-alert1);
}
.lightBoxTitle svg, .shade img {
	height: 100%;
	max-height: 1em;
}

div.lightBoxTitle:hover {
	background-color: var(--col-alert1);
}
div.lightBoxTitle.error:hover {
	background-color: yellow;
}

.lightBoxTitle > * {
	padding: 5px;
}

.dateTime {
	background-color: #f0f0f0;
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	box-sizing: border-box;
}
.footer {
	margin: 5px;
}
.resolve, .reject {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	padding: 5px;
	width: 100%;
	cursor: pointer;
}
.resolve:hover, .reject:hover {
	color: white;
	opacity: 0.68;
}
.reject {
	background-color: var(--col-alert1);
}
.resolve {
	background-color: lightgreen;
}
.error {
	color: red;
	width: 100%;
}

/* Profiles */

.divide {
	display: grid;
	grid-template-columns: 1fr auto auto 1fr;
	column-gap: 20px;
	font-weight: 300;
	align-items: center;
	cursor: pointer;
	font-family: Josefin Sans, sans-serif;
	margin: 20px 20px 0 20px;
}

.divide > hr {
	height: 1px;
	width: 100%;
	background-color: darkgrey;
	border-radius: 2px;
}
.divide:hover > hr {
	background-color: lightgrey;
}
.divide:hover {
	color: var(--col-accent2-hover);
}
.divide > svg {
	height: 0.68em;
	width: 0.68em;
	transition: transform 0.333s ease;
}
.divide:not(.collapsed) > svg {
	transform: rotate(0deg);
}
.divide.collapsed > svg {
	transform: rotate(450deg);
}

.divide {
	grid-column: 1 / -1;
}