.container {
    max-width: 1180px;
	margin: 0 20px 50px;
	box-sizing: border-box;

	display: grid;
	column-gap: 25px;
	row-gap: 25px;
	font-family: 'Josefin Sans', sans-serif;
}

/* Depth: 2 */

.userTable {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	justify-content: center;
	align-items: center;
}
@media only screen and (max-width: 1200px) { .userTable { grid-template-columns: repeat(3, 1fr) }}
@media only screen and (max-width: 900px) { .userTable { grid-template-columns: repeat(2, 1fr) }}
@media only screen and (max-width: 600px) { .userTable { grid-template-columns: repeat(1, 1fr) }}

/* Depth: 3 */

.compactContainer {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 5vw;
	position: relative;
}
.compact {
	display: grid;
	row-gap: 5px;
}

.preview {
	position: fixed;
	left: 55%;
	z-index: 2;
}
@keyframes scroll-up {
	0% {
		top: 18vh
	}
	80% {
		top: 7vh
	}
	100% {
		top: unset;
		bottom: 1vh;
	}
}

/* Depth 3 */

.title {
	margin: 10px 0;
	font-size: xx-large;
}
.title, .title .menu {
	display: grid;
	grid-template-columns: auto auto 1fr auto;
	align-items: center;
	justify-items: center;
	grid-auto-flow: column;
	column-gap: 20px;
	align-items: center;
}
.active {
	opacity: 0.3;
	cursor: pointer;
}
.inactive {
	color: #43853d;
}

.menu label {
	font-size: small;
}
:global(.light) {
	font-weight: 300;
}

/* Search Bar */
.searchBar {
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent3);
	align-items: center;
	justify-content: stretch;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	padding: 5px 10px;
}

.searchBarDiv {
	display: grid;
	grid-template-columns: minmax(auto, 400px);
}

.searchBar > * {
	height: 100%;
	display: flex;
	align-items: center;
}

.searchBar * {
	background-color: transparent;
}

.searchBar form {
	padding: 2px 0;
}

.searchBar div, .searchBar form, .searchBar input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.searchBar input, .searchBar input:focus {
	border: none;
	margin: 5px 0;
}

.searchIcon {
	font-size: medium;
}