.canvas {
	width: 100%;
	height: 100%;
}

div.column {
	font-family: 'Josefin Sans', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	align-content: stretch;
	margin: auto;
	min-width: 500px;
	min-height: 400px;
	background: transparent;
}
.column:hover, .foreground:hover {
	top: 0px;
}
.frame {
	display: flex;
	border-radius: 15px;
	height: inherit;
	align-items: stretch;
	padding: 0px;
	background-color: var(--container-bg);
}

.loginSection {
	position: relative;
	border-radius: 15px;
	height: 100%;
	min-height: 400px;
	align-self: stretch;
	justify-content: center;
	display: grid;
	grid-template-columns: 10ch 1fr 10ch;
	grid-template-areas:
		'login . register';
	width: 100%;
}

div.forgottenContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	box-sizing: border-box;
	animation: fade-in 0.68s ease both;
}

div.forgotten {
	background-color: transparent;
	color: grey;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	height: 100%;
	font-size: 16px;
	cursor: pointer;
}

div.forgotten:first-child {
	border-right: 1px solid grey;
}

.form {
	display: grid;
	grid-template-rows: repeat(4, 1fr);
	width: 100%;
}

.form > .input {
	font-family: 'Josefin Sans', sans-serif;
	width: 100%;
	overflow: hidden;
	font-size: 16px;
	margin: 0px;
	box-sizing: border-box;
	padding: 10px 14px;
	border: none;
	display: flex;
}
.input:first-child, .input:nth-child(2) {
	border-top: 1px solid grey;
}
.input:nth-child(2) {
	border-bottom: 1px solid grey;
}
.input:nth-child(3) {	
	animation: fade-in 0.68s ease both;
}
.input.back, .input.text {
	justify-content: center;
	padding: 6px;
}
.input.back {	
	cursor: pointer;
}

.foreground button[type='submit'].submit {
	font-family: 'Josefin Sans', sans-serif;
	width: 100%;
	justify-content: center;
	display: flex;
	padding: 10px;
	margin: 0px;
	box-sizing: border-box;
	border: none;
	border-radius: 15px;
	color: white;
	background-color: #1e1e1e;
	cursor: pointer;
	-webkit-transition: all 0.25s ease-out;
	-moz-transition: all 0.25s ease-out;
	 -ms-transition: all 0.25s ease-out;
	  -o-transition: all 0.25s ease-out;
		 transition: all 0.25s ease-out;
}
.foreground input.submit:hover {
	background-color: #43853d;
}

.foreground {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: flex-end;;
	align-items: center;
	position: absolute;
	height: 100%;
	width: calc(100% - 10ch);
	opacity: 1;
	margin: 0px;
	background-color: lightgrey;
	box-sizing: border-box;
}

div.flip {
	display: flex;
	flex-direction: column;
	border-radius: 0px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
}
.flip.loginButton {
	grid-area: login;
}
.flip.registerButton {
	grid-area: register;
}

@media only screen and (max-width: 700px) {
	div.column {
		min-width: 90vw;
		display: grid;
	}
	.loginSection {		
		grid-template-columns: 100%;
		grid-template-rows: 5ch auto 5ch;
		grid-template-areas:
			'login'
			'.'
			'register';
	}
	div.foreground {
		grid-area: 1 / span 2;
		width: 100%;
		height: calc(100% - 5ch);
	}
	.forgotten {		
		font-size: min(16px, 4vw);
	}
	div.flip {
		grid-area: login;
	}
}

.foreground input {
	background-color: transparent;
}

.loginForeground {
	overflow: hidden;
	top: 0px;
	left: 0px;
	-webkit-transition: all 0.68s ease-out;
	-moz-transition: all 0.68s ease-out;
	 -ms-transition: all 0.68s ease-out;
	  -o-transition: all 0.68s ease-out;
		 transition: all 0.68s ease-out;
}

.registerForeground {
	left: 10ch;
	-webkit-transition: all 0.68s ease-out;
	-moz-transition: all 0.68s ease-out;
	 -ms-transition: all 0.68s ease-out;
	  -o-transition: all 0.68s ease-out;
		 transition: all 0.68s ease-out;
}

@media only screen and (max-width: 700px) {
	.registerForeground {
		left: unset;
		top: 5ch;
	}
	div.flip {
		flex-direction: row;
	}
}

.flip > div {
	display: flex;
	justify-content: center;
	align-items: center;
    margin: 2px;
}

div.messageBar {
	display: flex;
	width: 100%;
    padding: 5px 0 5px 5px;
    box-sizing: border-box;
    border: 2px #252526 solid 0.0;
	box-shadow: none;
	justify-content: center;
	color: red;
}

@keyframes fade-in {
	from {
		opacity: 0.0;
	}
	to {
		opacity: 1.0;
	}
}

/* CROWN */


div.logo {
	width: 50%;
	max-width: 200px;
	display: grid;
	position: relative;
	grid-template: 100% / 100%;
}

img.staticLogo {
	width: 100%;
	position: relative;
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
}

img.loadingGif {
    position: absolute;
    width: 66.6%;
    max-width: 200px;
    transform: rotate(-33deg);
    opacity: 1;
    left: 6%;
    top: 15%;
	position: relative;
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
}

img.crownOutline {
    position: absolute;
    width: 71%;
    max-width: 213px;
    transform: rotate(-32deg);
    opacity: 1;
    z-index: 1;
    left: 5%;
    top: 13%;
	position: relative;
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
}

@media screen {
	.logo {
		animation: fade-in 1 0.5s linear;
	};
	.loadingLine {
		animation: grow-right infinite 0.68s linear;
	}
}

@keyframes fade-in {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}