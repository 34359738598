/* Depth: 1 */
.container {
	width: 100%;
	position: relative;
}
.stage {
	width: 100%;
	overflow-x: scroll;
	position: relative;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}
.stage::-webkit-scrollbar {
	display: none;
}

/* Depth: 2 */
.scroll {
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	z-index: 2;
	padding: 5px;
	box-sizing: border-box;
	backdrop-filter: blur(4px);
	background-color: rgba(150, 150, 150, 0.1);
	opacity: 0.9;
	cursor: pointer;
	transition: opacity 0.333s ease;
}
.scroll:not(:hover) {
	opacity: 0.4;
}
#left {
	left: 0px;
}
#right {
	right: 0px;
}
.columns {
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: auto 1fr;
	column-gap: 60px;
	align-items: center;
}

/* Depth: 3 */

.roundNumber {
	text-align: center;
	padding: 10px;
	font-weight: bold;
	font-size: 18px;
}
.column {
	display: grid;
	grid-auto-rows: auto;
	height: 100%;
	box-sizing: border-box;
	font-size: small;
	font-family: "Roboto","Source Sans Pro","Open Sans","San Francisco",Helvetica,Arial,sans-serif;
}

/* Depth: 4 */
.matchUp {
	display: grid;
	row-gap: 5px;
	grid-template-rows: 1fr 1fr;
	height: max-content;
	background-color: rgb(150, 150, 150, 0.31);
	border-radius: 15px;
	padding: 5px;
	position: relative;
}
.spacer {
	min-height: 20px;
}

/* Depth: 4.1 */
.poolTitle {
	position: relative;
	font-weight: bold;
	padding: 20px 0px 0px 0px;
	left: calc(100% - 6ch);
	display: flex;
	width: 20ch;
	justify-content: center;
}
.poolTitle:not(.visible ){
	visibility: hidden;
	left: 0px;
}

/* Depth: 5 */
.white, .black {
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
	background-color: rgb(200, 200, 200, 0.70);
	border-radius: 15px;
	padding-left: 8px;
	box-sizing: border-box;
	position: relative;
}
.white svg, .black svg {
	font-size: x-small;
}

/* Depth: 6 */
div.playerInfo {
	text-align: left;
	align-items: center;
	box-sizing: border-box;
	padding-right: 45px;
	white-space: nowrap;
	padding: 0 8px;
}
div.playerInfo:hover {
	background-color: unset;
}

.matchUp div[class^=components_resultsBridge] {
	width: unset;
	align-self: center;
	grid-auto-flow: row;
	position: absolute;
	right: 1.5ch;
	z-index: 2;
	font-size: small;
	row-gap: 10%;
}
.matchUp div[class^=components_resultsBridge]:hover {
	background-color: unset;
}
.matchUp div[class^=components_neutral], .playerInfo div[class^=components_centre] {
	display: none;
}
.matchUp div[class^=components_neutral], .playerInfo div[class^=components_centre] {
	display: none;
}

.score {
	background-color: #6cd46380;
	padding: 0 12px;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.score.win {
	background-color: #91ff00de;
}

/* Lines */

.winner, .all {
	width: 5px;
	height: 5px; 
	border-radius: 50%;
	background-color: darkblue;
	content: ' ';
	z-index: 1;
	position: absolute;
}
.all {
	left: -1ch;
}
.winner {
	right: -1ch;
}

.connectors {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 0;
}

.line {
	stroke-width: 2px;
	stroke: green;
	fill: transparent;
}