.container {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 40%);
	column-gap: 5%;
    row-gap: 30px;
    margin: 20px 0;
    width: 100%;
}

@media screen and (max-width: 700px) {
	.container {
		grid-template-columns: 80%;
	}
}

.results {
	grid-column: 1 / span 2;
	display: flex;
	justify-content: center;
	align-items: center;
}

.results > table {
	width: 100%;
}
.results > table tr > td > * {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	height: 1.5em;
}
.results > table tr > td > .score {
	font-weight: bold;
}

.wrapper {
    align-self: stretch;
	display: grid;
	grid-template-rows: 20px 1fr;
	grid-auto-flow: row;
	height: 100%;
	width: 100%;
	justify-content: center;
}

.wrapper > div, .wrapper > div > div {
	height: 100%;
	overflow-y: hidden;
}

.boardlabel {
	height: 100%;
}

.boardLabel > span {
    margin: 0 5px;
}

.gameViewWrapper {
	grid-column: 1 / span 1;
	grid-row: 2 / span 1;
}
.gameViewWrapper:global(.hidden) {
	visibility: hidden;
}

.board {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    color: black;
    background-color: blue;
    animation: fade-in 0.5s ease;
}

@keyframes fade-in {
    from {
        opacity: 0%
    }
    to {
        opacity: 100%
    }
}

.row {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    color: black;
    background-color: blue;
}

.row:nth-child(odd) div:nth-child(even), .row:nth-child(even) div:nth-child(odd) {
    background-color: lightgreen;
}

.square {
    display: flex;
    align-items: center;
    justify-content: center;
}

.square.W {
    color: white
}

.square.B {
    color: black
}

.square > * {
    width: 61.8%;
    height: 61.8%;
}

.null {
	opacity: 0.2;
}

.active {
	cursor: pointer
}

.loading > svg {
	animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

div.button, a.button {
	border-radius: 5px;
}