/* Depth: 1 */

.nav {
	margin-top: 10vh;
}

.stage {
	position: relative;
	display: grid;
	column-gap: 20px;
	row-gap: 20px;
	grid-template-columns: 25% 1fr;
	grid-template-rows: 1fr auto;
	grid-template-areas:
		'image name'
		'extra modes';		
}

/* Redesigned Nav */
.infoBox, .linkButtons {
	display: none!important;
}

@media only screen and (max-width: 700px) {
	.stage {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-template-areas:
			'image'
			'extra'
			'name'
			'modes'
	}
	div.profilePicture {
		grid-area: image;
	}
}

.nameContainer {
	grid-area: name;
	display: flex;
	align-items: center;
}

.name {
	background-color: rgba(150, 150, 150, 0.68);
	border-radius: 15px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 24px;
	padding: 7px;
}

.infoBox {
	grid-area: info;
	display: grid;
	grid-template-columns: auto auto;
	grid-auto-rows: auto;
	grid-auto-flow: column;
	column-gap: 10px;
	padding: 10px;
	background-color: rgb(150, 150, 150, 0.31);
	overflow: hidden;
	border-radius: 15px;
	font-size: 16px;
}
.infoBox > div {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: repeat(4, 1fr);
	column-gap: 10px;
	row-gap: 5px;
}
@media only screen and (max-width: 700px) {
	.infoBox {
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		font-size: unset;
	}
	.infoBox > div {
		grid-template-rows: unset;
	}
}
.infoBox > div > div {
	display: flex;
	align-items: center;
}

/* Profile Picture */

.profileWrapper, .live {
	grid-area: image / image / modes / image;
	position: relative;
	top: -70px;
	justify-self: center;
}
.profileWrapper {
	height: 100px;
}
.profilePicture {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	width: 160px;
	height: 160px;
	border: #e3e3e3 10px solid;
	background-color: white;
}
:global(.dark) .profilePicture {
	border-color: var(--col-accent2);
}
.profilePicture.preload {
	background-color: rgb(150, 150, 150, 0.31);
}

.profilePicture > img {
	width: auto;
	height: auto;
	max-height: 100%;
	/* object-fit: scale-down */
	max-width: unset;
}

a.live {
	position: relative;
	top: -60px;
	width: 160px;
	height: 160px;
	z-index: 1;
	background-color: transparent;
	color: transparent;
	cursor: pointer;
	border-radius: 50%;
	font-size: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
}
a.live:hover {
	background-color: rgb(220, 220, 220, 0.2);
	color: rgb(255, 255, 255, 0.8);
}
.liveRing {
	display: flex;
	justify-content: center;
	align-items: center;
}
.liveRing > svg {
	animation: rotate 15s infinite linear;
}
@media only screen and (max-width: 700px) {
	a.live {
		grid-area: image;
		top: -5px;
		height: 50px;
	}
}
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.livePlay {
	height: 100%;
	width: 100%;
	top: -10px;
}
a.live > svg {
	position: relative;
	grid-area: center;
	justify-self: center;
}

/* */

.extra {
	grid-area: extra;
	display: flex;
	flex-direction: row;
	align-self: end;
	justify-content: space-between;
	font-size: 1.5em;
}
.extra > a.rules, .extra > a.website {
	min-width: 1.5em;
	width: max-content;
}
a.website, a.rules, div.website, div.rules {
	border-radius: 25px;
	align-items: center;
	display: flex;
	flex-direction: row;
	border: none;
	padding: 7px;
	background-color: var(--col-accent2);
	justify-content: center;
	text-align: center;
	cursor: pointer;
	max-height: 40px;
}
a.website:hover, a.rules:hover {
	background-color: #43853d;
}

a.website > text {
	text-align: left;
}

a.website > svg, a.rules > svg {
	margin: 0 10px;
}
.extra > a:first-child {
	border-top-right-radius: 75px;
}
.extra > a:nth-child(2) {
	border-top-left-radius: 75px;
}

.buttonContainer {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	column-gap: 20px;
}

.linkButtons {
	grid-area: buttons;
	display: grid;
	grid-auto-rows: 1fr;
	row-gap: 15px;
}
div.linkButton, a.linkButton {
	border-radius: 10px;
	align-items: center;
	display: flex;
	flex-direction: row;
	border: none;
	padding: 7px;
	background-color: var(--col-accent2);
	justify-content: center;
	text-align: center;
	cursor: pointer;
	max-height: 40px;
}
div.linkButton {
	color: var(--col-accent1);
}
div.linkButton:hover {
	color: var(--col-accent1-hover);
    background-color: var(--col-accent1);
}

.defaultProfilePicture {
	box-sizing: content-box;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: xx-large;
	font-family: 'Josefin Sans', sans-serif;
	height: 100%;
	position: relative;
}
.profilePicture:not(.preload) .defaultProfilePicture {
	background-color: white;
}

.defaultProfilePicture > text {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

/* Tiebreaks */

.tieBreakLink {
	text-decoration: underline;
	cursor: pointer;
	grid-column: 1 / span 2;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.68);
	justify-content: center;
	text-decoration: none;
	padding: 2px;
}
.tieBreakLink:hover {
	background-color: rgba(150, 150, 150, 0.4);
}

section.tieBreakSection:not(.visible) {
	margin-bottom: 0px!important;
	margin: 0px;
	display: none;
}
section.tieBreakSection {
	position: fixed;
	top: 19vh;
	z-index: 3;
}

.tieBreakContainer, .tieBreakContainer:hover {
	max-width: 500px;
	height: auto;
	display: flex;
	background-color: rgb(224, 224, 224);
	transition: left 0.5s ease;
	justify-content: center;
}
.tieBreakSection:not(.visible) .tieBreakContainer {
	left: -100%;
}
.tieBreakSection.visible .tieBreakContainer {
	left: calc((100vw - 1280px) / 2 - 10px)
}
@media only screen and (max-width: 1080px) {
	.tieBreakSection.visible .tieBreakContainer {
		left: 20px;
	}
}
@media only screen and (max-width: 700px) {
	.tieBreakContainer, .tieBreakContainer:hover {
		position: static;
		max-width: unset;
	}
	.tieBreakSection:not(.visible) .tieBreakContainer {
		display: none;
	}
	.tieBreakSection.visible .tieBreakContainer {
		display: flex;
	}
}

.tieBreakContainer > div {
	width: 100%;
}

.tieBreakTitle {
	padding: 5px;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.6);
	display: flex;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
}
.tieBreakTitle:hover {
	background-color: rgba(65, 65, 65, 0.6);
}

.tieBreaks {
	display: grid;
	grid-template-columns: auto 1fr;
	font-size: smaller;
	margin: 10px 0;
}

.tieBreaks > div {
	padding: 2px 5px;
}

@keyframes slide-in-from-left {
	from {
		left: -100%;
	}
	to {
		left: 10vw;
	}
}