

/* Tournament Info */
.tournament_info {
	height: 100%;
	grid-area: info;

	display: grid;
	grid-template-rows: 1fr auto;
	column-gap: 10px;
	padding: 10px;
	box-sizing: border-box;
	background-color: var(--container-bg);
	border-radius: 15px;
}

.tournament_info > div {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-auto-rows: 1fr;
	column-gap: 10px;
	row-gap: 5px;
	height: max-content;
}

@media only screen and (max-width: 700px) {
	.tournament_info {
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		font-size: unset;
	}
	.tournament_info > div {
		grid-template-rows: unset;
	}
}

.tournament_info > div > div {
	padding: 5px;
}

.tieBreakLink {
	text-decoration: underline;
	cursor: pointer;
	grid-column: 1 / span 2;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.68);
	justify-content: center;
	text-decoration: none;
	margin-bottom: 5px;
	padding: 2px;
}

.tieBreakLink:hover {
	background-color: rgba(150, 150, 150, 0.4);
}