.chartist-tooltip {
	background-color: palegoldenrod;
	z-index: 1;
	display: none;
	/*
	flex-direction: column;
	justify-content: center;
	justify-items: center;*/
	font-family: 'Josefin Sans';
	border-radius: 20px;
	padding: 5px;

	position: absolute;
	margin-bottom: 160px;
	/*
	margin-left: 140px;*/
}

div.tooltip-show {
	display: flex;
}

.chartContainer {
	display: grid;
	grid-template-columns: 100%;
	height: max-content;
}

@media screen and (max-width: 700px) {
	.chartContainer {
		grid-auto-flow: row;
	}

	div.chartKey {
		grid-column: 1 / span 2;
		grid-row: 2 / span 1;
		text-align: center;
	}
}

.ct-chart {
	grid-column: 1 / span 2;
	grid-row: 1 / span 1;
	font-family: 'Josefin Sans', sans-serif;
	height: max-content;
	position: relative;
}

.ct-chart .ct-label {
	z-index: 2;
}

.chartKey {
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
	position: relative;
	height: calc(80vh - 15px);
	width: max-content;
	display: grid;
	font-family: 'Josefin Sans';
	position: relative;
	pointer-events: none;
	height: max-content;
}

.playerLabel {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.playerLabel hr {
	border: dashed 0.5px;
	margin: 0 10px;
	height: 0.5px;
	opacity: 0.3;
	position: relative;
	z-index: -1;
}

@media screen and (max-width: 700px) {
	.playerLabel hr {
		display: none;
	}
}

[*|meta='placeholder'] {
	display: none;
}

.gauge .ct-axis-title,
.ct-label {
	color: var(--col-accent3);
	font-family: 'Josefin Sans', sans-serif;
}

.rankings .ct-axis-title,
.ct-label {
	color: var(--col-accent3);
	font-family: 'Josefin Sans', sans-serif;
	position: absolute;
	right: 0px;
}
.dark .ct-label {
	fill: var(--col-accent3);
}
.rankings .ct-chart-line {
	overflow: visible
}

.ct-label.ct-vertical.ct-start {
	overflow: visible;
}

.chartArrow {
	align-items: center;
	margin: 5px 0;
	line-height: 100%;
	height: 100%;
}

.chartArrow>div {
	display: inline-block;
	width: 25px;
	justify-content: right;
	text-align: right;
	margin-right: 5px;
}

.chartArrow>svg {
	height: 100%;
	position: relative;
	top: 5px;
	margin-right: 5px;
	width: 25px;
}

.chartPositive>svg {
	color: green;
}

.chartNegative>svg {
	color: red;
}

.chartNeutral>svg {
	color: grey;
}

/* PIE */

.gauge>div {
	max-height: 220px;
	margin-bottom: 20px;
}

.gauge div.ct-chart>svg {
	overflow: visible;
	padding-top: 20px;
	padding-bottom: 20px;
	pointer-events: none;
}

.gauge .ct-label {
	margin: 50px;
}


.ct-slice-donut-solid[*|meta='confirmed'] {
	fill: gold
}

.ct-slice-donut-solid[*|meta='finished'] {
	fill: #43853d
}

.ct-slice-donut-solid[*|meta='live'] {
	fill: rgb(73, 73, 255);
}

.ct-slice-donut-solid[*|meta='registered'] {
	fill: turquoise
}

.ct-slice-donut-solid[*|meta='scheduled'] {
	fill: turquoise;
}

.ct-slice-donut-solid[*|meta='pending'] {
	fill: grey
}

.ct-slice-donut-solid[*|meta='aborted'] {
	fill: var(--col-alert1)
}

.gauge .ct-label {
	font-size: 24px;
}

/* Slider */

.sliderContainer {
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: stretch;
}

.sliderContainer .slider {
	margin: 0 10px;
	width: 100%;
	display: flex;
	align-items: center;
}

.sliderContainer>.slider>div {
	height: 10px;
	background-color: #43853d;
}

.sliderContainer>.slider>.track {
	border-radius: 5px;
}

.sliderContainer>.slider>.thumb {
	background-color: rgb(150, 150, 150);
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 5px solid rgb(200, 200, 200);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}

.increment {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px;
}

.optionsContainer {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	column-gap: 15px;
}

@media only screen and (max-width: 700px) {
	.optionsContainer {
		display: grid;
		grid-auto-rows: 1fr;
		grid-auto-flow: row;
		row-gap: 15px;
	}
}

.optionsContainer > div, .optionsContainer > div:active {
	display: grid;
	grid-template-columns: 68.1fr 30.8fr;
	grid-auto-flow: column;
	column-gap: 10px;
	height: 100%;
	border-radius: 15px;
	padding: 10px;
	align-items: center;
	position: relative;
	cursor: default
}

.optionsContainer > div:hover > * {
	background-color: unset;
	color: unset;
}

/* Toggle Switch */

/* The switch - the box around the slider */
.toggleSwitch {
	position: relative;
	max-width: 60px;
	justify-self: center;
}

/* Hide default HTML checkbox */
.toggleSwitch input.check {
	display: none;
}

/* The slider */
.toggleSlider {
	position: relative;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 80px;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
	min-height: 20px;
	display: flex;
}

.toggleSlider:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 30.8%;
	left: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input.check:checked+.toggleSlider {
	background-color: #2196F3;
}

input.check:focus+.toggleSlider {
	box-shadow: 0 0 1px #2196F3;
}

input.check:checked+.toggleSlider:before {
	left: calc(100% - 30.8% - 4px);
}

/* Rounded sliders */
.toggleSlider.round {
	border-radius: 34px;
}

.toggleSlider.round:before {
	border-radius: 34px;
}

.switchContainer label div:not(:first-child) {
	font-size: 14px;
	font-weight: 300;
}
.switchContainer {
	border-radius: 15px;
}
.switchContainer:hover > * {
	background-color: transparent;
}