section.fireworksSection {
	position: relative;
	overflow: hidden;
}

.resultsScreen {
	font-family: 'Josefin Sans', sans serif;
	display: grid;
	grid-template-rows: calc(200px * 0.32) repeat(3, auto);
	grid-template-columns: repeat(3, 1fr);
	column-gap: 40px;
	position: relative;
	grid-template-areas:
		'. first .'
		'second first third'
		'name name name'
		'description description description';
}
@media only screen and (max-width: 700px) {
	.resultsScreen {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(5, auto);
		grid-auto-flow: row;
		row-gap: 40px;
		grid-template-areas:
			'first'
			'second'
			'third'
			'name'
			'description';
	}
}

.place {
	height: min-content;
	justify-content: center;
	text-align: center;
	position: relative;
}
.place:global(#first) {	
	grid-area: first;
}
.place:global(#second) {	
	grid-area: second;
}
.place:global(#third) {	
	grid-area: third;
}

.place:global(#first) > svg {
	color: gold;
	font-size: 200px;
}
.place:global(#second) > svg {
	grid-area: second;
	color: silver;
	font-size: calc(200px * 0.68);
}
.place:global(#third) > svg {
	grid-area: third;
	color: #cd7f32;
	font-size: calc(200px * 0.68);
}

.position {
	position: absolute;
	left: calc(50% - 0.8ch);
	top: 25px;
	font-weight: bolder;
	font-size: 20px;
}
.place:global(#first) > .position {
	left: calc(50% - 0.65ch);
}
.position > sup {
	font-weight: normal;
	font-size: 15px;
}

.teamName {
	grid-area: name;
	color: black;
	font-size: 20px;
	font-weight: bold;
}

.table {
	display: grid;
	grid-template-columns: [blank] 1fr [key] max-content [value] 1fr;
	grid-auto-rows: auto;
	font-size: 18px;
}

.table .key {
	font-weight: 300;
}

a.standingsButton {
	padding: 5px;
}

.key {
	grid-column: key
}
.value {
	grid-column: value;
}
.flag {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flag > svg, .flag > img {
	height: 1em;
	outline: 1px lightgrey solid;
}

.links {
	display: grid;
	grid-auto-flow: column;
	column-gap: 15px;
}

.links a:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.links a:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}