
.matchUp {
	display: grid;
	row-gap: 5px;
	grid-template-rows: 1fr;
	height: max-content;
	background-color: rgb(150, 150, 150, 0.31);
	border-radius: 15px;
	padding: 5px;
	position: relative;
}

/* Depth: 5 */
.white, .black {
	display: grid;
	grid-template-columns: auto 1fr auto;
	column-gap: 10px;
	align-items: center;
	background-color: rgb(200, 200, 200, 0.70);
	border-radius: 15px;
	padding-left: 8px;
	box-sizing: border-box;
	position: relative;
}
.white svg, .black svg {
	font-size: x-small;
}

/* Depth: 2.0 */

.flag {
	position: relative;
	height: 1em;
	display: flex;
	justify-content: center;
	border-radius: 0.25em;
}
.flag > img {
	width: unset;
	max-width: unset;
	height: 1em;
	height: 100%;
	border-radius: 0.25em;
}

/* Depth: 6 */
div.playerInfo {
	text-align: left;
	align-items: center;
	box-sizing: border-box;
	padding-right: 45px;
	white-space: nowrap;
	padding: 0 8px;
	width: 100%;
	border-radius: 0.5em;
	cursor: pointer;
}
div.playerInfo:hover {
	background-color: unset;
	color: white;
}