body {
    box-sizing: border-box;
    font: 400 20px/1.5 'Roboto', "Source Sans Pro", "Open Sans", "San Francisco", Helvetica, Arial, sans-serif;
    color: #333;
    margin: 0;
	background-color: #fff;
	overflow-y: scroll;
    scroll-behavior: smooth;
}

#root {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	background-color: rgba(255, 255, 255, 0.1);
	max-width: 100vw;
	min-height: 100vh;
	overflow: hidden;
	--col-accent1: #43853d;
	--col-accent2: skyblue;
	--col-accent3: #eeeeee;
	--col-accent1-hover: white;
	--col-accent2-hover: #43853d;
	--col-accent4: #002147;
	--col-accent5: rgba(247, 224, 90, 0.68);
	--col-alert1: indianred;
	--col-text1: black;
	--container-bg: rgba(200, 200, 200, 0.3);
	--container-darkbg: rgba(100, 100, 100, 0.3);
	--container-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px 0px;
}
#root.dark {
	--col-accent1: #43853d;
	--col-light1: #fff;
	--col-accent1-hover: white;
	--col-accent2: #002147;
	--col-accent2-hover: rgb(190, 162, 0);
	--col-accent3: #fff;
	--col-accent4: #002147;
	--col-container1: white;
	--col-text1: white;
	background-color: #1e1e1e;
	color: rgba(240, 240, 240, 0.9);
	text-shadow: 0.5px 1px 1px rgba(0, 0, 0, .9);
}

header,
#main,
footer {
    display: -ms-flexbox;
    display: flex
}
#main {
    -ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-top: 11vh;
	flex-direction: column;
	padding: 0px 5px;
	width: 100%;
	max-width: 1132px;
	box-sizing: border-box;
	position: relative;
}
#main * {
	scroll-margin: 8vh;
}

.container {
	color: var(--col-container1);
	background-color: var(--container-bg);
	border-radius: 10px;
	padding: 10px;
	box-shadow:var(--container-shadow);
	font-family: 'Josefin Sans', sans-serif;
	position: relative;
	transition: max-height 0.333s ease, padding-top 0.333s ease, padding-bottom 0.333s ease, margin-top 0.333s ease, margin-bottom 0.333s ease;
}
#root.dark .container {
	--col-accent1: #e8ffe6;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 10px 0px;
}
.container:empty {
	margin: 0px;
	padding: 0px;
}
.container.collapsed {
	max-height: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.container:not(.collapsed) {
	max-height: 100%;
}

.bodyContainer {
	border-radius: 25px;
}

.container:hover {
	top: -1px;
}

section, footer {
	margin-top: 25px;
	z-index: 1;
}

.icon {
    height: 40px;
}
.loading > svg, svg.loading {
	animation: spin 1s linear infinite;
}

.button-list {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	column-gap: 10px;
	row-gap: 10px;
	border-radius: 25px;
	overflow: hidden;
}

.header-button, .header-button:link, .header-button:active {
	display: flex;
	background-color: var(--col-accent2);
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	cursor: pointer;
}

.dark .header-button, .dark .button {
	box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
}

a.header-button:hover > *{
	background-color: var(--col-accent2-hover);
	color: var(--col-accent1-hover);
}

a.header-button.selected {
	background-color: cyan;
	color: black;
}

a.twitch-link.header-button:hover > *, .button-list > a.youtube-link.header-button:hover > * {
	animation: flash-yellow 0.5s linear infinite alternate;
}

@keyframes flash-yellow {
	from {
		background-color: var(--col-accent1);
	}
	to {
		background-color: transparent;
	}
}

a.header-button > div > *, div.header-button > div > * {
	padding: 0 20px;
}
.header-button > div > svg, .header-button > div > img, .header-button > svg, .header-button > img {
	padding: 0 5px;
	height: 1em;
}


a.header-button.stream-link {
	display: flex;
}

a.header-button.stream-link > div {
	flex-direction: row;
	align-items: center;
}

.header-button.twitch-link {
	background-color: #6441a5;
	color: rgb(80, 0, 80);
}

a.header-button.youtube-link {
	background-color: lightgrey
}

@media only screen and (max-width: 700px) {
	a.header-button.stream-link {
		grid-column: unset;
	}
}

.header-button div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-button > div.full-text {
	grid-column: 1 / span 2;
	display: grid;
	grid-template-rows: 68% 32%;
	width: 100%;
	height: unset;
	position: relative;
	padding: 10px 0;
}

.header-button > div.full-text > * {
	margin: 2px 0;
}

.header-button > div.full-text > *:first-child:last-child {
	grid-row: 1 / span 2;
}

.header-button > div.full-text > h5 {
	font-size: 14px;
}

a.header-button img {
	max-height: 40px;
}

.button {
    display: flex;
    background-color: rgb(123, 121, 255);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
	line-height: 20px;
	color: var(--col-accent1-hover);
	user-select: none;
	-webkit-user-select: none;
	font-weight: bold;
	cursor: pointer;
}

.button:link, a.button:visited {
	color: var(--col-accent1-hover);
}

.button:hover:not(.sent) {
    color: var(--col-light1);
    background-color: var(--col-accent1);
}
.button:hover > img {
	filter: invert(1);
}

.button > svg {
    transition: transform 0.2s ease;
}

.button > * {
	padding: 10px 5px;
    height: 20px;
}

.iframe {
    width: 100%;
    min-height: 600px;
}

/* General */

h1,
h2,
h3,
h4,
h5 {
	font-weight: 400;
	font-family: 'Josefin Sans'
}

td {
    text-align: center;
}

.playerName {
    min-height: 30px;
    display: inline-flex;
    flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0 5px;
	border-radius: 5px;
}

.playerName > img {
	height: 1em;
	margin: 0 8px;
}

.flag {
	display: flex;
	justify-content: center;
}
.flag > img {
	height: 1.5em;
}

a,
a:link,
a:active {
    color: var(--col-accent1);
    text-decoration: none;
    border-radius: 2px
}

a:hover:not(:empty)  {
    color: var(--col-accent1-hover);
    background-color: var(--col-accent1)
}

a:hover code {
    background-color: transparent;
    color: var(--col-accent1-hover)
}

a.imagelink {
    display: inline-block
}

a.imagelink:hover {
    background-color: transparent
}

strong {
	font-weight: 600
}
b {
    font-weight: 700
}

p a {
    padding-right: 2px;
    padding-left: 2px;
    margin-right: -2px;
    margin-left: -2px
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    border: none
}

code {
    background-color: #f0f0f0;
    font-size: 85%;
    padding: .2em
}

pre {
    background-color: #333;
    border-radius: 3px;
    padding: .75em 1.2em;
    font-size: .8em;
    white-space: pre;
    color: #f0f0f0;
    overflow-x: auto
}

pre code {
    color: #f0f0f0;
    background-color: inherit;
    padding: 0
}

blockquote {
    position: relative;
    margin: 0;
    padding: 0 1em
}

blockquote::before {
    width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .14);
    content: "";
    display: block
}

iframe {
    border: none
}

.bg-white {
    background-color: var(--col-light1) !important
}

.bg-node-gray {
    background-color: #333 !important
}

.table-no-border-no-padding {
    border-spacing: 0
}

.table-no-border-no-padding td {
    padding: 0
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: none
}

.hidden {
    display: none
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row
}

.list-divider-pipe {
    margin: 0;
    padding: 0
}

.list-divider-pipe li {
    display: inline-block
}

.list-divider-pipe li+li::before {
    content: "|";
    padding: 0 .3em 0 .1em;
    color: #999
}

@media screen and (max-width: 700px ) {
    nav .list-divider-pipe {
        margin-bottom: 1rem;
        overflow: hidden
    }
	.button-list{
		grid-auto-flow: row;
	}
    nav .list-divider-pipe li+li::before {
        display: none
    }
}

.download-table {
	border-spacing: 0;
	border-radius: 5px;
}
.full-width {
	width: 100%;
}
.dark .download-table a, .dark .download-table {
	color: white;
}
.download-table.pairings, .download-table.standings {
    font-size: small;
}

.download-table.pairings > thead, .download-table.standings > thead {
    font-weight: 600
}

.download-table>tbody td {
	border-top: 1px solid transparent;
}

.download-table>tbody tr.highlighted {
	background-color: rgba(0, 0, 139, 0.39)!important;
}
tr.highlighted a {
	color: white;
}

td.download-table-last {
    text-align: right
}

td.download-table-last>a {
    padding: 0 10px
}

@media(max-width: 700px) {
	
	.download-table>tbody tr:nth-child(even) {
		background: repeating-linear-gradient(
			45deg,
			rgba(150, 150, 255, 0.5),
			rgba(200, 200, 200, 0.2) 10px,
			rgba(150, 150, 255, 0.5) 10px,
			rgba(200, 200, 200, 0.2) 20px
		);
	}

    .download-table {
        border: none
    }

    .download-table>thead {
        display: none
    }

    .download-table tr {
		margin: 30px 10px;
		display: block;
		border-radius: 15px;
    }

    .download-table td {
        display: block;
		padding: 2px 10px;
    }

    .download-table td:last-child {
        border-bottom: 0
    }

    .download-table td::before {
        content: attr(data-label);
        float: left;
        font-weight: 600
    }

    .download-table>thead>tr>th,
    .download-table>tbody>tr>th,
    .download-table>tfoot>tr>th,
    .download-table>thead>tr>td,
    .download-table>tbody>tr>td,
    .download-table>tfoot>tr>td,
    .download-table>tbody td {
        border: none
    }

    .download-table>tbody td {
        border: none
    }

    td.download-table-last {
        text-align: center
    }
}

.small {
    font-size: 10px
}

.color-lightgray {
    color: #999
}

a:hover .color-lightgray {
    color: var(--col-light1)
}

.no-padding {
    padding: 0
}

.highlight-box {
    background-color: #f0f0f0;
    padding: 5px 15px;
    border-radius: 2px;
    margin-top: 1em
}

.highlight-box *:first-child {
    margin-top: .5rem
}

.bold {
    font-weight: bold;
}

.hidden {
	visibility: hidden;
}

/* Animation */

*[class^='animated-left'] {
	animation: slide-in-from-left 1s ease both;
}

*[class^='animated-right'] {
	animation: slide-in-from-right 1s ease both;
}

@keyframes slide-in-from-left {
	from {
		position: relative;
		left: -100%;
	}
	to {
		position: relative;
		left: 0%;
	}
}

@keyframes slide-in-from-right {
	from {
		position: relative;
		right: -100%;
	}
	to {
		position: relative;
		right: 0%;
	}
}

/* Twitch Player */

.popped {
	position: fixed;
	width: 31%;
	bottom: 10px;
	right: 10px;
	animation: move-to-corner 0.25s ease;
	z-index: 3;
}

@keyframes move-to-corner {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}

.css-1qrom1v {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* CSS Buttons */


div.link, a.link {
	padding: 0 10px;
	border-radius: 5px;
	box-sizing: border-box;
	align-items: center;
}
.link#lichess {
	background-color: rgb(102, 102, 102);
}
.link#chessCom {
	background-color: #6c9d41;
}
.link#email {
	background-color: darkgrey;
}
.link#zoom {
	background-color: rgb(45, 140, 255);
}
.link#skype {
	background-color: rgb(0, 175, 240);
}
.link#facebook {
	background-color: rgb(66, 103, 178);
}
.link#reddit {
	background-color: #FF4500;
}
.link#twitter {
	background-color: rgb(29, 161, 242);
}
.link#phone {
	background-color: yellow;
}
a.link.button:hover:not(.sent) , div.link.button:hover:not(.sent) {
    background-color: var(--col-accent1)!important;
}
div.link {
	user-select: default;
}

.verified {
	color: var(--col-accent1);
	margin-left: 0.5ch;
}

div.jumpToTop {
	cursor: pointer;
}
div.popped > div.jumpToTop {
	width: max-content;
	display: flex;
	align-self: flex-end;
	box-sizing: border-box;
	padding: 10px;
	background-color: var(--col-accent2);
	border-radius: 50%;
	border: 5px solid rgba(0, 0, 0, 0.68);
}
@supports not (width: max-content) {
	div.popped > div.jumpToTop {
		width: 50px;
	}
}
.jumpToTop.transparent {
	opacity: 0;
}
.jumpToTop.opaque {
	opacity: 1;
	transition: opacity 0.5s ease 1s;
}
@media only screen and (max-width: 1080px) {
	div.popped > div.jumpToTop {
		align-self: flex-end;
	}
}

.mono {
	font-family: monospace;
	background-color: rgb(170, 170, 170);
	user-select: all;
}

.still [data-aos^=fade][data-aos^=fade] {
	opacity: 1;
}

.still [data-aos=fade-up] {
	transform: unset;
}

/* Scrollable */

.scrollable {
	overflow-y: auto;
}
.scrollable::-webkit-scrollbar {
	width: 10px;
}
.scrollable::-webkit-scrollbar-track {
	overflow: hidden;
}
.scrollable::-webkit-scrollbar-corner {
	display: none;
}
.scrollable::-webkit-scrollbar-button {
	display: none;
}
.scrollable::-webkit-resizer {
	display: none;
}
.scrollable::-webkit-scrollbar-thumb  {
	background-color: rgba(145, 155, 148, 0.3);
	transition: background-color 2s ease;
	border-radius: 15px;
}
.scrollable::-webkit-scrollbar-thumb:hover {
	position: relative;
}
