.container {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-auto-rows: auto;
	row-gap: 10px;
	column-gap: 10px;
	font-family: Josefin Sans, sans-serif;
	margin: 10px;
	font-size: small;
	margin-bottom: 50px;
	grid-auto-flow: row;
	align-items: stretch;
}

.container > *:not(.nav) {
	grid-column: 1;
}

.nav {
	grid-column: -1;
	grid-row: 1 / -1;
	min-width: 100px;
	position: relative;
}
.nav > div, .nav > div:hover {
	border-left: var(--col-accent1) 2px solid;
	height: max-content;
	position: fixed;
	max-height: 80vh;
	top: 10vh;
	padding: 10px;
	z-index: 1;
	display: grid;
	grid-row-gap: 15px;
}

.divider {
	height: 1px;
	width: 100%;
	background-color: black;
}

.announcement {
	margin-top: 0;
	font-family: Josefin Sans, sans-serif;
	text-align: justify;
}
.announcement p {
    margin-block-start: 0.7em;
    margin-block-end: 0.7em;
}

.title {
	font-size: large;
	font-weight: bold;
	display: flex;
	flex-direction: row;
}

.title svg {
	margin-right: 10px;
}