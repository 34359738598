/* Depth: 1 */

.floated {
	display: flex;
	position: fixed;
	z-index: 3;
	font-size: 16px;
	justify-content: center;
	align-items: center;
	font-family: Josefin Sans;
}
.container {
	position: fixed;
	max-width: 300px;
	z-index: 2;
	font-size: smaller;
	display: flex;
	flex-direction: column;
}
@media only screen and (max-width: 1050px) {
	.floated, .container {
		display: none;
	}
}

@keyframes fly-in-from-right {
	from {
		right: -100vw;
		opacity: 0;
	}
	to {
		right: 1em;
		opacity: 1;
	}
}
@keyframes fly-in-from-left {
	from {
		left: -100vw;
		opacity: 0;
	}
	to {
		left: 1em;
		opacity: 1;
	}
}
.left {
	left: 1em;
	animation: fly-in-from-left 1s ease 1.5s both;
}
.bottom {
	bottom: 1em;
}
.top, .top:hover {
	top: 9vh;
}
.right {
	right: 1em;
	animation: fly-in-from-right 1s ease 1.5s both;
}

/* Depth: 2 */

.linkSwitcher {
	display: grid;
	grid-auto-flow: column;
	column-gap: 5px;
	padding: 10px;
	align-items: right;
	width: max-content;
	align-self: flex-end;
	opacity: 0.9;
}

.container div:global(.container) {
	box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 7px 0px;
	max-width: 300px;
	background-color: rgba(200, 200, 200, 0.9);
	font-size: smaller;
}

.wrapper {
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	overflow: hidden;
}

/* Depth: 3 */

.formWrapper {
	display: grid;
}

.dot {
	cursor: pointer;
	font-size: small;
}

/* Depth: 4 */

.floated svg {
	padding: 0 10px;
}

a.link {
	display: flex;
	flex-direction: row;
	color: white;
	border-radius: 10px;
	padding: 3px 10px;
	justify-content: center;
	align-items: center;
	background-color: var(--col-accent4);
	box-sizing: border-box;
	z-index: 1;
}

.dotExpando {
	cursor: pointer;
}
.expando {
	position: relative;
	display: flex;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: var(--col-alert1);
	color: white;
	max-width: 10ch;
	padding: 3px 15px 3px 20px;
	justify-content: center;
	align-items: center;
	margin-left: -10px;
	left: 0px;
	z-index: 0;
	overflow: hidden;
	transition: 0.333s ease all;
	box-sizing: border-box;
	cursor: pointer;
}
.wrapper:not(:hover) .expando {
	max-width: 0px;
	left: -30px;
}

/* Depth: 4.1 */

.title {
	white-space: pre-line;
	text-align: center;
	background-color: var(--col-accent2);
	border-radius: 10px;
	padding: 5px 2px;
	margin-bottom: 5px;
}

.input input {
	background-color: var(--col-alert1);
	border: none;
	outline: none;
	padding: 8px 5px;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
}
.input input::placeholder {
	font-family: Josefin Sans;
	color: white;
	opacity: 0.5;
}

.buttons {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr auto 1fr;
	column-gap: 10px;
	max-height: 5em;
	overflow: hidden;
	margin-top: 10px;
	transition: 0.333s ease all;
}
.container:not(:hover) .buttons {
	max-height: 0px;
	margin-top: 0px;
}

.buttons button, .buttons .viewTournament {
	background-color: var(--col-accent2);
	color: var(--col-text1);
	padding: 5px 10px;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	border: none;
	outline: none;
	font-family: Josefin Sans;
	position: relative;
}
.buttons *:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.buttons *:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}
.viewTournament svg {
	padding: 0px;
}