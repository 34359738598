.sticky {
	position: fixed!important;
	transition: 3s ease transform;
}

.sticky.absolute {
	position: absolute!important;
}

@media only screen and (max-width: 1050px) {
	.sticky, .sticky.absolute {
		position: static!important;
		width: unset;
	}
}