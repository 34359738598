.tableAnnounce {
	width: 100%;
}

:global(.download-table) > tbody .rowLink:hover {
	background-color: #43853d;
	color: white;
}

:global(.download-table) > tbody .rowLink:hover > * > * {
	color: white;
}

:not(:global(#profileTournaments)).tournamentLink {
	width: 100%;
	display: grid;
	grid-template-columns: auto 5%;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
}