.pgnWrapper {
	height: 100%;
	border-radius: 10px;
}

.pgnViewerMain {
	display: grid;
	grid-template-columns: 68.1% 30.8%;
	grid-auto-flow: row;
	height: 300px;
	padding-bottom: 10px;
}

@media screen and (max-width: 700px) {
    .pgnViewerMain {
		grid-auto-flow: column;
		grid-template-columns: unset;
		height: calc((100vw - 40px) * 0.8)
	}
	.pgnViewerMoveList {
		padding-bottom: 10px;
	}
}

.pgnViewerMoveList {
	text-align: left;
	overflow-y: scroll;
	padding-right: 0px;
	padding-left: 10px;
	font-size: 14px;
	line-height: 20px;
}

.chessboardContainer {
	user-select: none;
	-webkit-user-select: none;
}

.pgnViewerFooter {
	margin-top: 10px;
	user-select: none;
	-webkit-user-select: none;
	color: #333
}