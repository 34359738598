.fsChart {
	font-family: 'Josefin Sans', sans-serif;
}

.fsChart .title {
	font-family: 'Josefin Sans', sans-serif;
	font-size: xx-large;
	font-weight: bold;
}
.fsChart .subtitle {
	font-family: 'Josefin Sans', sans-serif;
	font-weight: light;
}

.fsChart :global(.ct-chart) {
	margin: 0 40px;
	width: 80%;
}

.name, .url {
	display: flex;
	font-family: 'Josefin Sans', sans-serif;
	position: absolute;
	bottom: 20px;
}

.name {
	left: 20px;
	font-weight: bold;
}

.url {
	right: 20px;
}

.fsChart :global(.ct-chart) {
	grid-area: content;
}

.fsChart .header {
	grid-area: title;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

@media only screen and (max-width: 700px) {
	.fsChart :global(.ct-chart) {
		margin: 40px 0;
	}
}