/* Main container */
.container {
    display: grid;
	grid-template-areas: 
		'left-column right-column';
	grid-template-rows: minmax(385px, auto);
	grid-template-columns: 5fr 3fr;
	column-gap: 10px;
	row-gap: 10px;
	font-family: 'Josefin Sans', sans-serif;
}

.left_column {
	grid-area: left-column;
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: 1fr;
	row-gap: 10px;
	height: max-content;
}

/* Subcontainer for tournament and organiser info */
.info_organiser_container {
	display: grid;
	grid-template-areas: 'info organiser';
	grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
	width: 100%;
	column-gap: 10px;
	row-gap: 10px;
	align-items: stretch;
	justify-content: stretch;
	
}

/* Description */

.description {
	background-color: var(--container-bg);
}

/* Organiser */

div.organiser_container {
	margin: 0px;
	box-shadow: none;
	max-width: unset;
	justify-self: stretch;
	width: 100%;
	box-sizing: border-box;
}

/* Tiebreak and Prizes Buttons */

section.tieBreakSection {
	margin-bottom: 0px !important;
	margin-top: 0px !important;
}

.tieBreakContainer, .tieBreakContainer:hover {
	display: flex;
	background-color: var(--container-bg);
	transition: left 0.5s ease;
	justify-content: center;
	z-index: 3;
}

.tieBreakContainer.visible {
	left: calc((100vw - 1280px) / 2 - 10px)
}

@media only screen and (max-width: 1080px) {
	.tieBreakContainer.visible {
		left: 20px;
	}
}

@media only screen and (max-width: 700px) {
	.tieBreakContainer, .tieBreakContainer:hover {
		position: static;
		max-width: unset;
	}
	.tieBreakContainer:not(.visible) {
		display: none;
	}
	.tieBreakContainer.visible {
		display: flex;
	}
}

.tieBreakContainer > div {
	width: 100%;
}

.tieBreakTitle {
	padding: 5px;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.6);
	display: flex;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
}

.tieBreakTitle:hover {
	background-color: rgba(65, 65, 65, 0.6);
}

.tieBreaks {
	display: grid;
	grid-template-columns: auto 1fr;
	font-size: smaller;
	margin: 10px 0;
}

.tieBreaks > div {
	padding: 2px 5px;
}

/* Featured/Upcoming games */
.featured {
	margin-top: 0px;
	max-height: 600px;
}

.live {
	grid-column: 1;
}

.teams_announcements {
	grid-area: right-column;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	margin: 5px 0px;
}

.teams_list {
	height: auto;
	box-sizing: border-box;
	position: relative;
	display: flex;
	justify-content: center;
}

.announcements {
	height: auto;
	max-height: 500px;
	overflow: auto;
}