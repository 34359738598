@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, Roboto, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
    box-sizing: border-box;
    font: 400 20px/1.5 'Roboto', "Source Sans Pro", "Open Sans", "San Francisco", Helvetica, Arial, sans-serif;
    color: #333;
    margin: 0;
	background-color: #fff;
	overflow-y: scroll;
    scroll-behavior: smooth;
}

#root {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	background-color: rgba(255, 255, 255, 0.1);
	max-width: 100vw;
	min-height: 100vh;
	overflow: hidden;
	--col-accent1: #43853d;
	--col-accent2: skyblue;
	--col-accent3: #eeeeee;
	--col-accent1-hover: white;
	--col-accent2-hover: #43853d;
	--col-accent4: #002147;
	--col-accent5: rgba(247, 224, 90, 0.68);
	--col-alert1: indianred;
	--col-text1: black;
	--container-bg: rgba(200, 200, 200, 0.3);
	--container-darkbg: rgba(100, 100, 100, 0.3);
	--container-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px 0px;
}
#root.dark {
	--col-accent1: #43853d;
	--col-light1: #fff;
	--col-accent1-hover: white;
	--col-accent2: #002147;
	--col-accent2-hover: rgb(190, 162, 0);
	--col-accent3: #fff;
	--col-accent4: #002147;
	--col-container1: white;
	--col-text1: white;
	background-color: #1e1e1e;
	color: rgba(240, 240, 240, 0.9);
	text-shadow: 0.5px 1px 1px rgba(0, 0, 0, .9);
}

header,
#main,
footer {
    display: flex
}
#main {
	flex: 0 0 auto;
	margin-top: 11vh;
	flex-direction: column;
	padding: 0px 5px;
	width: 100%;
	max-width: 1132px;
	box-sizing: border-box;
	position: relative;
}
#main * {
	scroll-margin: 8vh;
}

.container {
	color: var(--col-container1);
	background-color: var(--container-bg);
	border-radius: 10px;
	padding: 10px;
	box-shadow:var(--container-shadow);
	font-family: 'Josefin Sans', sans-serif;
	position: relative;
	transition: max-height 0.333s ease, padding-top 0.333s ease, padding-bottom 0.333s ease, margin-top 0.333s ease, margin-bottom 0.333s ease;
}
#root.dark .container {
	--col-accent1: #e8ffe6;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 10px 0px;
}
.container:empty {
	margin: 0px;
	padding: 0px;
}
.container.collapsed {
	max-height: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.container:not(.collapsed) {
	max-height: 100%;
}

.bodyContainer {
	border-radius: 25px;
}

.container:hover {
	top: -1px;
}

section, footer {
	margin-top: 25px;
	z-index: 1;
}

.icon {
    height: 40px;
}
.loading > svg, svg.loading {
	-webkit-animation: spin 1s linear infinite;
	        animation: spin 1s linear infinite;
}

.button-list {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-row-gap: 10px;
	row-gap: 10px;
	border-radius: 25px;
	overflow: hidden;
}

.header-button, .header-button:link, .header-button:active {
	display: flex;
	background-color: var(--col-accent2);
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	cursor: pointer;
}

.dark .header-button, .dark .button {
	box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
}

a.header-button:hover > *{
	background-color: var(--col-accent2-hover);
	color: var(--col-accent1-hover);
}

a.header-button.selected {
	background-color: cyan;
	color: black;
}

a.twitch-link.header-button:hover > *, .button-list > a.youtube-link.header-button:hover > * {
	-webkit-animation: flash-yellow 0.5s linear infinite alternate;
	        animation: flash-yellow 0.5s linear infinite alternate;
}

@-webkit-keyframes flash-yellow {
	from {
		background-color: var(--col-accent1);
	}
	to {
		background-color: transparent;
	}
}

@keyframes flash-yellow {
	from {
		background-color: var(--col-accent1);
	}
	to {
		background-color: transparent;
	}
}

a.header-button > div > *, div.header-button > div > * {
	padding: 0 20px;
}
.header-button > div > svg, .header-button > div > img, .header-button > svg, .header-button > img {
	padding: 0 5px;
	height: 1em;
}


a.header-button.stream-link {
	display: flex;
}

a.header-button.stream-link > div {
	flex-direction: row;
	align-items: center;
}

.header-button.twitch-link {
	background-color: #6441a5;
	color: rgb(80, 0, 80);
}

a.header-button.youtube-link {
	background-color: lightgrey
}

@media only screen and (max-width: 700px) {
	a.header-button.stream-link {
		grid-column: unset;
	}
}

.header-button div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-button > div.full-text {
	grid-column: 1 / span 2;
	display: grid;
	grid-template-rows: 68% 32%;
	width: 100%;
	height: unset;
	position: relative;
	padding: 10px 0;
}

.header-button > div.full-text > * {
	margin: 2px 0;
}

.header-button > div.full-text > *:first-child:last-child {
	grid-row: 1 / span 2;
}

.header-button > div.full-text > h5 {
	font-size: 14px;
}

a.header-button img {
	max-height: 40px;
}

.button {
    display: flex;
    background-color: rgb(123, 121, 255);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
	line-height: 20px;
	color: var(--col-accent1-hover);
	-moz-user-select: none;
	 -ms-user-select: none;
	     user-select: none;
	-webkit-user-select: none;
	font-weight: bold;
	cursor: pointer;
}

.button:link, a.button:visited {
	color: var(--col-accent1-hover);
}

.button:hover:not(.sent) {
    color: var(--col-light1);
    background-color: var(--col-accent1);
}
.button:hover > img {
	-webkit-filter: invert(1);
	        filter: invert(1);
}

.button > svg {
    transition: transform 0.2s ease;
}

.button > * {
	padding: 10px 5px;
    height: 20px;
}

.iframe {
    width: 100%;
    min-height: 600px;
}

/* General */

h1,
h2,
h3,
h4,
h5 {
	font-weight: 400;
	font-family: 'Josefin Sans'
}

td {
    text-align: center;
}

.playerName {
    min-height: 30px;
    display: inline-flex;
    flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0 5px;
	border-radius: 5px;
}

.playerName > img {
	height: 1em;
	margin: 0 8px;
}

.flag {
	display: flex;
	justify-content: center;
}
.flag > img {
	height: 1.5em;
}

a,
a:link,
a:active {
    color: var(--col-accent1);
    text-decoration: none;
    border-radius: 2px
}

a:hover:not(:empty)  {
    color: var(--col-accent1-hover);
    background-color: var(--col-accent1)
}

a:hover code {
    background-color: transparent;
    color: var(--col-accent1-hover)
}

a.imagelink {
    display: inline-block
}

a.imagelink:hover {
    background-color: transparent
}

strong {
	font-weight: 600
}
b {
    font-weight: 700
}

p a {
    padding-right: 2px;
    padding-left: 2px;
    margin-right: -2px;
    margin-left: -2px
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    border: none
}

code {
    background-color: #f0f0f0;
    font-size: 85%;
    padding: .2em
}

pre {
    background-color: #333;
    border-radius: 3px;
    padding: .75em 1.2em;
    font-size: .8em;
    white-space: pre;
    color: #f0f0f0;
    overflow-x: auto
}

pre code {
    color: #f0f0f0;
    background-color: inherit;
    padding: 0
}

blockquote {
    position: relative;
    margin: 0;
    padding: 0 1em
}

blockquote::before {
    width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .14);
    content: "";
    display: block
}

iframe {
    border: none
}

.bg-white {
    background-color: var(--col-light1) !important
}

.bg-node-gray {
    background-color: #333 !important
}

.table-no-border-no-padding {
    border-spacing: 0
}

.table-no-border-no-padding td {
    padding: 0
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: none
}

.hidden {
    display: none
}

.row {
    display: flex;
    flex-direction: row
}

.list-divider-pipe {
    margin: 0;
    padding: 0
}

.list-divider-pipe li {
    display: inline-block
}

.list-divider-pipe li+li::before {
    content: "|";
    padding: 0 .3em 0 .1em;
    color: #999
}

@media screen and (max-width: 700px ) {
    nav .list-divider-pipe {
        margin-bottom: 1rem;
        overflow: hidden
    }
	.button-list{
		grid-auto-flow: row;
	}
    nav .list-divider-pipe li+li::before {
        display: none
    }
}

.download-table {
	border-spacing: 0;
	border-radius: 5px;
}
.full-width {
	width: 100%;
}
.dark .download-table a, .dark .download-table {
	color: white;
}
.download-table.pairings, .download-table.standings {
    font-size: small;
}

.download-table.pairings > thead, .download-table.standings > thead {
    font-weight: 600
}

.download-table>tbody td {
	border-top: 1px solid transparent;
}

.download-table>tbody tr.highlighted {
	background-color: rgba(0, 0, 139, 0.39)!important;
}
tr.highlighted a {
	color: white;
}

td.download-table-last {
    text-align: right
}

td.download-table-last>a {
    padding: 0 10px
}

@media(max-width: 700px) {
	
	.download-table>tbody tr:nth-child(even) {
		background: repeating-linear-gradient(
			45deg,
			rgba(150, 150, 255, 0.5),
			rgba(200, 200, 200, 0.2) 10px,
			rgba(150, 150, 255, 0.5) 10px,
			rgba(200, 200, 200, 0.2) 20px
		);
	}

    .download-table {
        border: none
    }

    .download-table>thead {
        display: none
    }

    .download-table tr {
		margin: 30px 10px;
		display: block;
		border-radius: 15px;
    }

    .download-table td {
        display: block;
		padding: 2px 10px;
    }

    .download-table td:last-child {
        border-bottom: 0
    }

    .download-table td::before {
        content: attr(data-label);
        float: left;
        font-weight: 600
    }

    .download-table>thead>tr>th,
    .download-table>tbody>tr>th,
    .download-table>tfoot>tr>th,
    .download-table>thead>tr>td,
    .download-table>tbody>tr>td,
    .download-table>tfoot>tr>td,
    .download-table>tbody td {
        border: none
    }

    .download-table>tbody td {
        border: none
    }

    td.download-table-last {
        text-align: center
    }
}

.small {
    font-size: 10px
}

.color-lightgray {
    color: #999
}

a:hover .color-lightgray {
    color: var(--col-light1)
}

.no-padding {
    padding: 0
}

.highlight-box {
    background-color: #f0f0f0;
    padding: 5px 15px;
    border-radius: 2px;
    margin-top: 1em
}

.highlight-box *:first-child {
    margin-top: .5rem
}

.bold {
    font-weight: bold;
}

.hidden {
	visibility: hidden;
}

/* Animation */

*[class^='animated-left'] {
	-webkit-animation: slide-in-from-left 1s ease both;
	        animation: slide-in-from-left 1s ease both;
}

*[class^='animated-right'] {
	-webkit-animation: slide-in-from-right 1s ease both;
	        animation: slide-in-from-right 1s ease both;
}

@-webkit-keyframes slide-in-from-left {
	from {
		position: relative;
		left: -100%;
	}
	to {
		position: relative;
		left: 0%;
	}
}

@keyframes slide-in-from-left {
	from {
		position: relative;
		left: -100%;
	}
	to {
		position: relative;
		left: 0%;
	}
}

@-webkit-keyframes slide-in-from-right {
	from {
		position: relative;
		right: -100%;
	}
	to {
		position: relative;
		right: 0%;
	}
}

@keyframes slide-in-from-right {
	from {
		position: relative;
		right: -100%;
	}
	to {
		position: relative;
		right: 0%;
	}
}

/* Twitch Player */

.popped {
	position: fixed;
	width: 31%;
	bottom: 10px;
	right: 10px;
	-webkit-animation: move-to-corner 0.25s ease;
	        animation: move-to-corner 0.25s ease;
	z-index: 3;
}

@-webkit-keyframes move-to-corner {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}

@keyframes move-to-corner {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}

.css-1qrom1v {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* CSS Buttons */


div.link, a.link {
	padding: 0 10px;
	border-radius: 5px;
	box-sizing: border-box;
	align-items: center;
}
.link#lichess {
	background-color: rgb(102, 102, 102);
}
.link#chessCom {
	background-color: #6c9d41;
}
.link#email {
	background-color: darkgrey;
}
.link#zoom {
	background-color: rgb(45, 140, 255);
}
.link#skype {
	background-color: rgb(0, 175, 240);
}
.link#facebook {
	background-color: rgb(66, 103, 178);
}
.link#reddit {
	background-color: #FF4500;
}
.link#twitter {
	background-color: rgb(29, 161, 242);
}
.link#phone {
	background-color: yellow;
}
a.link.button:hover:not(.sent) , div.link.button:hover:not(.sent) {
    background-color: var(--col-accent1)!important;
}
div.link {
	-webkit-user-select: default;
	   -moz-user-select: default;
	    -ms-user-select: default;
	        user-select: default;
}

.verified {
	color: var(--col-accent1);
	margin-left: 0.5ch;
}

div.jumpToTop {
	cursor: pointer;
}
div.popped > div.jumpToTop {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	display: flex;
	align-self: flex-end;
	box-sizing: border-box;
	padding: 10px;
	background-color: var(--col-accent2);
	border-radius: 50%;
	border: 5px solid rgba(0, 0, 0, 0.68);
}
@supports not ((width: -webkit-max-content) or (width: -moz-max-content) or (width: max-content)) {
	div.popped > div.jumpToTop {
		width: 50px;
	}
}
.jumpToTop.transparent {
	opacity: 0;
}
.jumpToTop.opaque {
	opacity: 1;
	transition: opacity 0.5s ease 1s;
}
@media only screen and (max-width: 1080px) {
	div.popped > div.jumpToTop {
		align-self: flex-end;
	}
}

.mono {
	font-family: monospace;
	background-color: rgb(170, 170, 170);
	-webkit-user-select: all;
	   -moz-user-select: all;
	    -ms-user-select: all;
	        user-select: all;
}

.still [data-aos^=fade][data-aos^=fade] {
	opacity: 1;
}

.still [data-aos=fade-up] {
	transform: unset;
}

/* Scrollable */

.scrollable {
	overflow-y: auto;
}
.scrollable::-webkit-scrollbar {
	width: 10px;
}
.scrollable::-webkit-scrollbar-track {
	overflow: hidden;
}
.scrollable::-webkit-scrollbar-corner {
	display: none;
}
.scrollable::-webkit-scrollbar-button {
	display: none;
}
.scrollable::-webkit-resizer {
	display: none;
}
.scrollable::-webkit-scrollbar-thumb  {
	background-color: rgba(145, 155, 148, 0.3);
	-webkit-transition: background-color 2s ease;
	transition: background-color 2s ease;
	border-radius: 15px;
}
.scrollable::-webkit-scrollbar-thumb:hover {
	position: relative;
}



/* Table business */

table {
	box-sizing: border-box;
}

table thead tr {
	box-sizing: border-box;
	border-radius: 15px;
	overflow: hidden;
}
table thead td, table thead tr:not([class*="sizer"]) th {
	padding: 12px 3px;
	box-sizing: border-box;
	background-color: var(--col-accent2);
	font-family: Josefin Sans;
	vertical-align: middle;
}
div#root:not(.dark) table thead th {
	color: black;
}
.dark table thead td, .dark table thead th {
	color: white;
}


table thead td:first-child, table thead th:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
table thead td:last-child, table thead th:last-child  {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}


table.download-table tbody::before {
    content: '@';
    display: block;
    line-height: 1em;
	color: transparent;
	opacity: 0;
}
table tbody tr.odd {
    background-color: rgba(220, 220, 220, 0.8);
}
.dark table tbody tr.odd {
    background-color: rgba(220, 220, 220, 0.2);
}
table tbody tr.even {
    background-color: rgba(255, 255, 255, 0.8);
}
.dark table tbody tr.even {
    background-color: rgba(255, 255, 255, 0.1);
}
table tbody tr:first-child td:first-of-type {
	border-top-left-radius: 15px;
}
table tbody tr:first-child td:last-child {
	border-top-right-radius: 15px;
}
table tbody tr:last-child td:first-of-type {
	border-bottom-left-radius: 15px;
}
table tbody tr:last-child td:last-child {
	border-bottom-right-radius: 15px;
}
table tbody tr td:first-child {
	padding-left: 8px;
}
table tbody tr td:last-child {
	padding-right: 8px;
}

.collapsed {
	display: none;
}


.switcher_switcher__3zrJL {
	grid-area: modes;
    display: grid;
	grid-auto-columns: 1fr;
	grid-auto-rows: 1fr;
	grid-auto-flow: column;
    overflow: hidden;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
	grid-row-gap: 20px;
	row-gap: 20px;
	font-size: 1em;
}

@media only screen and (max-width: 700px) {
	.switcher_switcher__3zrJL {
		grid-auto-flow: row;
	}
}

.switcher_switcher__3zrJL > .header-button:first-child {
	overflow: hidden;
}

.switcher_switcher__3zrJL > .header-button {
	border: none;
	border-radius: 10px;
	overflow: hidden;
}

.switcher_switcher__3zrJL > .header-button > div {
	margin: 0px;
	grid-template-rows: unset;
	padding: 7px 0;
	display: flex;
}
.pairings_score__1yn8S {
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 700px) {
	.pairings_score__1yn8S {
		min-height: 20px;
	}
}

.pairings_confirmed__3JrmJ {
	color: var(--col-accent4);
	background-color: gold;
	font-weight: bold;
}
.pairings_finished__3Tinj {
	background-color: #43853d;
	color: white;
}
.pairings_live__1ycFn {
	background-color: rgb(73, 73, 255);
	color: white;
	font-weight: 400;
}
.pairings_watchLive__2cnys, div.pairings_watchLive__2cnys {
	background-color: rgb(73, 73, 255);
}
.pairings_watchLive__2cnys.button > a {
	color: gold;
}
.pairings_registered__Q2QET {
	background-color: turquoise;
}
.pairings_scheduled__1GzqC {
	background-color: turquoise;
}
.pairings_pending__3TFsg {
	background-color: grey;
}
.pairings_aborted__pZaOi {
	background-color: var(--col-alert1);
}

.pairings_pairingRound__2Sz8j.pairings_roundSelected__15kTz {
	background-color: cyan;
}

a.pairings_pairingRound__2Sz8j.pairings_nullRound__2lhF2, a.pairings_pairingRound__2Sz8j.pairings_nullRound__2lhF2:hover > * {
	background-color: lightgrey;
	color: grey;
	cursor: default;
}

td.pairings_inner__hb8sB {
	padding: 0;
}

.pairings_subheader__tOuDn {
	display: grid;
	grid-template-columns: 45% 2fr 3fr;
	grid-column-gap: 5%;
	-webkit-column-gap: 5%;
	        column-gap: 5%;
	margin: 20px 0;
}
.pairings_subheader__tOuDn, .pairings_subheader__tOuDn input {
	font-family: Josefin sans, sans-serif;
}

@media screen and (max-width: 700px) {
	.pairings_subheader__tOuDn {
		grid-template-columns: unset;
		grid-auto-flow: row;
		grid-row-gap: 20px;
		row-gap: 20px;
	}
}

.pairings_subheader__tOuDn > div {
	display: flex;
	width: 100%;
}

.pairings_subheader__tOuDn > div:first-child {
	align-items: center;
	justify-content: flex-start;
}

.pairings_subheader__tOuDn > div:last-child {
	justify-content: flex-end;
}

.pairings_pieMatrix__2pEq7 {
	display: grid;
	grid-row-gap: 10px;
	row-gap: 10px;
	grid-template-columns: repeat(4, 1fr) minmax(auto, 480px) repeat(4, 1fr);
	grid-template-areas:
		'timezone timezone timezone timezone pie . search search search'
		'download download download . pie . expandAll expandAll expandAll'
		'. . . . pie . jumpTo jumpTo jumpTo'
		'. . . . pie .  picker picker picker'
		'. . . . pie . . . .'
}
@media only screen and (max-width: 700px) {
	.pairings_pieMatrix__2pEq7 {
		grid-template-columns: 1fr;
		grid-template-areas:
			'pie'
			'search'
			'timezone'
			'download'
			'jumpTo'
			'expandAll'
			'picker';
	}
}
.pairings_pieMatrix__2pEq7 > :not(.pairings_pie__3vifK) {
	z-index: 1;
}
.pairings_pieMatrix__2pEq7 .button {
	display: grid;
	grid-template-columns: minmax(1.5ch, auto) 1fr minmax(1.5ch, auto);
	text-align: center;
	justify-content: center;
	align-items: center;
}

div.pairings_statusPicker__1Frr2 {
	grid-area: picker;
}
div.pairings_statusPicker__1Frr2 > div.button {
	border-radius: 10px;
}
.pairings_pie__3vifK {
	grid-column: timezone / search;
	grid-row: pie;
}
div.pairings_timezone__2ajYa {
	grid-area: timezone;
	font-size: smaller;
}
div.pairings_timezone__2ajYa svg {
	font-size: normal;
}
div.pairings_download__gZx8B {
	grid-area: download;
}
div.pairings_jumpTo__1i8OA {
	grid-area: jumpTo;
}
div.pairings_expandAll__2WE6V {
	grid-area: expandAll;
}
div.pairings_timezone__2ajYa, div.pairings_download__gZx8B, div.pairings_jumpTo__1i8OA, div.pairings_expandAll__2WE6V, .pairings_statusPicker__1Frr2 > div {
	border-radius: 10px;
	padding: 0 5px;
	box-sizing: border-box;
}

.pairings_searchBar__r83HS {
	grid-area: search;
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent2);
	align-items: center;
	justify-content: stretch;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	padding: 0 10px;
}

.pairings_searchBar__r83HS > * {
	height: 100%;
	display: flex;
	align-items: center;
}
.pairings_searchBar__r83HS * {
	background-color: transparent;
}
.pairings_searchBar__r83HS form {
	padding: 2px 0;
}

.pairings_searchBar__r83HS div, .pairings_searchBar__r83HS form, .pairings_searchBar__r83HS input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.pairings_searchBar__r83HS input, .pairings_searchBar__r83HS input:focus {
	border: none;
	margin: 5px 0;
}

.pairings_description__4Fd7Z {
	padding: 10px 0;
	font-family: Josefin Sans;
}

@media only screen and (max-width: 700px) {
	div.pairings_buttonList__2r4XD {
		grid-template-columns: unset;
	}
}

.pairings_flag__21EM_ {
	box-sizing: border-box;
	max-height: 2.5em;
	display: flex;
	justify-content: center;
}

.pairings_join__YK3fM {
	grid-column: 1 / span 3;
	grid-row: 4 / span 1;
	border: none;
	border-radius: 10px;
	overflow: hidden;
}

.pairings_join__YK3fM > div {
	margin: 0px;
	grid-template-rows: unset;
	padding: 7px 0;
	display: flex;
}

.pairings_pool__3ObdH {
	text-align: center;
	font-weight: bold;
}

.pairings_table__RLFKq {
	width: 72%;
}
.pairings_table__RLFKq tbody::before {
    content: '@';
    display: block;
    line-height: 2em;
	color: transparent;
	opacity: 0;
}
@media only screen and (max-width: 700px) {
	.pairings_table__RLFKq {
		width: 100%;
	}
}

a.pairings_watchButton__1ksVQ {
	color: white;
}
/* Depth: 1 */

.nav_nav__1gFey {
	margin-top: 10vh;
}

.nav_stage__3GKOi {
	position: relative;
	display: grid;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	grid-row-gap: 20px;
	row-gap: 20px;
	grid-template-columns: 25% 1fr;
	grid-template-rows: 1fr auto;
	grid-template-areas:
		'image name'
		'extra modes';		
}

/* Redesigned Nav */
.nav_infoBox__22EbU, .nav_linkButtons__2ee5L {
	display: none!important;
}

@media only screen and (max-width: 700px) {
	.nav_stage__3GKOi {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-template-areas:
			'image'
			'extra'
			'name'
			'modes'
	}
	div.nav_profilePicture__PVvqr {
		grid-area: image;
	}
}

.nav_nameContainer__17v30 {
	grid-area: name;
	display: flex;
	align-items: center;
}

.nav_name__1A6FS {
	background-color: rgba(150, 150, 150, 0.68);
	border-radius: 15px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 24px;
	padding: 7px;
}

.nav_infoBox__22EbU {
	grid-area: info;
	display: grid;
	grid-template-columns: auto auto;
	grid-auto-rows: auto;
	grid-auto-flow: column;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	padding: 10px;
	background-color: rgb(150, 150, 150, 0.31);
	overflow: hidden;
	border-radius: 15px;
	font-size: 16px;
}
.nav_infoBox__22EbU > div {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: repeat(4, 1fr);
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-row-gap: 5px;
	row-gap: 5px;
}
@media only screen and (max-width: 700px) {
	.nav_infoBox__22EbU {
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		font-size: unset;
	}
	.nav_infoBox__22EbU > div {
		grid-template-rows: unset;
	}
}
.nav_infoBox__22EbU > div > div {
	display: flex;
	align-items: center;
}

/* Profile Picture */

.nav_profileWrapper__33UPj, .nav_live__3qmpe {
	grid-area: image / image / modes / image;
	position: relative;
	top: -70px;
	justify-self: center;
}
.nav_profileWrapper__33UPj {
	height: 100px;
}
.nav_profilePicture__PVvqr {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	width: 160px;
	height: 160px;
	border: #e3e3e3 10px solid;
	background-color: white;
}
.dark .nav_profilePicture__PVvqr {
	border-color: var(--col-accent2);
}
.nav_profilePicture__PVvqr.nav_preload__vbtMr {
	background-color: rgb(150, 150, 150, 0.31);
}

.nav_profilePicture__PVvqr > img {
	width: auto;
	height: auto;
	max-height: 100%;
	/* object-fit: scale-down */
	max-width: unset;
}

a.nav_live__3qmpe {
	position: relative;
	top: -60px;
	width: 160px;
	height: 160px;
	z-index: 1;
	background-color: transparent;
	color: transparent;
	cursor: pointer;
	border-radius: 50%;
	font-size: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
}
a.nav_live__3qmpe:hover {
	background-color: rgb(220, 220, 220, 0.2);
	color: rgb(255, 255, 255, 0.8);
}
.nav_liveRing__1leOV {
	display: flex;
	justify-content: center;
	align-items: center;
}
.nav_liveRing__1leOV > svg {
	-webkit-animation: nav_rotate__3MBnR 15s infinite linear;
	        animation: nav_rotate__3MBnR 15s infinite linear;
}
@media only screen and (max-width: 700px) {
	a.nav_live__3qmpe {
		grid-area: image;
		top: -5px;
		height: 50px;
	}
}
@-webkit-keyframes nav_rotate__3MBnR {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes nav_rotate__3MBnR {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.nav_livePlay__1hJbH {
	height: 100%;
	width: 100%;
	top: -10px;
}
a.nav_live__3qmpe > svg {
	position: relative;
	grid-area: center;
	justify-self: center;
}

/* */

.nav_extra__nwYhM {
	grid-area: extra;
	display: flex;
	flex-direction: row;
	align-self: end;
	justify-content: space-between;
	font-size: 1.5em;
}
.nav_extra__nwYhM > a.nav_rules__3Fitx, .nav_extra__nwYhM > a.nav_website__3Z3po {
	min-width: 1.5em;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}
a.nav_website__3Z3po, a.nav_rules__3Fitx, div.nav_website__3Z3po, div.nav_rules__3Fitx {
	border-radius: 25px;
	align-items: center;
	display: flex;
	flex-direction: row;
	border: none;
	padding: 7px;
	background-color: var(--col-accent2);
	justify-content: center;
	text-align: center;
	cursor: pointer;
	max-height: 40px;
}
a.nav_website__3Z3po:hover, a.nav_rules__3Fitx:hover {
	background-color: #43853d;
}

a.nav_website__3Z3po > text {
	text-align: left;
}

a.nav_website__3Z3po > svg, a.nav_rules__3Fitx > svg {
	margin: 0 10px;
}
.nav_extra__nwYhM > a:first-child {
	border-top-right-radius: 75px;
}
.nav_extra__nwYhM > a:nth-child(2) {
	border-top-left-radius: 75px;
}

.nav_buttonContainer__2BU-r {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
}

.nav_linkButtons__2ee5L {
	grid-area: buttons;
	display: grid;
	grid-auto-rows: 1fr;
	grid-row-gap: 15px;
	row-gap: 15px;
}
div.nav_linkButton__1saVW, a.nav_linkButton__1saVW {
	border-radius: 10px;
	align-items: center;
	display: flex;
	flex-direction: row;
	border: none;
	padding: 7px;
	background-color: var(--col-accent2);
	justify-content: center;
	text-align: center;
	cursor: pointer;
	max-height: 40px;
}
div.nav_linkButton__1saVW {
	color: var(--col-accent1);
}
div.nav_linkButton__1saVW:hover {
	color: var(--col-accent1-hover);
    background-color: var(--col-accent1);
}

.nav_defaultProfilePicture__3cROn {
	box-sizing: content-box;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: xx-large;
	font-family: 'Josefin Sans', sans-serif;
	height: 100%;
	position: relative;
}
.nav_profilePicture__PVvqr:not(.nav_preload__vbtMr) .nav_defaultProfilePicture__3cROn {
	background-color: white;
}

.nav_defaultProfilePicture__3cROn > text {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

/* Tiebreaks */

.nav_tieBreakLink__1UyPD {
	text-decoration: underline;
	cursor: pointer;
	grid-column: 1 / span 2;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.68);
	justify-content: center;
	text-decoration: none;
	padding: 2px;
}
.nav_tieBreakLink__1UyPD:hover {
	background-color: rgba(150, 150, 150, 0.4);
}

section.nav_tieBreakSection__2Qbwx:not(.nav_visible__1OKR0) {
	margin-bottom: 0px!important;
	margin: 0px;
	display: none;
}
section.nav_tieBreakSection__2Qbwx {
	position: fixed;
	top: 19vh;
	z-index: 3;
}

.nav_tieBreakContainer__2hPD_, .nav_tieBreakContainer__2hPD_:hover {
	max-width: 500px;
	height: auto;
	display: flex;
	background-color: rgb(224, 224, 224);
	transition: left 0.5s ease;
	justify-content: center;
}
.nav_tieBreakSection__2Qbwx:not(.nav_visible__1OKR0) .nav_tieBreakContainer__2hPD_ {
	left: -100%;
}
.nav_tieBreakSection__2Qbwx.nav_visible__1OKR0 .nav_tieBreakContainer__2hPD_ {
	left: calc((100vw - 1280px) / 2 - 10px)
}
@media only screen and (max-width: 1080px) {
	.nav_tieBreakSection__2Qbwx.nav_visible__1OKR0 .nav_tieBreakContainer__2hPD_ {
		left: 20px;
	}
}
@media only screen and (max-width: 700px) {
	.nav_tieBreakContainer__2hPD_, .nav_tieBreakContainer__2hPD_:hover {
		position: static;
		max-width: unset;
	}
	.nav_tieBreakSection__2Qbwx:not(.nav_visible__1OKR0) .nav_tieBreakContainer__2hPD_ {
		display: none;
	}
	.nav_tieBreakSection__2Qbwx.nav_visible__1OKR0 .nav_tieBreakContainer__2hPD_ {
		display: flex;
	}
}

.nav_tieBreakContainer__2hPD_ > div {
	width: 100%;
}

.nav_tieBreakTitle__1guQ_ {
	padding: 5px;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.6);
	display: flex;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
}
.nav_tieBreakTitle__1guQ_:hover {
	background-color: rgba(65, 65, 65, 0.6);
}

.nav_tieBreaks__qpKIN {
	display: grid;
	grid-template-columns: auto 1fr;
	font-size: smaller;
	margin: 10px 0;
}

.nav_tieBreaks__qpKIN > div {
	padding: 2px 5px;
}

@-webkit-keyframes nav_slide-in-from-left__1m3c0 {
	from {
		left: -100%;
	}
	to {
		left: 10vw;
	}
}

@keyframes nav_slide-in-from-left__1m3c0 {
	from {
		left: -100%;
	}
	to {
		left: 10vw;
	}
}
/* Depth: 0 */
.crosstable_container__1iD5w {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}

.crosstable_th__Q7ggx {
	cursor: pointer;
}

.crosstable_round__2z99m {
	min-width: 20px;
}

.crosstable_pool__nPUSS {
	text-align: center;
	font-weight: bold;
	margin: 5px;
}

/* Standings */

.crosstable_table__1xVB_ {
	width: 100%;
}
.crosstable_table__1xVB_ tbody::before {
    content: '@';
    display: block;
    line-height: 2em;
	color: transparent;
	opacity: 0;
}

.crosstable_table__1xVB_ .playerName.left {
	width: 100%;
	justify-content: flex-start;
}
th.crosstable_headerCell__1MXgj {
	display: none;
}

.crosstable_moreHidden__3RsC0 {
	/* visibility: hidden; */
}

@media only screen and (max-width: 700px) {
	th.crosstable_headerCell__1MXgj {
		display: table-cell;
	}
	th.crosstable_headerCell__1MXgj > div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: black;
	}
	table > tbody > tr.crosstable_tr__1OMcp {
		display: grid;
		grid-template-columns: 50% 50%;
		background-color: #f0f0f0;
	}
	table td {
		text-align: center;
	}
	tr.crosstable_tr__1OMcp > th:nth-child(4n), tr.crosstable_tr__1OMcp > th:nth-child(4n-1), tr.crosstable_tr__1OMcp > td:nth-child(4n) {
		background-color: lightblue;
	}
}

.crosstable_footer__1NSZx {
	font-family: Josefin Sans, sans-serif;
	font-size: 14px;
	font-weight: 400;
}

/* Col 2 stuff */

.crosstable_col2__1bcMr {
	display: grid;
	height: 50vh;
}

.crosstable_button__2lxn7 {
	border-radius: 50%;
	border: grey 5px solid;
	background-color: var(--col-accent2);
	display: none;
}

/* Search Bar */
.crosstable_searchBar__2QHjR {
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent2);
	align-items: center;
	justify-content: stretch;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	padding: 5px 10px;
}

.crosstable_searchBarDiv__BkgHE {
	margin-top: 2vh;
	display: grid;
	grid-template-columns: minmax(auto, 400px);
	align-self:start;
}

.crosstable_searchBar__2QHjR > * {
	height: 100%;
	display: flex;
	align-items: center;
}

.crosstable_searchBar__2QHjR * {
	background-color: transparent;
}

.crosstable_searchBar__2QHjR form {
	padding: 2px 0;
}

.crosstable_searchBar__2QHjR div, .crosstable_searchBar__2QHjR form, .crosstable_searchBar__2QHjR input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.crosstable_searchBar__2QHjR input, .crosstable_searchBar__2QHjR input:focus {
	border: none;
	margin: 5px 0;
}
.sticky_sticky__kzuz5 {
	position: fixed!important;
	transition: 3s ease transform;
}

.sticky_sticky__kzuz5.sticky_absolute__3TZI6 {
	position: absolute!important;
}

@media only screen and (max-width: 1050px) {
	.sticky_sticky__kzuz5, .sticky_sticky__kzuz5.sticky_absolute__3TZI6 {
		position: static!important;
		width: unset;
	}
}
.leaderboard_th__AoCU4 a.playerName, div.playerName {
	min-height: unset;
}
/* Depth: 1 */
.knockout_container__3GFEr {
	width: 100%;
	position: relative;
}
.knockout_stage__2PYEg {
	width: 100%;
	overflow-x: scroll;
	position: relative;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}
.knockout_stage__2PYEg::-webkit-scrollbar {
	display: none;
}

/* Depth: 2 */
.knockout_scroll__2Lk6I {
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	z-index: 2;
	padding: 5px;
	box-sizing: border-box;
	-webkit-backdrop-filter: blur(4px);
	        backdrop-filter: blur(4px);
	background-color: rgba(150, 150, 150, 0.1);
	opacity: 0.9;
	cursor: pointer;
	transition: opacity 0.333s ease;
}
.knockout_scroll__2Lk6I:not(:hover) {
	opacity: 0.4;
}
#knockout_left__3kShv {
	left: 0px;
}
#knockout_right__1EZg2 {
	right: 0px;
}
.knockout_columns__1HnTZ {
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: auto 1fr;
	grid-column-gap: 60px;
	-webkit-column-gap: 60px;
	        column-gap: 60px;
	align-items: center;
}

/* Depth: 3 */

.knockout_roundNumber__H8JgI {
	text-align: center;
	padding: 10px;
	font-weight: bold;
	font-size: 18px;
}
.knockout_column__1qlCt {
	display: grid;
	grid-auto-rows: auto;
	height: 100%;
	box-sizing: border-box;
	font-size: small;
	font-family: "Roboto","Source Sans Pro","Open Sans","San Francisco",Helvetica,Arial,sans-serif;
}

/* Depth: 4 */
.knockout_matchUp__3Jiow {
	display: grid;
	grid-row-gap: 5px;
	row-gap: 5px;
	grid-template-rows: 1fr 1fr;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	background-color: rgb(150, 150, 150, 0.31);
	border-radius: 15px;
	padding: 5px;
	position: relative;
}
.knockout_spacer__XYmSq {
	min-height: 20px;
}

/* Depth: 4.1 */
.knockout_poolTitle__2C7J0 {
	position: relative;
	font-weight: bold;
	padding: 20px 0px 0px 0px;
	left: calc(100% - 6ch);
	display: flex;
	width: 20ch;
	justify-content: center;
}
.knockout_poolTitle__2C7J0:not(.knockout_visible__3GoHg ){
	visibility: hidden;
	left: 0px;
}

/* Depth: 5 */
.knockout_white__GNLcm, .knockout_black__3bxjQ {
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
	background-color: rgb(200, 200, 200, 0.70);
	border-radius: 15px;
	padding-left: 8px;
	box-sizing: border-box;
	position: relative;
}
.knockout_white__GNLcm svg, .knockout_black__3bxjQ svg {
	font-size: x-small;
}

/* Depth: 6 */
div.knockout_playerInfo__1lJHe {
	text-align: left;
	align-items: center;
	box-sizing: border-box;
	padding-right: 45px;
	white-space: nowrap;
	padding: 0 8px;
}
div.knockout_playerInfo__1lJHe:hover {
	background-color: unset;
}

.knockout_matchUp__3Jiow div[class^=components_resultsBridge] {
	width: unset;
	align-self: center;
	grid-auto-flow: row;
	position: absolute;
	right: 1.5ch;
	z-index: 2;
	font-size: small;
	grid-row-gap: 10%;
	row-gap: 10%;
}
.knockout_matchUp__3Jiow div[class^=components_resultsBridge]:hover {
	background-color: unset;
}
.knockout_matchUp__3Jiow div[class^=components_neutral], .knockout_playerInfo__1lJHe div[class^=components_centre] {
	display: none;
}
.knockout_matchUp__3Jiow div[class^=components_neutral], .knockout_playerInfo__1lJHe div[class^=components_centre] {
	display: none;
}

.knockout_score__388e2 {
	background-color: #6cd46380;
	padding: 0 12px;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.knockout_score__388e2.knockout_win__GFz0e {
	background-color: #91ff00de;
}

/* Lines */

.knockout_winner__2ItfJ, .knockout_all__1IdMR {
	width: 5px;
	height: 5px; 
	border-radius: 50%;
	background-color: darkblue;
	content: ' ';
	z-index: 1;
	position: absolute;
}
.knockout_all__1IdMR {
	left: -1ch;
}
.knockout_winner__2ItfJ {
	right: -1ch;
}

.knockout_connectors__3QXK_ {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 0;
}

.knockout_line__3Bc0o {
	stroke-width: 2px;
	stroke: green;
	fill: transparent;
}
.chesscom_container__AGg5n {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 40%);
	grid-column-gap: 5%;
	-webkit-column-gap: 5%;
	        column-gap: 5%;
    grid-row-gap: 30px;
    row-gap: 30px;
    margin: 20px 0;
    width: 100%;
}

@media screen and (max-width: 700px) {
	.chesscom_container__AGg5n {
		grid-template-columns: 80%;
	}
}

.chesscom_results__xtTqO {
	grid-column: 1 / span 2;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chesscom_results__xtTqO > table {
	width: 100%;
}
.chesscom_results__xtTqO > table tr > td > * {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	height: 1.5em;
}
.chesscom_results__xtTqO > table tr > td > .chesscom_score__wGavR {
	font-weight: bold;
}

.chesscom_wrapper__3vwwR {
    align-self: stretch;
	display: grid;
	grid-template-rows: 20px 1fr;
	grid-auto-flow: row;
	height: 100%;
	width: 100%;
	justify-content: center;
}

.chesscom_wrapper__3vwwR > div, .chesscom_wrapper__3vwwR > div > div {
	height: 100%;
	overflow-y: hidden;
}

.chesscom_boardlabel__E2EmQ {
	height: 100%;
}

.chesscom_boardLabel__1N2Qy > span {
    margin: 0 5px;
}

.chesscom_gameViewWrapper__T9V4M {
	grid-column: 1 / span 1;
	grid-row: 2 / span 1;
}
.chesscom_gameViewWrapper__T9V4M.hidden {
	visibility: hidden;
}

.chesscom_board__3LGEQ {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    color: black;
    background-color: blue;
    -webkit-animation: chesscom_fade-in__15-gn 0.5s ease;
            animation: chesscom_fade-in__15-gn 0.5s ease;
}

@-webkit-keyframes chesscom_fade-in__15-gn {
    from {
        opacity: 0%
    }
    to {
        opacity: 100%
    }
}

@keyframes chesscom_fade-in__15-gn {
    from {
        opacity: 0%
    }
    to {
        opacity: 100%
    }
}

.chesscom_row__17flm {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    color: black;
    background-color: blue;
}

.chesscom_row__17flm:nth-child(odd) div:nth-child(even), .chesscom_row__17flm:nth-child(even) div:nth-child(odd) {
    background-color: lightgreen;
}

.chesscom_square___JdY5 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chesscom_square___JdY5.chesscom_W__1Kbfl {
    color: white
}

.chesscom_square___JdY5.chesscom_B__misXA {
    color: black
}

.chesscom_square___JdY5 > * {
    width: 61.8%;
    height: 61.8%;
}

.chesscom_null__13qcL {
	opacity: 0.2;
}

.chesscom_active__1tUDT {
	cursor: pointer
}

.chesscom_loading__2Ayrk > svg {
	-webkit-animation: chesscom_spin__1lrfp 1s linear infinite;
	        animation: chesscom_spin__1lrfp 1s linear infinite;
}

@-webkit-keyframes chesscom_spin__1lrfp {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes chesscom_spin__1lrfp {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

div.chesscom_button__3PlYs, a.chesscom_button__3PlYs {
	border-radius: 5px;
}
.pgnWrapper {
	height: 100%;
	border-radius: 10px;
}

.pgnViewerMain {
	display: grid;
	grid-template-columns: 68.1% 30.8%;
	grid-auto-flow: row;
	height: 300px;
	padding-bottom: 10px;
}

@media screen and (max-width: 700px) {
    .pgnViewerMain {
		grid-auto-flow: column;
		grid-template-columns: unset;
		height: calc((100vw - 40px) * 0.8)
	}
	.pgnViewerMoveList {
		padding-bottom: 10px;
	}
}

.pgnViewerMoveList {
	text-align: left;
	overflow-y: scroll;
	padding-right: 0px;
	padding-left: 10px;
	font-size: 14px;
	line-height: 20px;
}

.chessboardContainer {
	-moz-user-select: none;
	 -ms-user-select: none;
	     user-select: none;
	-webkit-user-select: none;
}

.pgnViewerFooter {
	margin-top: 10px;
	-moz-user-select: none;
	 -ms-user-select: none;
	     user-select: none;
	-webkit-user-select: none;
	color: #333
}
.login_canvas__1qibW {
	width: 100%;
	height: 100%;
}

div.login_column__14Kgt {
	font-family: 'Josefin Sans', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	align-content: stretch;
	margin: auto;
	min-width: 500px;
	min-height: 400px;
	background: transparent;
}
.login_column__14Kgt:hover, .login_foreground__2Cqo8:hover {
	top: 0px;
}
.login_frame__3ssmQ {
	display: flex;
	border-radius: 15px;
	height: inherit;
	align-items: stretch;
	padding: 0px;
	background-color: var(--container-bg);
}

.login_loginSection__1BBMn {
	position: relative;
	border-radius: 15px;
	height: 100%;
	min-height: 400px;
	align-self: stretch;
	justify-content: center;
	display: grid;
	grid-template-columns: 10ch 1fr 10ch;
	grid-template-areas:
		'login . register';
	width: 100%;
}

div.login_forgottenContainer__2yR4y {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	box-sizing: border-box;
	-webkit-animation: login_fade-in__3QsyL 0.68s ease both;
	        animation: login_fade-in__3QsyL 0.68s ease both;
}

div.login_forgotten__3VFQz {
	background-color: transparent;
	color: grey;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	height: 100%;
	font-size: 16px;
	cursor: pointer;
}

div.login_forgotten__3VFQz:first-child {
	border-right: 1px solid grey;
}

.login_form__1ieaR {
	display: grid;
	grid-template-rows: repeat(4, 1fr);
	width: 100%;
}

.login_form__1ieaR > .login_input__3ze1R {
	font-family: 'Josefin Sans', sans-serif;
	width: 100%;
	overflow: hidden;
	font-size: 16px;
	margin: 0px;
	box-sizing: border-box;
	padding: 10px 14px;
	border: none;
	display: flex;
}
.login_input__3ze1R:first-child, .login_input__3ze1R:nth-child(2) {
	border-top: 1px solid grey;
}
.login_input__3ze1R:nth-child(2) {
	border-bottom: 1px solid grey;
}
.login_input__3ze1R:nth-child(3) {	
	-webkit-animation: login_fade-in__3QsyL 0.68s ease both;	
	        animation: login_fade-in__3QsyL 0.68s ease both;
}
.login_input__3ze1R.login_back__1oP5T, .login_input__3ze1R.login_text__WDlWF {
	justify-content: center;
	padding: 6px;
}
.login_input__3ze1R.login_back__1oP5T {	
	cursor: pointer;
}

.login_foreground__2Cqo8 button[type='submit'].login_submit__2qxJT {
	font-family: 'Josefin Sans', sans-serif;
	width: 100%;
	justify-content: center;
	display: flex;
	padding: 10px;
	margin: 0px;
	box-sizing: border-box;
	border: none;
	border-radius: 15px;
	color: white;
	background-color: #1e1e1e;
	cursor: pointer;
	transition: all 0.25s ease-out;
}
.login_foreground__2Cqo8 input.login_submit__2qxJT:hover {
	background-color: #43853d;
}

.login_foreground__2Cqo8 {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: flex-end;;
	align-items: center;
	position: absolute;
	height: 100%;
	width: calc(100% - 10ch);
	opacity: 1;
	margin: 0px;
	background-color: lightgrey;
	box-sizing: border-box;
}

div.login_flip__2KG2q {
	display: flex;
	flex-direction: column;
	border-radius: 0px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
}
.login_flip__2KG2q.login_loginButton__1XTHr {
	grid-area: login;
}
.login_flip__2KG2q.login_registerButton__1vv8o {
	grid-area: register;
}

@media only screen and (max-width: 700px) {
	div.login_column__14Kgt {
		min-width: 90vw;
		display: grid;
	}
	.login_loginSection__1BBMn {		
		grid-template-columns: 100%;
		grid-template-rows: 5ch auto 5ch;
		grid-template-areas:
			'login'
			'.'
			'register';
	}
	div.login_foreground__2Cqo8 {
		grid-area: 1 / span 2;
		width: 100%;
		height: calc(100% - 5ch);
	}
	.login_forgotten__3VFQz {		
		font-size: min(16px, 4vw);
	}
	div.login_flip__2KG2q {
		grid-area: login;
	}
}

.login_foreground__2Cqo8 input {
	background-color: transparent;
}

.login_loginForeground__lnGgY {
	overflow: hidden;
	top: 0px;
	left: 0px;
	transition: all 0.68s ease-out;
}

.login_registerForeground__3D6jX {
	left: 10ch;
	transition: all 0.68s ease-out;
}

@media only screen and (max-width: 700px) {
	.login_registerForeground__3D6jX {
		left: unset;
		top: 5ch;
	}
	div.login_flip__2KG2q {
		flex-direction: row;
	}
}

.login_flip__2KG2q > div {
	display: flex;
	justify-content: center;
	align-items: center;
    margin: 2px;
}

div.login_messageBar__6TM4O {
	display: flex;
	width: 100%;
    padding: 5px 0 5px 5px;
    box-sizing: border-box;
    border: 2px #252526 solid 0.0;
	box-shadow: none;
	justify-content: center;
	color: red;
}

@-webkit-keyframes login_fade-in__3QsyL {
	from {
		opacity: 0.0;
	}
	to {
		opacity: 1.0;
	}
}

@keyframes login_fade-in__3QsyL {
	from {
		opacity: 0.0;
	}
	to {
		opacity: 1.0;
	}
}

/* CROWN */


div.login_logo__UJ7Qe {
	width: 50%;
	max-width: 200px;
	display: grid;
	position: relative;
	grid-template: 100% / 100%;
}

img.login_staticLogo__Z0jZQ {
	width: 100%;
	position: relative;
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
}

img.login_loadingGif__WfBMg {
    position: absolute;
    width: 66.6%;
    max-width: 200px;
    transform: rotate(-33deg);
    opacity: 1;
    left: 6%;
    top: 15%;
	position: relative;
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
}

img.login_crownOutline__2mObX {
    position: absolute;
    width: 71%;
    max-width: 213px;
    transform: rotate(-32deg);
    opacity: 1;
    z-index: 1;
    left: 5%;
    top: 13%;
	position: relative;
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
}

@media screen {
	.login_logo__UJ7Qe {
		-webkit-animation: login_fade-in__3QsyL 1 0.5s linear;
		        animation: login_fade-in__3QsyL 1 0.5s linear;
	};
	.login_loadingLine__1iXG0 {
		-webkit-animation: login_grow-right__2HxVz infinite 0.68s linear;
		        animation: login_grow-right__2HxVz infinite 0.68s linear;
	}
}

@keyframes login_fade-in__3QsyL {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}
.sidebar_sidebar__2onqj {
	position: fixed;
	width: calc(1132px / 4);
	top: 8vh;
	right: 0px;
	display: grid;
	grid-row-gap: 10px;
	row-gap: 10px;
	transition: 3s ease transform;
	opacity: 1;
}

@media only screen and (max-width: 1050px) {
	div.sidebar_sidebar__2onqj {
		position: static;
		width: unset;
	}
	.sidebar_sidebar__2onqj .ct-chart {
		display: none;
	}
}

.sidebar_name__36s3Y {
	font-weight: bold;
	text-align: center;
}

/* Announcement */

.sidebar_announcement__3Kxqn {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		'title title'
		'body body'
		'dates more';
}
.sidebar_announcement__3Kxqn .sidebar_title__3-Bzg {
	grid-area: title;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	border-radius: 10px;
	padding: 0px 5px;
	align-items: center;
	font-size: medium;
}
.sidebar_announcement__3Kxqn .sidebar_body__yxp0C {
	grid-area: body;
}
.sidebar_announcement__3Kxqn .sidebar_body__yxp0C *, .sidebar_announcement__3Kxqn .sidebar_dates__3BaX0 {
    font-family: "Roboto", Consolas, Monaco, "Andale Mono", monospace;
	font-size: x-small!important;
	-webkit-margin-before: 0em;
	        margin-block-start: 0em;
	-webkit-margin-after: 0em;
	        margin-block-end: 0em;
}
.sidebar_announcement__3Kxqn .sidebar_dates__3BaX0 {
	grid-area: dates;
	font-weight: bold;
}
.sidebar_announcement__3Kxqn .sidebar_more__2_1JR {
	display: flex;
	align-items: center;
	grid-area: more;
	border-radius: 6px;
	background-color: var(--col-accent2);
	font-size: x-small;
	padding: 0 8px;
}

/* Chart */

.sidebar_sidebar__2onqj .ct-chart {
	max-height: 120px;
}
.sidebar_sidebar__2onqj .gauge .ct-label {
	font-size: 16px;
}

/* Floating buttons */


.sidebar_sidebar__2onqj .button {
	display: grid;
	grid-template-columns: minmax(1.5ch, auto) 1fr minmax(1.5ch, auto);
	text-align: center;
	justify-content: center;
	align-items: center;
}

div.sidebar_statusPicker__3PMmo > div.button {
	border-radius: 10px;
}
div.sidebar_timezone__Q_npt, div.sidebar_download__ctCo6, div.sidebar_jumpTo__1aUvg, div.sidebar_expandAll__1JSCF, .sidebar_statusPicker__3PMmo > div {
	border-radius: 10px;
	padding: 0 5px;
	box-sizing: border-box;
}

.sidebar_searchBar__1tfzm {
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent2);
	align-items: center;
	justify-content: stretch;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	padding: 5px 10px;
}

.sidebar_searchBar__1tfzm > * {
	height: 100%;
	display: flex;
	align-items: center;
}
.sidebar_searchBar__1tfzm * {
	background-color: transparent;
}
.sidebar_searchBar__1tfzm form {
	padding: 2px 0;
}

.sidebar_searchBar__1tfzm div, .sidebar_searchBar__1tfzm form, .sidebar_searchBar__1tfzm input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.sidebar_searchBar__1tfzm input, .sidebar_searchBar__1tfzm input:focus {
	border: none;
	margin: 5px 0;
}

.dropdown_container__1hKRx {
	position: relative;
}

.dropdown_header__1BLxu {
	display: flex;
	flex-direction: row;
	position: relative;
	height: unset;
}
div.dropdown_header__1BLxu:hover:not(.dropdown_sent__1kGzj) {	
	color: var(--col-accent1-hover);
}


.dropdown_wrapper__1ecPY {
	position: absolute;
	overflow: hidden;
	width: 100%;
	transition: height 0.33s ease;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	z-index: 1;
}

.dropdown_list__3lWIJ {
	margin: 0px;
	padding: 0px;
	width: 100%;
	display: grid;
	grid-row-gap: 1px;
	row-gap: 1px;
}

.dropdown_list__3lWIJ li {
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: var(--col-accent2);
	padding: 5px;
	box-sizing: border-box;
	cursor: pointer;
	font-weight: 600;
}

.dropdown_list__3lWIJ li:hover {
	color: var(--col-accent1-hover);
	background-color: var(--col-accent2-hover);
}

section.streams_container__3OSGu {
	margin: 20px 0;
	font-family: 'Josefin Sans', sans-serif;
	font-size: 16px;
}

.streams_streamHeader__2u9wC {
	display: grid;
	grid-template-columns: auto 1fr 100px;
	margin: 10px;
	align-items: center;
}

.streams_streamHeader__2u9wC > *:not(.button) {
	padding: 0 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
@media only screen and (max-width: 700px) {
	.streams_popOut__20aUz {
		display: none;
	}
}

.streams_streamHeader__2u9wC > span {
	width: 90%;
}

.streams_streamContainer__28Cw5 {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 700px) {
	.streams_streamHeader__2u9wC {
		flex-direction: column;
	}
	.streams_streamContainer__28Cw5 {
		flex-direction: column;
	}
	div.streams_player__1u4zp {
		width: 100%;
		height: 68vh;
	}
	div#streams_twitchChat__2j9X5 {
		height: 80vh;
		width: 100%;
	}
}

.streams_player__1u4zp {
	height: calc((1132px - 40px) / 1.78);
	width: 68%;
}

div.popped .streams_player__1u4zp {
	height: calc(30vw / 1.68);
	width: 100%;
	-webkit-animation: streams_move-to-corner__1zn6f 0.5s ease;
	        animation: streams_move-to-corner__1zn6f 0.5s ease;
}

.streams_chat__3ysIe {
	height: calc((1132px - 40px) / 1.78);
	width: 32%;
}

@media screen and (max-width: 1132px) {
	.streams_player__1u4zp {
		height: calc((100vw - 40px) / 1.78);
		width: 68%;
	}
	.streams_chat__3ysIe {
		height: calc((100vw - 40px) / 1.78);
		width: 32%;
	}
}

.streams_footer__NYfmO {
	padding-top: 10px;
	margin: 20px 0;
	width: 100%;
	text-align: center;
	font-size: 13px;
	letter-spacing: -0.5px;
	text-align: left;
}

#streams_twitchPlayer__ZwLdZ, #streams_twitchChat__2j9X5 {
	visibility: visible;
}

@-webkit-keyframes streams_move-to-corner__1zn6f {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}

@keyframes streams_move-to-corner__1zn6f {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}
.chartist-tooltip {
	background-color: palegoldenrod;
	z-index: 1;
	display: none;
	/*
	flex-direction: column;
	justify-content: center;
	justify-items: center;*/
	font-family: 'Josefin Sans';
	border-radius: 20px;
	padding: 5px;

	position: absolute;
	margin-bottom: 160px;
	/*
	margin-left: 140px;*/
}

div.tooltip-show {
	display: flex;
}

.chartContainer {
	display: grid;
	grid-template-columns: 100%;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

@media screen and (max-width: 700px) {
	.chartContainer {
		grid-auto-flow: row;
	}

	div.chartKey {
		grid-column: 1 / span 2;
		grid-row: 2 / span 1;
		text-align: center;
	}
}

.ct-chart {
	grid-column: 1 / span 2;
	grid-row: 1 / span 1;
	font-family: 'Josefin Sans', sans-serif;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	position: relative;
}

.ct-chart .ct-label {
	z-index: 2;
}

.chartKey {
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
	position: relative;
	height: calc(80vh - 15px);
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	display: grid;
	font-family: 'Josefin Sans';
	position: relative;
	pointer-events: none;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.playerLabel {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.playerLabel hr {
	border: dashed 0.5px;
	margin: 0 10px;
	height: 0.5px;
	opacity: 0.3;
	position: relative;
	z-index: -1;
}

@media screen and (max-width: 700px) {
	.playerLabel hr {
		display: none;
	}
}

[*|meta='placeholder'] {
	display: none;
}

.gauge .ct-axis-title,
.ct-label {
	color: var(--col-accent3);
	font-family: 'Josefin Sans', sans-serif;
}

.rankings .ct-axis-title,
.ct-label {
	color: var(--col-accent3);
	font-family: 'Josefin Sans', sans-serif;
	position: absolute;
	right: 0px;
}
.dark .ct-label {
	fill: var(--col-accent3);
}
.rankings .ct-chart-line {
	overflow: visible
}

.ct-label.ct-vertical.ct-start {
	overflow: visible;
}

.chartArrow {
	align-items: center;
	margin: 5px 0;
	line-height: 100%;
	height: 100%;
}

.chartArrow>div {
	display: inline-block;
	width: 25px;
	justify-content: right;
	text-align: right;
	margin-right: 5px;
}

.chartArrow>svg {
	height: 100%;
	position: relative;
	top: 5px;
	margin-right: 5px;
	width: 25px;
}

.chartPositive>svg {
	color: green;
}

.chartNegative>svg {
	color: red;
}

.chartNeutral>svg {
	color: grey;
}

/* PIE */

.gauge>div {
	max-height: 220px;
	margin-bottom: 20px;
}

.gauge div.ct-chart>svg {
	overflow: visible;
	padding-top: 20px;
	padding-bottom: 20px;
	pointer-events: none;
}

.gauge .ct-label {
	margin: 50px;
}


.ct-slice-donut-solid[*|meta='confirmed'] {
	fill: gold
}

.ct-slice-donut-solid[*|meta='finished'] {
	fill: #43853d
}

.ct-slice-donut-solid[*|meta='live'] {
	fill: rgb(73, 73, 255);
}

.ct-slice-donut-solid[*|meta='registered'] {
	fill: turquoise
}

.ct-slice-donut-solid[*|meta='scheduled'] {
	fill: turquoise;
}

.ct-slice-donut-solid[*|meta='pending'] {
	fill: grey
}

.ct-slice-donut-solid[*|meta='aborted'] {
	fill: var(--col-alert1)
}

.gauge .ct-label {
	font-size: 24px;
}

/* Slider */

.sliderContainer {
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: stretch;
}

.sliderContainer .slider {
	margin: 0 10px;
	width: 100%;
	display: flex;
	align-items: center;
}

.sliderContainer>.slider>div {
	height: 10px;
	background-color: #43853d;
}

.sliderContainer>.slider>.track {
	border-radius: 5px;
}

.sliderContainer>.slider>.thumb {
	background-color: rgb(150, 150, 150);
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 5px solid rgb(200, 200, 200);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}

.increment {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px;
}

.optionsContainer {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
}

@media only screen and (max-width: 700px) {
	.optionsContainer {
		display: grid;
		grid-auto-rows: 1fr;
		grid-auto-flow: row;
		grid-row-gap: 15px;
		row-gap: 15px;
	}
}

.optionsContainer > div, .optionsContainer > div:active {
	display: grid;
	grid-template-columns: 68.1fr 30.8fr;
	grid-auto-flow: column;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	height: 100%;
	border-radius: 15px;
	padding: 10px;
	align-items: center;
	position: relative;
	cursor: default
}

.optionsContainer > div:hover > * {
	background-color: unset;
	color: unset;
}

/* Toggle Switch */

/* The switch - the box around the slider */
.toggleSwitch {
	position: relative;
	max-width: 60px;
	justify-self: center;
}

/* Hide default HTML checkbox */
.toggleSwitch input.check {
	display: none;
}

/* The slider */
.toggleSlider {
	position: relative;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 80px;
	background-color: #ccc;
	transition: .4s;
	min-height: 20px;
	display: flex;
}

.toggleSlider:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 30.8%;
	left: 4px;
	background-color: white;
	transition: .4s;
}

input.check:checked+.toggleSlider {
	background-color: #2196F3;
}

input.check:focus+.toggleSlider {
	box-shadow: 0 0 1px #2196F3;
}

input.check:checked+.toggleSlider:before {
	left: calc(100% - 30.8% - 4px);
}

/* Rounded sliders */
.toggleSlider.round {
	border-radius: 34px;
}

.toggleSlider.round:before {
	border-radius: 34px;
}

.switchContainer label div:not(:first-child) {
	font-size: 14px;
	font-weight: 300;
}
.switchContainer {
	border-radius: 15px;
}
.switchContainer:hover > * {
	background-color: transparent;
}
.stats_fsChart__3pHze {
	font-family: 'Josefin Sans', sans-serif;
}

.stats_fsChart__3pHze .stats_title__XSaLS {
	font-family: 'Josefin Sans', sans-serif;
	font-size: xx-large;
	font-weight: bold;
}
.stats_fsChart__3pHze .stats_subtitle__24ox6 {
	font-family: 'Josefin Sans', sans-serif;
	font-weight: light;
}

.stats_fsChart__3pHze .ct-chart {
	margin: 0 40px;
	width: 80%;
}

.stats_name__178sR, .stats_url__1r9Nj {
	display: flex;
	font-family: 'Josefin Sans', sans-serif;
	position: absolute;
	bottom: 20px;
}

.stats_name__178sR {
	left: 20px;
	font-weight: bold;
}

.stats_url__1r9Nj {
	right: 20px;
}

.stats_fsChart__3pHze .ct-chart {
	grid-area: content;
}

.stats_fsChart__3pHze .stats_header__3zy0s {
	grid-area: title;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

@media only screen and (max-width: 700px) {
	.stats_fsChart__3pHze .ct-chart {
		margin: 40px 0;
	}
}


/* Full screen */

.fullscreen_container__2o3cX {
	display: grid;
	grid-template-areas:
		'header header header'
		'title title title'
		'content content content'
		'brand . url';
	grid-template-rows: 50px auto auto 50px;

	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 3;
	background-color: white;

	-webkit-font-smoothing: antialiased;
	font: 400 20px/1.5 'Roboto', "Source Sans Pro", "Open Sans", "San Francisco", Helvetica, Arial, sans-serif;
	color: #333;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1200;
	justify-content: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s, opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	transform: translate3d(0px, 0px, 0px);
	opacity: 1;
}

.fullscreen_container__2o3cX > .fullscreen_fullScreenHeader__8hyCZ, .fullscreen_container__2o3cX > .header {
	grid-area: header;
	width: 100vw;
	display: flex;
	position: absolute;
}

.fullscreen_container__2o3cX > .fullscreen_fullScreenHeader__8hyCZ {
	opacity: 0;
	transition: opacity 0.333s ease;
}

.fullscreen_container__2o3cX > .fullscreen_fullScreenHeader__8hyCZ > * {
	width: 100%;
}

.fullscreen_container__2o3cX > .fullscreen_fullScreenHeader__8hyCZ:hover {
	width: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0));
	opacity: 1;
}

.fullscreen_stage__2cH6J {
	grid-area: title / content / footer / content;
	width: 100%;
	padding: 0px 80px;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	display: grid;
	grid-template-rows: auto auto 50px;
	grid-template-areas:
		'title title title'
		'content content content'
		'brand . url';
}

@media only screen and (max-width: 700px) {

	.fullscreen_stage__2cH6J {
		grid-area: unset;
	}
}

.fullscreen_container__2o3cX .chartHeader, .fullscreen_container__2o3cX .chartFooter {
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.fullscreen_container__2o3cX .chartHeader {
	top: 50px;
}
.fullscreen_container__2o3cX .chartFooter {
	bottom: 50px;
}

.fullscreen_container__2o3cX button svg {
	color: white;
	cursor: pointer;
	height: 100%;
}

.fullscreen_container__2o3cX .fullscreen_fullScreenHeader__8hyCZ span:last-child {
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
}

.fullscreen_container__2o3cX .fullscreen_fullScreenHeader__8hyCZ button {
	width: 50px;
	background: none;
	border: none;
	color: white;
	transition: opacity 300ms, top 300ms;
	justify-self: right;
	box-sizing: border-box;
	opacity: 0;
	position: relative;
	top: -50%;
}

.fullscreen_container__2o3cX .fullscreen_fullScreenHeader__8hyCZ:hover button {
	top: 0px;
    opacity: 1;
}
.css-1rkjyjh {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -webkit-flex: 0 0 auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
}
.meta_container__37e3D {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-auto-rows: auto;
	grid-row-gap: 10px;
	row-gap: 10px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	font-family: Josefin Sans, sans-serif;
	margin: 10px;
	font-size: small;
	margin-bottom: 50px;
	grid-auto-flow: row;
	align-items: stretch;
}

.meta_container__37e3D > *:not(.meta_nav__3NyJ8) {
	grid-column: 1;
}

.meta_nav__3NyJ8 {
	grid-column: -1;
	grid-row: 1 / -1;
	min-width: 100px;
	position: relative;
}
.meta_nav__3NyJ8 > div, .meta_nav__3NyJ8 > div:hover {
	border-left: var(--col-accent1) 2px solid;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	position: fixed;
	max-height: 80vh;
	top: 10vh;
	padding: 10px;
	z-index: 1;
	display: grid;
	grid-row-gap: 15px;
}

.meta_divider__3oJiV {
	height: 1px;
	width: 100%;
	background-color: black;
}

.meta_announcement__AfOYM {
	margin-top: 0;
	font-family: Josefin Sans, sans-serif;
	text-align: justify;
}
.meta_announcement__AfOYM p {
    -webkit-margin-before: 0.7em;
            margin-block-start: 0.7em;
    -webkit-margin-after: 0.7em;
            margin-block-end: 0.7em;
}

.meta_title__xgqyZ {
	font-size: large;
	font-weight: bold;
	display: flex;
	flex-direction: row;
}

.meta_title__xgqyZ svg {
	margin-right: 10px;
}
/* Draggable */

.components_draggable__6I6m8 {
	position: fixed;
	width: 31%;
	bottom: 10px;
	right: 180px;
	-webkit-animation: components_move-to-corner__2IMAP 0.25s ease;
	        animation: components_move-to-corner__2IMAP 0.25s ease;
	display: flex;
	flex-direction: column;
}

.components_draggable__6I6m8 div {
	width: 100%;
}

@-webkit-keyframes components_move-to-corner__2IMAP {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}

@keyframes components_move-to-corner__2IMAP {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}

/* Lightbox */


.components_shade__3PndK {
	background-color: rgba(25, 25, 25, 0.68);
	position: fixed;
	width: 100vw;
	height: 100vh;
	bottom: 0px;
	left: 0px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Josefin Sans', sans-serif;
	overflow: hidden;
	z-index: 4;
}

.components_shade__3PndK .components_section__2oPYC {
	max-width: 60vw;
	background-color: lightgray;
	border-radius: 40px;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-animation: components_vertical-split__17qtW 0.5s ease;
	        animation: components_vertical-split__17qtW 0.5s ease;
	color: black;
	text-shadow: none;
}
@-webkit-keyframes components_vertical-split__17qtW {
	from {
		max-height: 0px;
		overflow: hidden;
	}
	to {
		max-height: 100%;
		overflow: hidden;
	}
}
@keyframes components_vertical-split__17qtW {
	from {
		max-height: 0px;
		overflow: hidden;
	}
	to {
		max-height: 100%;
		overflow: hidden;
	}
}
@media only screen and (max-width: 700px) {
	.components_shade__3PndK .components_section__2oPYC {
		max-width: unset;
		max-height: 60vh;
	}
}
.components_lightBoxContent__1phCW {
	box-sizing: border-box;
	padding: 25px;
	word-break: break-word;
}
.components_lightBoxContent__1phCW button, .components_lightBoxContent__1phCW input, .components_lightBoxContent__1phCW textarea {
	border: none;
	outline: none;
	color: var(--col-accent1);
	font: unset;
	border-radius: 5px;
	cursor: pointer;
}
.components_lightBoxContent__1phCW input, .components_lightBoxContent__1phCW textarea {
	margin: 10px;
	cursor: unset;
	width: calc(100% - 20px);
	box-sizing: border-box;
}
.components_lightBoxContent__1phCW textarea {
	min-height: 20vh;
}
.components_lightBoxContent__1phCW button:hover {
	background-color: var(--col-accent1);
	color: white;
}

.components_lightBoxTitle__b48fv {
	display: flex;
	justify-content: center;
	font-weight: 600;
	background-color: orange;
	padding: 5px;
	cursor: pointer;
}
.components_lightBoxTitle__b48fv.components_error__3Plio {
	background-color: var(--col-alert1);
}
.components_lightBoxTitle__b48fv svg, .components_shade__3PndK img {
	height: 100%;
	max-height: 1em;
}

div.components_lightBoxTitle__b48fv:hover {
	background-color: var(--col-alert1);
}
div.components_lightBoxTitle__b48fv.components_error__3Plio:hover {
	background-color: yellow;
}

.components_lightBoxTitle__b48fv > * {
	padding: 5px;
}

.components_dateTime__2VtC6 {
	background-color: #f0f0f0;
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	box-sizing: border-box;
}
.components_footer__27uos {
	margin: 5px;
}
.components_resolve__1X_KN, .components_reject__3ymMO {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	padding: 5px;
	width: 100%;
	cursor: pointer;
}
.components_resolve__1X_KN:hover, .components_reject__3ymMO:hover {
	color: white;
	opacity: 0.68;
}
.components_reject__3ymMO {
	background-color: var(--col-alert1);
}
.components_resolve__1X_KN {
	background-color: lightgreen;
}
.components_error__3Plio {
	color: red;
	width: 100%;
}

/* Profiles */

.components_divide__37rpK {
	display: grid;
	grid-template-columns: 1fr auto auto 1fr;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	font-weight: 300;
	align-items: center;
	cursor: pointer;
	font-family: Josefin Sans, sans-serif;
	margin: 20px 20px 0 20px;
}

.components_divide__37rpK > hr {
	height: 1px;
	width: 100%;
	background-color: darkgrey;
	border-radius: 2px;
}
.components_divide__37rpK:hover > hr {
	background-color: lightgrey;
}
.components_divide__37rpK:hover {
	color: var(--col-accent2-hover);
}
.components_divide__37rpK > svg {
	height: 0.68em;
	width: 0.68em;
	transition: transform 0.333s ease;
}
.components_divide__37rpK:not(.components_collapsed__1YIKM) > svg {
	transform: rotate(0deg);
}
.components_divide__37rpK.components_collapsed__1YIKM > svg {
	transform: rotate(450deg);
}

.components_divide__37rpK {
	grid-column: 1 / -1;
}
section.results_fireworksSection__2qvrE {
	position: relative;
	overflow: hidden;
}

.results_resultsScreen__KIKHg {
	font-family: 'Josefin Sans', sans serif;
	display: grid;
	grid-template-rows: calc(200px * 0.32) repeat(3, auto);
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 40px;
	-webkit-column-gap: 40px;
	        column-gap: 40px;
	position: relative;
	grid-template-areas:
		'. first .'
		'second first third'
		'name name name'
		'description description description';
}
@media only screen and (max-width: 700px) {
	.results_resultsScreen__KIKHg {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(5, auto);
		grid-auto-flow: row;
		grid-row-gap: 40px;
		row-gap: 40px;
		grid-template-areas:
			'first'
			'second'
			'third'
			'name'
			'description';
	}
}

.results_place__3ZhVh {
	height: -webkit-min-content;
	height: -moz-min-content;
	height: min-content;
	justify-content: center;
	text-align: center;
	position: relative;
}
.results_place__3ZhVh#first {	
	grid-area: first;
}
.results_place__3ZhVh#second {	
	grid-area: second;
}
.results_place__3ZhVh#third {	
	grid-area: third;
}

.results_place__3ZhVh#first > svg {
	color: gold;
	font-size: 200px;
}
.results_place__3ZhVh#second > svg {
	grid-area: second;
	color: silver;
	font-size: calc(200px * 0.68);
}
.results_place__3ZhVh#third > svg {
	grid-area: third;
	color: #cd7f32;
	font-size: calc(200px * 0.68);
}

.results_position__3Onk_ {
	position: absolute;
	left: calc(50% - 0.8ch);
	top: 25px;
	font-weight: bolder;
	font-size: 20px;
}
.results_place__3ZhVh#first > .results_position__3Onk_ {
	left: calc(50% - 0.65ch);
}
.results_position__3Onk_ > sup {
	font-weight: normal;
	font-size: 15px;
}

.results_teamName__2Pxe5 {
	grid-area: name;
	color: black;
	font-size: 20px;
	font-weight: bold;
}

.results_table__1keWa {
	display: grid;
	grid-template-columns: [blank] 1fr [key] -webkit-max-content [value] 1fr;
	grid-template-columns: [blank] 1fr [key] max-content [value] 1fr;
	grid-auto-rows: auto;
	font-size: 18px;
}

.results_table__1keWa .results_key__3XFuQ {
	font-weight: 300;
}

a.results_standingsButton__36hZx {
	padding: 5px;
}

.results_key__3XFuQ {
	grid-column: key
}
.results_value__3Qasx {
	grid-column: value;
}
.results_flag__2-WYI {
	display: flex;
	justify-content: center;
	align-items: center;
}
.results_flag__2-WYI > svg, .results_flag__2-WYI > img {
	height: 1em;
	outline: 1px lightgrey solid;
}

.results_links__2vHBV {
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
}

.results_links__2vHBV a:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.results_links__2vHBV a:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
  
  .pyro > .before, .pyro > .after {
	position: absolute;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
	-webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
	animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }
  
  .pyro > .after {
	-webkit-animation-delay: 1.25s, 1.25s, 1.25s;
	animation-delay: 1.25s, 1.25s, 1.25s;
	-webkit-animation-duration: 1.25s, 1.25s, 6.25s;
	animation-duration: 1.25s, 1.25s, 6.25s; }
  
  @-webkit-keyframes bang {
	from {
	  box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
  @keyframes bang {
	from {
	  box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
  @-webkit-keyframes gravity {
	to {
	  transform: translateY(200px);
	  -moz-transform: translateY(200px);
	  -webkit-transform: translateY(200px);
	  -o-transform: translateY(200px);
	  -ms-transform: translateY(200px);
	  opacity: 0; } }
  @keyframes gravity {
	to {
	  transform: translateY(200px);
	  -moz-transform: translateY(200px);
	  -webkit-transform: translateY(200px);
	  -o-transform: translateY(200px);
	  -ms-transform: translateY(200px);
	  opacity: 0; } }
  @-webkit-keyframes position {
	0%, 19.9% {
	  margin-top: 10%;
	  margin-left: 40%; }
  
	20%, 39.9% {
	  margin-top: 40%;
	  margin-left: 30%; }
  
	40%, 59.9% {
	  margin-top: 20%;
	  margin-left: 70%; }
  
	60%, 79.9% {
	  margin-top: 30%;
	  margin-left: 20%; }
  
	80%, 99.9% {
	  margin-top: 30%;
	  margin-left: 80%; } }
  @keyframes position {
	0%, 19.9% {
	  margin-top: 10%;
	  margin-left: 40%; }
  
	20%, 39.9% {
	  margin-top: 40%;
	  margin-left: 30%; }
  
	40%, 59.9% {
	  margin-top: 20%;
	  margin-left: 70%; }
  
	60%, 79.9% {
	  margin-top: 30%;
	  margin-left: 20%; }
  
	80%, 99.9% {
	  margin-top: 30%;
	  margin-left: 80%; } }

/* Depth: 1 */

.info_info__2j2R2 {
	display: grid;
	grid-template-rows: 50px repeat(3, auto) 1fr;
	grid-row-gap: 5px;
	row-gap: 5px;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
	justify-content: center;
	overflow: visible;
	margin: 30px;
	margin-right: 0px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	max-width: 300px;
	background-color: var(--container-bg);
}
.info_info__2j2R2.info_small__cklkw {
	width: unset;
	align-self: stretch;
	grid-template-rows: 5px repeat(3, auto) 1fr;
}
.info_info__2j2R2 * {
	display: flex;
	justify-content: center;
	text-align: center;
	transition: all 0.333s ease;
}

.info_info__2j2R2 h1 {
	display: flex;
	margin: 20px 0;
	-webkit-margin-after: 0em;
	        margin-block-end: 0em;
	font-size: min(2vw, 36px);
}
.info_info__2j2R2.info_small__cklkw h1 {
	margin: 15px 0px 0px;
	font-size: smaller;
}
.info_info__2j2R2 h3 {
	margin: 0;
}

/* Depth 1.5 */

.info_cols__1yYqW {
	display: grid;
	grid-template-rows: auto auto;
	padding: 0 5px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}

/* Depth: 2.0 */

.info_flag__1H1VP {
	position: relative;
	top: -35px;
	height: 100px;
	display: flex;
	justify-content: center;
	border-radius: 10px;
}
.info_small__cklkw .info_flag__1H1VP {
	height: 50px;
}
.info_flag__1H1VP > img {
	width: unset;
	max-width: unset;
	height: 100px;
	height: 100%;
	border-radius: 10px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

/* Depth 2.1 */

.info_name__1t72C, .info_name__1t72C *, .info_name__1t72C:hover, a.info_name__1t72C, a.info_name__1t72C * {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 900;
	margin-top: 10px;
	text-transform: uppercase;
	padding: 5px;
	background-color: unset;
	margin: unset;
	transition: unset;
}
a.info_name__1t72C {
	color: black;
}
a.info_name__1t72C:hover {
	background-color: unset;
	color: var(--col-accent1);
}

/* Depth 2.2 */

.info_bio__3T2uc:not(:empty) {
	display: flex;
	justify-content: center;
	padding: 0 10px;
	border-radius: 15px;
	font-style: italic;
	font-weight: 300;
	font-size: small;
	margin-bottom: 10px;
}

/* Depth 2.3 */

.info_details__pv7Xk {
	display: grid;
	grid-row-gap: 10px;
	row-gap: 10px;
	margin: 10px 0;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}
.info_small__cklkw .info_details__pv7Xk {
	font-size: small;
	margin: 5px 0;
	grid-row-gap: 5px;
	row-gap: 5px;
}
.info_detail__GcdDt {
	display: flex;
	flex-direction: column;
	position: relative;
	white-space: pre-wrap;
	word-break: break-all;
	justify-content: flex-start;
}
.info_detail__GcdDt .info_title__lFhDd {
	opacity: 0.68;
	text-transform: uppercase;
	font-size: small;
}

/* Depth 2.4 */

.info_buttons__3NQRo {
	display: grid;
	grid-auto-flow: row;
	grid-row-gap: 12px;
	row-gap: 12px;
	padding-top: 10px;
	justify-content: stretch;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}
.info_small__cklkw .info_buttons__3NQRo {
	font-size: small;
}
.info_buttons__3NQRo div.button, .info_buttons__3NQRo a.button {
	padding: 10px 20px;
	border-radius: 7px;
	width: inherit;
	word-break: break-word;
}
.info_small__cklkw .info_buttons__3NQRo > div, .info_small__cklkw .info_buttons__3NQRo > a {
	padding: 5px 10px;
}
.info_buttons__3NQRo .button svg, .info_buttons__3NQRo .button img {
	padding: 0 5px;
}

/* Depth 2.5 */

.info_memberBox__3Qob9 {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
	top: 0px;
	left: 0px;
	white-space: pre-wrap;
	font-size: small;
}
.info_memberBox__3Qob9 .playerName {
	display: flex;
}
.info_small__cklkw .info_memberBox__3Qob9 .playerName {
	min-height: unset;
}

/* Depth 2.6 */

a.info_more__8BqKY {
	display: flex;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	align-self: end;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	justify-self: center;
	justify-content: center;
	align-items: center;
	padding: 2px 20px;
	border-radius: 1em;
	transition: unset;
	font-size: small;
	text-transform: uppercase;
}
a.info_more__8BqKY svg {
	padding: 0 5px;
}

@media only screen and (max-width: 700px) {
	.info_container__2WVXM {
		grid-template-columns: 1fr;
	}
	.info_section__1Wa9L > table {
		margin-bottom: 0px;
	}
	.info_section__1Wa9L > table thead {
		display: table-header-group;
	}
	.info_section__1Wa9L > table th {
		width: 100%;
		display: flex;
	}
	.info_section__1Wa9L > table td {
		width: 100%;
		display: flex;
		text-align: center;
	}
}
.info_sectionHeader__1p1UY {
	background-color: rgba(135, 207, 235, 0.5);
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	border-radius: 15px;
	overflow: hidden;
}

.info_expando__26_ZT {
	cursor: pointer;
}

.info_status__334R_ {
	color: #43853d;
	display: flex;
	justify-content: center;
	padding: 0 10px 0.5em;
}
.info_status__334R_ > svg {
	font-size: 2em;
}
.info_status__334R_:not(.info_online__1Pz8P) {
	color: darkgrey;
}
/* Depth: 1 */

.user_container__1dvTh {
	box-sizing: border-box;
	overflow: hidden;

	display: grid;
	grid-column-gap: 25px;
	-webkit-column-gap: 25px;
	        column-gap: 25px;
	grid-row-gap: 25px;
	row-gap: 25px;
	grid-template-columns: 30.8fr 68.1fr;
	grid-template-areas:
		'box modes';
	font-family: 'Josefin Sans', sans-serif;
	min-height: 68vh;
}

/* Depth: 2 */
.user_col1__3bNLm {
	grid-area: box;
}
.user_col2__1JC_B {
	grid-area: modes;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	display: flex;
	flex-direction: column;
}

/* Depth: 3 */
.user_infoWrapper__2j1nO {
	position: relative;
}

.user_container__1dvTh > * {
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin: 5px;
}

.user_section__3uWp7 {
	display: grid;
	overflow: hidden;
}

@media only screen and (max-width: 700px) {
	div.user_container__1dvTh {
		grid-template-columns: 100%;
	}
	div.user_section__3uWp7 {
		grid-column: unset;
		grid-row: unset;
	}
	div.user_title__1_x29 {
		grid-column: unset;
	}
}

/* Depth: 4 */
.user_wrapper__1caZc {
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}

section.user_accountSection__1v38f:not(:first-of-type) {
	margin-top: 5px;
}
section.user_accountSection__1v38f:first-of-type {
	margin-top: 25px;
}
.user_optionsContainer__1_GSb {
	grid-auto-columns: 1fr;
}
.user_optionsContainer__1_GSb > *:first-child:nth-last-child(2) {
	grid-column: span 2;
}
.user_pendingContainer__1Bwh8 {
	grid-template-columns: 1fr auto;
}
.user_pendingContainer__1Bwh8 > div:first-child:last-child {
	grid-column: 1 / -1;
}
div.user_account__2Ssme {
	border-radius: 7px;
}
div.user_submit__2HRF7 {
    background-color: var(--col-accent1);
}
div.user_claim__3tI48 {
	cursor: default;
	padding: 10px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}
a.user_account__2Ssme {
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	justify-content: center;
	align-items: center;
	grid-auto-flow: column;
}
.user_account__2Ssme.user_inline__3OXtN > img.user_icon__1Gghq, .user_account__2Ssme.user_inline__3OXtN > svg.user_icon__1Gghq {
	height: 40px;
	width: 40px;
}
.user_account__2Ssme.user_inline__3OXtN, .user_account__2Ssme.user_inline__3OXtN:active {
	box-sizing: border-box;
	grid-template-columns: unset;
	border-radius: 15px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	cursor: pointer;
}
.user_account__2Ssme.user_inline__3OXtN.sent .user_account__2Ssme.user_inline__3OXtN.sent.user_active__2mhNB, .user_account__2Ssme.user_inline__3OXtN.sent:hover {
	cursor: default;
}
.user_account__2Ssme.user_inline__3OXtN > span, .user_facebookLink__2btxv, .user_facebookLink__2btxv > button {
	background-color: transparent;
	border: none;
	outline: none;
	font-family: Josefin Sans, sans-serif;
	font-size: 20px;
	font-weight: bold;
	color: unset;
	display: flex;
	align-items: center;
	align-self: center;
	cursor: unset;
}
div.user_accountButton__3oyQ8 {
	padding: 0 20px;
	border-radius: 15px;
	display: flex;
	cursor: pointer;
}
.user_accountButton__3oyQ8.user_remove__fI5h_ {
	background-color: var(--col-alert1);
}
.user_accountButton__3oyQ8:hover {
	opacity: 0.68;
}

.user_notes__2esV_ {
    border-radius: 5px;
    justify-content: center;
    align-items: center;
	display: flex;
    background-color: hsla(0,0%,58.8%,.68);
	font-weight: 700;
	font-size: small;
	box-sizing: border-box;
	align-self: baseline;
	padding: 10px 40px;
	height: 100%;
	
}
.user_sync__1JFkv {
	padding: 0 5px;
	box-sizing: border-box;
}

.user_auth__2yfjQ {
	margin: 20px auto;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	display: grid;
	grid-template-columns: auto minmax(250px, auto);
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	grid-row-gap: 20px;
	row-gap: 20px;
}
.user_auth__2yfjQ input {
	margin: unset;
	width: unset;
	border-radius: 10px;
	padding: 5px 10px;
}
.user_auth__2yfjQ > a.link.button, .user_auth__2yfjQ > div.link.button {
	border-radius: 10px;
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	min-height: 40px;
}
.user_auth__2yfjQ > div.link.button.user_null__3Qrr2 {
	background-color: grey!important;
	color: lightgrey;
	cursor: default;
}
.user_auth__2yfjQ > div.link.button.user_null__3Qrr2 > img {
	-webkit-filter: invert(0.8);
	        filter: invert(0.8);
}
.user_authMessage__390Sk {
	font-size: 12px;
}

/* Play */

.user_upcomingBattle__3pUGX {
	position: relative;
	margin-top: 40px;
}

.user_upcomingBattle__3pUGX > .user_stage__3ss_y {
	display: grid;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	grid-row-gap: 15px;
	row-gap: 15px;
	grid-template-columns: 28.8% 1fr 5ch 1fr 28.8%;
	grid-template-rows: 50px 1fr auto;
	grid-template-areas:
		'wFlag wFlag round bFlag bFlag'
		'wName wName vs bName bName'
		'Buttons Buttons Info Info Score'
		'Buttons Buttons Info Info Score';
}
.user_upcomingBattle__3pUGX > .user_stage__3ss_y.user_reversed__2kR16 {
	grid-template-areas:
		'wFlag wFlag round bFlag bFlag'
		'wName wName vs bName bName'
		'Score Score Info Info Buttons'
		'Score Score Info Info Buttons';
}

.user_upcomingBattle__3pUGX .user_flag__2UYEA {
	position: relative;
	top: -50px;
	display: flex;
	justify-content: center;
}

.user_upcomingBattle__3pUGX .user_name__3j1p- {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 24px;
	background-color: hsla(0,0%,58.8%,.68);
	border-radius: 20px;
	padding: 5px;
}
.user_upcomingBattle__3pUGX .user_vs__1kBgF {
	grid-area: vs;
	font-weight: 700;
	font-size: 30px;
	display: flex;
	padding: 0 20px;
	justify-content: center;
	align-items: center;
}
.user_upcomingBattle__3pUGX .user_round__3EHAB, .user_upcomingBattle__3pUGX .user_round__3EHAB {
	grid-area: round;
	position: relative;
	top: -10px;
	font-size: 42px;
	color: var(--col-light1);
	background-color: transparent;
}
.user_upcomingBattle__3pUGX .user_round__3EHAB:hover {
	color: var(--col-accent1);
	background-color: transparent;
}

.user_upcomingBattle__3pUGX .user_buttons__2RHQ3 {
	grid-area: Buttons;
	display: grid;
	grid-template-columns: repeat(3, auto);
    grid-auto-rows: auto;
    overflow: hidden;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    grid-row-gap: 15px;
    row-gap: 15px;
    font-size: 1em;
}

.user_buttons__2RHQ3 > .header-button {
	border-radius: 10px;
	padding: 3px 5px;
	grid-row: 2;
	align-items: center;
}
.user_upcomingBattle__3pUGX .user_buttons__2RHQ3 > .header-button:nth-child(2) {
	grid-column-start: 1;
}
.user_upcomingBattle__3pUGX .user_buttons__2RHQ3 > .header-button:last-child {
	grid-column-end: -1;
}
.user_upcomingBattle__3pUGX .user_buttons__2RHQ3 > .header-button > * {
	margin: 0 5px;
}

.user_upcomingBattle__3pUGX .user_buttons__2RHQ3 > .header-button.user_join__3BJv3 {
	grid-row: 1;
	grid-column: 1 / -1;
}
.user_upcomingBattle__3pUGX .user_buttons__2RHQ3 > .header-button.user_gameLink__3NIae {
	background-color: var(--col-accent2-hover);
	color: var(--col-accent4);
}

.user_upcomingBattle__3pUGX div.user_null__3Qrr2 {
	cursor: default;
	background-color: lightgrey;
	color: grey;
}

.user_upcomingBattle__3pUGX .user_infoBox__3E8hT {
	grid-area: Info;
	display: grid;
	grid-template-columns: 50fr 68.1fr;
	grid-auto-rows: auto;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	overflow: hidden;
	font-size: 16px;
}
.user_upcomingBattle__3pUGX .user_scoreBox__fcW-6 {
	grid-area: Score;
	white-space: pre-line;
}
.user_scoreBox__fcW-6 .user_warning__1z2z2 {
	color: grey;
}

.user_scoreBox__fcW-6 table thead tr th {
	padding: unset;
}
.user_scoreBox__fcW-6 > div {
	margin: 0;
	grid-template-columns: unset;
	font-size: small;
}

.user_upcomingBattle__3pUGX .user_infoBox__3E8hT, .user_upcomingBattle__3pUGX .user_scoreBox__fcW-6 {
	padding: 10px;
	background-color: rgb(150, 150, 150, 0.31);
	border-radius: 15px;
	align-items: center;
}

.user_upcomingBattle__3pUGX .user_clickButton__1FCsS {
	background-color: rgba(150, 150, 150, 0.4);
	display: flex;
	text-align: center;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    border-radius: 15px;
    background-color: rgba(150, 150, 150, 0.68);
    text-decoration: none;
    padding: 2px;
}

.user_darkMode__71Cw8 {
	padding: 10px;
	display: flex;
	align-items: center;
}
.user_darkMode__71Cw8 svg {
	font-size: 32px;
}

.user_merge__o00Ut, .user_teamMerge__3sVxu {
	border-radius: 10px;
	padding: 5px;
}
.user_merge__o00Ut:hover, .user_teamMerge__3sVxu:hover {
	background-color: var(--col-accent2-hover);
	color: var(--col-accent1-hover);
}
.user_merge__o00Ut {
	grid-column: 1 / -1;
}
.user_teamMerge__3sVxu {
	font-weight: 700;
}


/* Tournament Info */
.tournamentinfo_tournament_info__3OozG {
	height: 100%;
	grid-area: info;

	display: grid;
	grid-template-rows: 1fr auto;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	padding: 10px;
	box-sizing: border-box;
	background-color: var(--container-bg);
	border-radius: 15px;
}

.tournamentinfo_tournament_info__3OozG > div {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-auto-rows: 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-row-gap: 5px;
	row-gap: 5px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

@media only screen and (max-width: 700px) {
	.tournamentinfo_tournament_info__3OozG {
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		font-size: unset;
	}
	.tournamentinfo_tournament_info__3OozG > div {
		grid-template-rows: unset;
	}
}

.tournamentinfo_tournament_info__3OozG > div > div {
	padding: 5px;
}

.tournamentinfo_tieBreakLink__30hG_ {
	text-decoration: underline;
	cursor: pointer;
	grid-column: 1 / span 2;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.68);
	justify-content: center;
	text-decoration: none;
	margin-bottom: 5px;
	padding: 2px;
}

.tournamentinfo_tieBreakLink__30hG_:hover {
	background-color: rgba(150, 150, 150, 0.4);
}
.registered_header__1Gp9E {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	margin: 5px 0px;
}

.registered_table__yBsRH {
	width: 100%;
	margin: unset;
}

.registered_table__yBsRH .playerName.left {
	width: 100%;
	justify-content: flex-start;
}

.registered_cell__2gBeU, a.playerName {
	font-family: Arial, Helvetica, sans-serif;
}
th.registered_headerCell__3zk9f {
	display: none;
}

.registered_flag__3SAHb {
	position: relative;
	width: 2.5em;
	height: 2.5em;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	text-align: center;
}

/* TODO: Centre this flag */
.registered_flag__3SAHb > * {
	display: block;
	padding-left: 9px;
	padding-top: 4px;
}

.registered_tr__2fs11 {
	height: 30px;
}

.registered_stickyheader__1HWiu {
	z-index: 2;
}

.registered_showAllButton__1DD0h {
	border-radius: 50%;
	border: none;
	
	position: absolute;
	bottom: -1em;
	height: 2em;
	width: 2em;
	outline: none;
	cursor: pointer;
}

@media only screen and (max-width: 700px) {
	th.registered_headerCell__3zk9f {
		display: table-cell;
	}
	th.registered_headerCell__3zk9f > div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: black;
	}
	table > tbody > tr.registered_tr__2fs11 {
		display: grid;
		grid-template-columns: 50% 50%;
		background-color: #f0f0f0;
	}
	table td {
		text-align: center;
	}
	tr.registered_tr__2fs11 > th:nth-child(4n), tr.registered_tr__2fs11 > th:nth-child(4n-1), tr.registered_tr__2fs11 > td:nth-child(4n) {
		background-color: lightblue;
	}
}

#registered_rowLast__3NF-- > td {
	padding-bottom: 15px;
}
.featured_featured__zs_iR {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.featured_headerItems__2EPoN {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 20px;
	font-weight: bold;
	text-align: center;

	display: flex;
	align-items: flex-end;
	flex-direction: row;

	display: grid;
	grid-column: 1 / span 2;
	width: 100%;
}

.featured_headingContainer__5ud5j {
	padding: 5px;
	margin-bottom: 5px;
}

.featured_table__alHoJ {
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.featured_featured_table__3YWrg tbody::before {
	line-height: 0.5em !important;
}

.featured_table__alHoJ .playerName.left {
	width: 100%;
	justify-content: flex-start;
}

.featured_cell__2ru_7, a.playerName {
	font-family: Arial, Helvetica, sans-serif;
}
th.featured_headerCell__3_aGP {
	display: none;
}

.featured_flag__3DtES {
	position: relative;
	width: 2.5em;
	height: 2.5em;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	text-align: center;
}

/* TODO: Centre this flag */
.featured_flag__3DtES > * {
	display: block;
	padding-left: 9px;
	padding-top: 4px;
}

.featured_tr__20MXQ {
	height: 30px;
}
.featured_showAllButton__cv3-K {
	border-radius: 50%;
	border: none;
	
	position: absolute;
	height: 2em;
	width: 2em;
	outline: none;
	cursor: pointer;

	bottom: -1em;
}

@media only screen and (max-width: 700px) {
	th.featured_headerCell__3_aGP {
		display: table-cell;
	}
	th.featured_headerCell__3_aGP > div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: black;
	}
	table > tbody > tr.featured_tr__20MXQ {
		display: grid;
		grid-template-columns: 50% 50%;
		background-color: #f0f0f0;
	}
	table td {
		text-align: center;
	}
	tr.featured_tr__20MXQ > th:nth-child(4n), tr.featured_tr__20MXQ > th:nth-child(4n-1), tr.featured_tr__20MXQ > td:nth-child(4n) {
		background-color: lightblue;
	}
}

.featured_menuItems__1p2uG.featured_selectedItem__DBU8G {
	background-color: cyan;
}

a.featured_menuItems__1p2uG.featured_nullRound__2RKp0, a.featured_pairingRound__2YCgO.featured_nullRound__2RKp0:hover > * {
	background-color: lightgrey;
	color: grey;
	cursor: default;
}
/* Main container */
.dashboard_container__2J23A {
    display: grid;
	grid-template-areas: 
		'left-column right-column';
	grid-template-rows: minmax(385px, auto);
	grid-template-columns: 5fr 3fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-row-gap: 10px;
	row-gap: 10px;
	font-family: 'Josefin Sans', sans-serif;
}

.dashboard_left_column__2yqzh {
	grid-area: left-column;
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: 1fr;
	grid-row-gap: 10px;
	row-gap: 10px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

/* Subcontainer for tournament and organiser info */
.dashboard_info_organiser_container__329_7 {
	display: grid;
	grid-template-areas: 'info organiser';
	grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
	width: 100%;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-row-gap: 10px;
	row-gap: 10px;
	align-items: stretch;
	justify-content: stretch;
	
}

/* Description */

.dashboard_description__2f7Ep {
	background-color: var(--container-bg);
}

/* Organiser */

div.dashboard_organiser_container__1bD29 {
	margin: 0px;
	box-shadow: none;
	max-width: unset;
	justify-self: stretch;
	width: 100%;
	box-sizing: border-box;
}

/* Tiebreak and Prizes Buttons */

section.dashboard_tieBreakSection__1E9RP {
	margin-bottom: 0px !important;
	margin-top: 0px !important;
}

.dashboard_tieBreakContainer__1UUuR, .dashboard_tieBreakContainer__1UUuR:hover {
	display: flex;
	background-color: var(--container-bg);
	transition: left 0.5s ease;
	justify-content: center;
	z-index: 3;
}

.dashboard_tieBreakContainer__1UUuR.dashboard_visible__ZUPCq {
	left: calc((100vw - 1280px) / 2 - 10px)
}

@media only screen and (max-width: 1080px) {
	.dashboard_tieBreakContainer__1UUuR.dashboard_visible__ZUPCq {
		left: 20px;
	}
}

@media only screen and (max-width: 700px) {
	.dashboard_tieBreakContainer__1UUuR, .dashboard_tieBreakContainer__1UUuR:hover {
		position: static;
		max-width: unset;
	}
	.dashboard_tieBreakContainer__1UUuR:not(.dashboard_visible__ZUPCq) {
		display: none;
	}
	.dashboard_tieBreakContainer__1UUuR.dashboard_visible__ZUPCq {
		display: flex;
	}
}

.dashboard_tieBreakContainer__1UUuR > div {
	width: 100%;
}

.dashboard_tieBreakTitle__2Bgc7 {
	padding: 5px;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.6);
	display: flex;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
}

.dashboard_tieBreakTitle__2Bgc7:hover {
	background-color: rgba(65, 65, 65, 0.6);
}

.dashboard_tieBreaks__3QIuR {
	display: grid;
	grid-template-columns: auto 1fr;
	font-size: smaller;
	margin: 10px 0;
}

.dashboard_tieBreaks__3QIuR > div {
	padding: 2px 5px;
}

/* Featured/Upcoming games */
.dashboard_featured__1uxAe {
	margin-top: 0px;
	max-height: 600px;
}

.dashboard_live__9fbmX {
	grid-column: 1;
}

.dashboard_teams_announcements__1_Etj {
	grid-area: right-column;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.dashboard_header__3PhQ6 {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	margin: 5px 0px;
}

.dashboard_teams_list__1OrFn {
	height: auto;
	box-sizing: border-box;
	position: relative;
	display: flex;
	justify-content: center;
}

.dashboard_announcements__Ye1Fj {
	height: auto;
	max-height: 500px;
	overflow: auto;
}
/* Depth: 0 */
.rotating_fixed__1mKEH, div.rotating_backContainer__PRAiB {
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100vw;
	z-index: 0;
	-webkit-animation: rotating_fade-in__2DxCb ease 3s;
	        animation: rotating_fade-in__2DxCb ease 3s;
	grid-area: unset;

}

.rotating_parent__2hVEL, .rotating_background__1qBGX, .rotating_logoBackground__Sunzn {
	width: 100%;
	height: 100vh;
}
.rotating_background__1qBGX {
	opacity: 0.1;
}
.rotating_background__1qBGX.rotating_faded__pUsO6 {
	opacity: 0.2;
	-webkit-filter: saturate(2);
	        filter: saturate(2);
}
.rotating_background__1qBGX.rotating_lighter__1PJ2J {
	-webkit-filter: brightness(2);
	        filter: brightness(2);
}
.rotating_logoBackground__Sunzn {
	background-repeat: no-repeat;
	background-position: 68% 68%;
	background-size: 50vh;
	opacity: 0.3;
	display: flex;
	justify-content: center;
	align-items: center;
}
#root.dark .rotating_logoBackground__Sunzn {
	background-color: white;
	-webkit-filter: brightness(0.3);
	        filter: brightness(0.3);
}
.rotating_logoBackground__Sunzn img {
	mix-blend-mode: multiply;
	position: relative;
	left: 12vw;
	height: 50vh;
}
@-webkit-keyframes rotating_fade-in__2DxCb {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.2;
	}
}
@keyframes rotating_fade-in__2DxCb {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.2;
	}
}


/* Depth: 1 */

.rotating_rotator__1DVLb {
	grid-area: gallery;
	display: flex;
	width: 100%;
	position: relative;
	margin-bottom: 20px;
	justify-items: center;
}

/* Depth: 2 */

.rotating_stage__2scv6 {
    position: relative;
	left: -100vw;
    transition: left 0.5s;
	display: grid;
	grid-auto-flow: column;
    grid-auto-columns: 100vw;
}

.rotating_selection__2iSsC {
	position: absolute;
    display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	bottom: -10px;
	z-index: 2;
}

/* Depth: 3 */

.rotating_child__1UA2D {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

/* Depth: 4 */

.rotating_curr__2YxLv {
	display: grid;
	grid-template-areas:
		'description logo'
		'title logo';
	grid-template-columns: 1fr 20vw;
	padding: 0px 10vw 0px 10vw;
	grid-column-gap: 10vw;
	-webkit-column-gap: 10vw;
	        column-gap: 10vw;
	height: inherit;
	position: relative;
	-webkit-animation: rotating_from-right__1DmXW 1s ease;
	        animation: rotating_from-right__1DmXW 1s ease;
}

@-webkit-keyframes rotating_from-right__1DmXW {
	from {
		opacity: 0;
		right: -50%;
	}
	to {
		opacity: 1;
		right: 0px;
	}
}

@keyframes rotating_from-right__1DmXW {
	from {
		opacity: 0;
		right: -50%;
	}
	to {
		opacity: 1;
		right: 0px;
	}
}

/* Depth 3 */

.rotating_description__2VU0a {
	grid-area: description;
	display: flex;
	font-style: italic;
	font-weight: 300;
	font-size: large;
	text-align: center;
	justify-content: center;
	align-items: center;
	white-space: pre-wrap;
	padding: 5px;
}

.rotating_title__1mJSD {
	grid-area: title;
	height: 100%;
	width: 100%;
	font-size: xx-large;
	font-weight: 800;
	z-index: 1;
	display: flex;
	position: relative;
	justify-content: center;
	text-shadow: 0px 0.5px rgba(0, 0, 0, 0.3);
}

.rotating_palette__1UniL {
	position: absolute;
	bottom: 0px;
	left: 0px;
	display: flex;
	flex-direction: row;
	visibility: hidden;
}
.rotating_box__RNGV_ {
	width: 20px;
	height: 20px;
}

a.rotating_new__2i9lb {
	position: absolute;
	left: 55vw;
	bottom: -15px;
	border-radius: 15px;
	text-align: center;
	padding: 5px 15px;
	display: flex;
	align-items: center;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	background-color: var(--col-accent2);
	font-size: small;
	border: #e3e3e3 5px solid;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
	z-index: 2;
}
a.rotating_new__2i9lb svg {
	padding: unset;
}

/* Depth: 6 */
a.rotating_frame__LIoJX {
	grid-area: logo;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	width: 150px;
	height: 150px;
	position: relative;
	overflow: hidden;
	justify-self: center;
	border: #e3e3e3 5px solid;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
}
.rotating_logo__2NJ_4 {
	display: flex;
	position: relative;
	align-self: flex-end;
}

/* Text Gallery */

.rotating_dot__25TPv {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
    border-radius: 50%;
    display: inline-block;
	margin: 0 10px;
	cursor: pointer;
}

.rotating_dotSelected__1f7uq {
    background-color: #002147;
}
.rules_columns__TQ3pt {
	display: grid;
	grid-template-columns: 68.1fr 31.8fr;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
}

/* Depth: 2 */

.rules_controls__2PIup {
	display: grid;
	grid-row-gap: 5px;
	row-gap: 5px;
	justify-content: stretch;
	width: 100%;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.rules_arrows__3i4Ul {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

/* Depth: 3 */

.rules_title__nnkUS {
	display: flex;
}

.rules_arrow__2Jtq1 {
	border: grey 5px solid;
	background-color: var(--col-accent2);
	border-radius: 50%;
	width: 1.5em;
	height: 1.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.rules_link__1ontN input {
	width: 100%;
	padding: 5px;
	-webkit-user-select: all;
	   -moz-user-select: all;
	    -ms-user-select: all;
	        user-select: all;
}

.home_container__3m-V0 {
	max-width: min(100vw, 1180px);
	width: inherit;
	margin: 0 20px;
	box-sizing: border-box;
	font-family: 'Josefin Sans', sans-serif;
	align-self: center;
	display: grid;
	grid-column-gap: 25px;
	-webkit-column-gap: 25px;
	        column-gap: 25px;
	grid-row-gap: 25px;
	row-gap: 25px;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'gallery gallery'
		'col1 col2'
		'online new'
}

.home_section__J4Pas {
	display: grid;
	overflow: hidden;
}

@media only screen and (max-width: 700px) {
	div.home_container__3m-V0 {
		grid-template-columns: 1fr;
		grid-template-areas:
			'gallery'
			'col1'
			'col2'
			'online'
			'new';
	}
	div.home_section__J4Pas {
		grid-column: unset;
		grid-row: unset;
	}
	div.home_title__2zCUD {
		grid-column: unset;
	}
}

.home_title__2zCUD {
	margin: 0px 5px;
}
.home_title__2zCUD h1 {
	margin: 20px 0;
}
.home_title__2zCUD small {
	font-size: small;
	margin: 0 10px;
}
.home_title__2zCUD h3 {
	margin: 10px 0;
}

.home_wrapper__1kdtX {
	display: grid;
	grid-template-columns: auto auto;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	align-items: baseline;
}

.home_col1__1cCiq {
	grid-area: col1;
	grid-template-rows: auto 1fr;
}
.home_col2__1BVR0 {
	grid-area: col2;	
	grid-template-rows: auto 1fr;
}
.home_col1__1cCiq, .home_col2__1BVR0 {
	display: grid;
	grid-row-gap: 25px;
	row-gap: 25px;
	width: 100%;
	max-width: 98vw;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.home_announcements__4cufj, .home_tournaments__1q8_O {
	width: 100%;
	display: flex;
    flex: 0 1 auto;
	flex-shrink: 0;
	box-sizing: border-box;
	margin: 0px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.home_tableAnnounce__dGzDK {
	width: 100%;
	overflow: hidden;
	border-radius: 15px;
	margin-bottom: 0px;
	table-layout: fixed;
}
.home_tableAnnounce__dGzDK tbody tr:not(:last-child) {
	border-bottom: 2px white solid;
}

@media only screen and (max-width: 700px) {
	.home_container__3m-V0 {
		grid-template-columns: 1fr;
	}
	.home_section__J4Pas > table {
		margin-bottom: 0px;
	}
	.home_section__J4Pas > table thead {
		display: table-header-group;
	}
	.home_section__J4Pas > table th {
		width: 100%;
		display: flex;
	}
	.home_section__J4Pas > table td {
		width: 100%;
		display: flex;
		text-align: center;
	}
}

.home_yourTournaments__1aqpu {
	grid-column: 1 / span 2;
	grid-row: 3 / span 1;
	overflow: hidden;
}

.home_sectionHeader__1zVNc {
	background-color: rgba(135, 207, 235, 0.5);
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	border-radius: 15px;
	overflow: hidden;
}
.home_sectionHeader__1zVNc.home_live__2gArQ {
	background-color: rgb(73, 73, 255, 0.3);
}
.home_sizer__3Z1C7 > th:first-child {
	width: 40%
}
.home_sizer__3Z1C7 > th:last-child {
	width: 5%
}
.home_tournaments__1q8_O tr > *:first-child {
	overflow: hidden;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.home_tournaments__1q8_O tr > *:last-child {
	overflow: hidden;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.home_tournaments__1q8_O tbody tr:hover > th, .home_tournaments__1q8_O tbody tr:hover > td, .home_tournaments__1q8_O tbody tr:hover a {
	background-color: var(--col-accent1);
	color: white;
}

.home_notificationRow__1q_nU {
	overflow-x: hidden;
	white-space: nowrap;
	width: 100%;
}
.home_notificationRow__1q_nU::-webkit-scrollbar, .home_notificationRow__1q_nU > td::-webkit-scrollbar {
	display: none;
}
.home_notificationRow__1q_nU > td {
	display: inline-block;
	width: 100%;
}

.home_notificationRow__1q_nU > td > div {
	display: grid;
	grid-template-columns: 5% auto 1fr 5%;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	align-items: center;
	overflow: hidden;
	border-radius: 15px;
	padding: 0 5px;
}

.home_notificationRow__1q_nU > td > div > div, .home_notificationRow__1q_nU > td > div > a {
	display: flex;
    flex: 0 1 auto;
	flex-shrink: 0;
	width: 100%;
	overflow: hidden;
	align-items: center;
}

.home_expando__2-dbm {
	cursor: pointer;
}

.download-table > tbody .home_rowLink__1N7Sr:hover {
	background-color: var(--col-accent1);
	color: white;
}

.download-table > tbody .home_rowLink__1N7Sr:hover > * > * {
	color: white;
}

:not(#profileTournaments).home_tournamentLink__1SOA6 {
	width: 100%;
	display: grid;
	grid-template-columns: auto 5%;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
}

.home_notificationRow__1q_nU > td > div:hover, .home_notificationRow__1q_nU > td > div:hover > a.home_iconLink__1RN-Z {
    color: #fff;
	background-color: var(--col-accent1);
	cursor: pointer;
}

.home_connected__3l58F, .home_disconnected__3n6S0 {
	display: flex;
	align-items: center;
	font-size: small;
}

.home_connected__3l58F > svg {
	color: var(--col-accent1);
	height: 1em;
	padding-right: 10px;
}

.home_disconnected__3n6S0 {
	color: var(--col-alert1);
	height: 1em;
	padding: 0 5px;
}
.home_container__3m-V0 .home_logout__12JDk:hover {
	color: white;
}

.home_info__13MIt {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-areas:
		'Name . Buttons'
		'Details . Buttons'
}

.home_buttons__3u2f7 {
	grid-area: Buttons;
	display: grid;
	grid-auto-rows: 2em;
	grid-auto-flow: row;
	grid-row-gap: 5px;
	row-gap: 5px;
	transform: scaleY(-1);
	justify-content: right;
}
.home_buttons__3u2f7 > .home_row__Wfflj {
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	transform: scaleY(-1);
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	justify-self: right;
}
.home_buttons__3u2f7 > .home_row__Wfflj > div, .home_buttons__3u2f7 > .home_row__Wfflj > a {
	padding: 0 20px;
	border-radius: 10px;
}
.home_details___qRnM {
	grid-area: Details
}

a.home_account__15sn1 {
	display: grid;
	grid-template-columns: minmax(auto, 1fr) auto minmax(auto, 1fr);
	justify-content: center;
	align-items: center;
	grid-auto-flow: column;
}

.home_sync__175YB {
	padding: 0 5px;
	box-sizing: border-box;
}
.home_row__Wfflj > span > button.kep-login-facebook {
	height: 100%;
	padding: 0 20px;
	border-radius: 10px;
	font-family: Josefin Sans, sans-serif;
}

.home_container__3m-V0 .home_new__2kEVt {
	border-radius: 10px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	text-align: right;
	width: minmax(-webkit-max-content, 100%);
	width: minmax(-moz-max-content, 100%);
	width: minmax(max-content, 100%);
	padding: 5px;
	background-color: var(--col-accent2);
}
.reddit-poll, .reddit-poll * {
	cursor: pointer;
	font-family: Helvetica, sans-serif;
	font-weight: bold;
	font-size: small;
}

.reddit-poll {
	padding: 0 10px;
	margin: 2px;
	border: 1px solid #c5c7cf;
	pointer-events: all;
}
.reddit-poll * {
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.reddit-poll label {
	display: grid;
	grid-template-columns: 2em auto;
	position: relative;
	margin: 0.2em 0;
}

.reddit-poll input {
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	z-index: -1;
	position: relative;
}
.reddit-poll input:after {
	top: 0px;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background: tranparent;
	content: '';
	display: block;
	z-index: 1;
}

.reddit-card {
	color: white;
}
.community_container__2qOrW {
    max-width: 1180px;
	margin: 0 20px 50px;
	box-sizing: border-box;
	overflow: hidden;

	display: grid;
	grid-column-gap: 25px;
	-webkit-column-gap: 25px;
	        column-gap: 25px;
	grid-row-gap: 25px;
	row-gap: 25px;
	grid-template-columns: 1fr 1fr;
	font-family: 'Josefin Sans', sans-serif;
}

.community_userTable__NudmB {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
}
.community_status__3r8a4 {
	color: #43853d;
	display: flex;
	justify-content: center;
}
.community_status__3r8a4:not(.community_online__31t3x) {
	color: darkgrey;
}

a.community_profile__3bNmQ {
	border-radius: 15px;
	padding: 0 10px;
}

.community_title__26thm {
	font-size: xx-large;
	margin: 20px 0;
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	align-items: center;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}
.light {
	font-weight: 300;
}
.game_stage__2w45h {
	display: grid;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
	grid-template-columns: 68.1fr 31.9fr;
	grid-template-rows: 50px 1fr;
	grid-template-areas:
		'info next'
		'viewer next';
	height: 100vh;
	margin-bottom: 100px;
}

.game_viewer__3D_Va {
	grid-area: viewer;
}
.game_rightCol__2HW0P {
	grid-area: next;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-row-gap: 10px;
	row-gap: 10px;
}

div.game_more__1LAp- {
	display: grid;
	grid-row-gap: 15px;
	row-gap: 15px;
	height: 100%;
	border-radius: 15px;
	overflow-y: hidden;
	position: relative;
}

div.game_scroll__3k70v {
	border-radius: 10px;
}
div.game_info__1PzxE {
	grid-area: info;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	font-family: Josefin Sans, sans-serif;
}

.game_viewer__3D_Va .pgnViewerMain {
	height: 490px;
}
div.game_more__1LAp- > div {
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	cursor: pointer;
}

.game_more__1LAp- .pgnViewerMoveList, .game_more__1LAp- .pgnViewerFooter  {
	display: none;
	visibility: hidden;
}
.game_more__1LAp- .pgnViewerMain {
	grid-template-columns: unset;
}
.teams_container__2UodT {
    max-width: 1180px;
	margin: 0 20px 50px;
	box-sizing: border-box;

	display: grid;
	grid-column-gap: 25px;
	-webkit-column-gap: 25px;
	        column-gap: 25px;
	grid-row-gap: 25px;
	row-gap: 25px;
	font-family: 'Josefin Sans', sans-serif;
}

/* Depth: 2 */

.teams_userTable__1ewml {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	justify-content: center;
	align-items: center;
}
@media only screen and (max-width: 1200px) { .teams_userTable__1ewml { grid-template-columns: repeat(3, 1fr) }}
@media only screen and (max-width: 900px) { .teams_userTable__1ewml { grid-template-columns: repeat(2, 1fr) }}
@media only screen and (max-width: 600px) { .teams_userTable__1ewml { grid-template-columns: repeat(1, 1fr) }}

/* Depth: 3 */

.teams_compactContainer__1FsXq {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 5vw;
	-webkit-column-gap: 5vw;
	        column-gap: 5vw;
	position: relative;
}
.teams_compact__JlUva {
	display: grid;
	grid-row-gap: 5px;
	row-gap: 5px;
}

.teams_preview__3baBK {
	position: fixed;
	left: 55%;
	z-index: 2;
}
@-webkit-keyframes teams_scroll-up__ydb2Z {
	0% {
		top: 18vh
	}
	80% {
		top: 7vh
	}
	100% {
		top: unset;
		bottom: 1vh;
	}
}
@keyframes teams_scroll-up__ydb2Z {
	0% {
		top: 18vh
	}
	80% {
		top: 7vh
	}
	100% {
		top: unset;
		bottom: 1vh;
	}
}

/* Depth 3 */

.teams_title__ZBgAW {
	margin: 10px 0;
	font-size: xx-large;
}
.teams_title__ZBgAW, .teams_title__ZBgAW .teams_menu__3GQxR {
	display: grid;
	grid-template-columns: auto auto 1fr auto;
	align-items: center;
	justify-items: center;
	grid-auto-flow: column;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	align-items: center;
}
.teams_active__3EYBs {
	opacity: 0.3;
	cursor: pointer;
}
.teams_inactive__2C24V {
	color: #43853d;
}

.teams_menu__3GQxR label {
	font-size: small;
}
.light {
	font-weight: 300;
}

/* Search Bar */
.teams_searchBar__2Xvbs {
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent3);
	align-items: center;
	justify-content: stretch;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	padding: 5px 10px;
}

.teams_searchBarDiv__39JPn {
	display: grid;
	grid-template-columns: minmax(auto, 400px);
}

.teams_searchBar__2Xvbs > * {
	height: 100%;
	display: flex;
	align-items: center;
}

.teams_searchBar__2Xvbs * {
	background-color: transparent;
}

.teams_searchBar__2Xvbs form {
	padding: 2px 0;
}

.teams_searchBar__2Xvbs div, .teams_searchBar__2Xvbs form, .teams_searchBar__2Xvbs input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.teams_searchBar__2Xvbs input, .teams_searchBar__2Xvbs input:focus {
	border: none;
	margin: 5px 0;
}

.teams_searchIcon__7VoOb {
	font-size: medium;
}

.playerline_matchUp__Jb9ni {
	display: grid;
	grid-row-gap: 5px;
	row-gap: 5px;
	grid-template-rows: 1fr;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	background-color: rgb(150, 150, 150, 0.31);
	border-radius: 15px;
	padding: 5px;
	position: relative;
}

/* Depth: 5 */
.playerline_white__2Dg_3, .playerline_black__yf2p1 {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	align-items: center;
	background-color: rgb(200, 200, 200, 0.70);
	border-radius: 15px;
	padding-left: 8px;
	box-sizing: border-box;
	position: relative;
}
.playerline_white__2Dg_3 svg, .playerline_black__yf2p1 svg {
	font-size: x-small;
}

/* Depth: 2.0 */

.playerline_flag__1Wzhr {
	position: relative;
	height: 1em;
	display: flex;
	justify-content: center;
	border-radius: 0.25em;
}
.playerline_flag__1Wzhr > img {
	width: unset;
	max-width: unset;
	height: 1em;
	height: 100%;
	border-radius: 0.25em;
}

/* Depth: 6 */
div.playerline_playerInfo__2wd6f {
	text-align: left;
	align-items: center;
	box-sizing: border-box;
	padding-right: 45px;
	white-space: nowrap;
	padding: 0 8px;
	width: 100%;
	border-radius: 0.5em;
	cursor: pointer;
}
div.playerline_playerInfo__2wd6f:hover {
	background-color: unset;
	color: white;
}
.tournaments_tableAnnounce__2MLQm {
	width: 100%;
}

.download-table > tbody .tournaments_rowLink__1OayQ:hover {
	background-color: #43853d;
	color: white;
}

.download-table > tbody .tournaments_rowLink__1OayQ:hover > * > * {
	color: white;
}

:not(#profileTournaments).tournaments_tournamentLink__3mGz7 {
	width: 100%;
	display: grid;
	grid-template-columns: auto 5%;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
}
.profile_container__1K7NH {
    max-width: 1180px;
	margin: 0 20px 50px;
	box-sizing: border-box;
	overflow: hidden;

	display: grid;
	grid-column-gap: 25px;
	-webkit-column-gap: 25px;
	        column-gap: 25px;
	grid-row-gap: 25px;
	row-gap: 25px;
	grid-template-columns: 1fr 1fr;
	font-family: 'Josefin Sans', sans-serif;
}
.profile_title__2BZxm {
	grid-column: 1 / span 2;
	grid-row: 1 / span 1;
	padding: 10px;
	margin-top: 60px;
}
.profile_title__2BZxm h1 {
	margin: 20px 0;
}
.profile_title__2BZxm h3 {
	margin: 0;
}

.profile_grid__3AVqG {
	display: grid;
	grid-row-gap: 5px;
	row-gap: 5px;
}

.profile_details__T2CsB {
	grid-area: Details;
	display: flex;
	flex-direction: column;
}
.profile_details__T2CsB > * {
	padding: 10px 0;
}
.profile_flag__3RCpw {
	display: flex;
	height: 50px;
	align-items: center;
}
.profile_flag__3RCpw > img {
	width: unset;
	max-width: unset;
	height: 100%;
	border-radius: 5px;
	margin-right: 20px;
}

/* Contact details */

.profile_info__3FtOw {
	display: grid;
	grid-template-rows: 1fr auto;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        'Name . Buttons'
        'Details . Buttons';
}

.profile_contact__3SQSq {
	grid-area: Buttons;
	display: grid;
	grid-auto-rows: 2em;
	grid-row-gap: 10px;
	row-gap: 10px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	justify-content: right;
	align-items: center;
	text-align: center;
	transform: scaleY(-1);
	direction: rtl;
}
.profile_contact__3SQSq > * {
	transform: scaleY(-1);
	direction: ltr;
}
.profile_contact__3SQSq h1 {
	margin: 0;
	text-align: left;
}
.profile_notes__1GtWG {
    border-radius: 5px;
    justify-content: center;
    align-items: center;
	display: flex;
    background-color: hsla(0,0%,58.8%,.68);
	font-weight: 700;
	font-size: small;
	box-sizing: border-box;
	grid-row: span 2;
	align-self: baseline;
	width: 68.1%;
	padding: 10px 40px;
}

/* Tournaments */

.profile_background__2pk8i {
	grid-column: 1 / span 2;
	width: unset;
	overflow: hidden;
}

.profile_stage__2kP0B {
	position: relative;
	display: grid;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	grid-row-gap: 20px;
	row-gap: 20px;
	grid-template-columns: 1fr 21.3% 30.8%;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		'name name history'
		'scores midButtons history'
		'action action history'
}
.profile_teamStage__2cDka {
	position: relative;
	display: grid;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	grid-row-gap: 20px;
	row-gap: 20px;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'name name'
		'action action'
}
.profile_midButtons__3oJbm {
	display: grid;
	grid-row-gap: 20px;
	row-gap: 20px;
}

a.profile_box__2QL9b, div.profile_box__2QL9b {
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 7px;
}
div.profile_box__2QL9b {
	cursor: default;
}

a.profile_name__bRkcf{
	display: flex;
    background-color: hsla(0,0%,58.8%,.68);
    font-weight: 700;
	grid-area: name;
	font-size: 24px;
	color: black;
}

div.profile_history__22CVZ {
	grid-area: history;
	display: grid;
	grid-auto-rows: 1fr;
	grid-template-columns: 100%;
	background-color: hsla(0,0%,58.8%,.31);
	align-items: start;
	grid-row-gap: 5px;
	row-gap: 5px;
}
.profile_resultsRow__4zleX {
	display: grid;
	grid-template-columns: 2ch auto repeat(3, 2ch);
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	padding: 5px;
	box-sizing: border-box;
	background-color: darkgrey;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	font-size: 90%;
}
.profile_resultsRow__4zleX.profile_small__10h6z {
	grid-template-columns: 2ch auto repeat(2, 2ch);
}
.profile_resultsRow__4zleX > * {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 5px;
	border-radius: 5px;
}
.profile_resultsRow__4zleX:hover > a.profile_fieldBox__1gEdr:not(:empty) {
    color: #fff;
    background-color: #43853d
}
.profile_subtitle__2fxvS {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	text-align: right;
	font-weight: 700;
	font-size: 22px;
}

.profile_scores__ByD8u {
	grid-area: scores;
	background-color: hsla(0,0%,58.8%,.31);
	display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    font-size: 18px;
}
.profile_row__aUfgI {
	display: grid;
	grid-template-columns: [key] 1fr [value] auto;
}
.profile_key__mRxor {
	grid-column: key;
	padding: 0 10px;
	font-weight: 300;
}
.profile_value__vcPiH {
	grid-column: value;
	padding: 0 10px;
}

.profile_action__2ARI5 {
	grid-area: action;
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}
.profile_action__2ARI5 > a.header-button, .profile_action__2ARI5 > div.header-button {
	border-radius: 10px;
	display: flex;
	align-items: center;
	padding: 3px 5px;
}
.profile_action__2ARI5 > div.header-button {
	cursor: default;
}
.profile_action__2ARI5 > .header-button > * {
	margin: 0 10px;
}

/* Teams */

.profile_inactive__2tSAV {
	opacity: 0.68;
}

/* Flags */

.profile_flag__3RCpw {
	position: relative;
	top: -35px;
	height: 80px;
	display: flex;
	justify-content: center;
	border-radius: 10px;
}
.profile_small__10h6z .profile_flag__3RCpw {
	height: 50px;
}
.profile_flag__3RCpw > img {
	width: unset;
	max-width: unset;
	height: 100%;
	border-radius: 10px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.members_columns__2lClQ {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}

.members_userTable__14M2i {
	display: grid;
	grid-row-gap: 5px;
	row-gap: 5px;
}

a.members_row__2J83a {
	display: grid;
	grid-template-columns: 1em 1fr;
	border-radius: 5px;
	background-color: var(--col-accent2-hover);
	color: white;
}

.members_status__2jTkv {
	color: #43853d;
	display: flex;
	justify-content: center;
	align-items: center;
}
.members_status__2jTkv:not(.members_online__Wd29_) {
	color: darkgrey;
}

div.members_profile__15QHc {
	padding: 0 10px;
}

.members_searchBar__BWiTL {
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent2);
	align-items: center;
	align-content: center;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	margin-bottom: 20px;
}

.members_searchBar__BWiTL > * {
	height: 100%;
	padding: 0 5px;
}

.members_searchBar__BWiTL * {
	background-color: transparent;
}

.members_searchBar__BWiTL form {
	padding: 2px 0;
}

.members_searchBar__BWiTL div, .members_searchBar__BWiTL form, .members_searchBar__BWiTL input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.members_searchBar__BWiTL input, .members_searchBar__BWiTL input:focus {
	border: none;
	padding: 0;
	margin: 0;
}

.header_header__3AnVH {
    top: 0px;
    width: 100%;
    height: 9vh;
    z-index: 3; /* On account of react-carousel */
	background-color: rgba(255, 255, 255, 0.0);
	display: grid;
	position: fixed;
    grid-template-columns: 15vh 1fr;
	align-items: start;
	font-family: Roboto, "Source Sans Pro", "Open Sans", "San Francisco", Helvetica, Arial, sans-serif;
	transition: height 0.68s ease, box-shadow 0.68s ease, background-color 0.68s ease 0.31s;
}
.header_header__3AnVH.header_small__1FEFj {
	background-color: rgb(217, 244, 255);
	height: 6vh;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
}

.header_buttonContainer__2NGSo {
    display: grid;
    grid-template-columns: 1.5fr repeat(3, 1fr) repeat(3, auto) 2fr;
    grid-auto-flow: column;
	grid-area: 'name link link link link link';
	align-items: center;
	height: inherit;
}

.header_buttonContainer__2NGSo .header_button__3Dvcf:first-child {
	padding: 5px;
}

@media only screen and (max-width: 700px) {
	.header_header__3AnVH {
		grid-template-columns: 100%;
	}
	.header_buttonContainer__2NGSo, div.header_header__3AnVH .header_nameWrapper__3xkIw {
		display: none;
	}
}

.header_logoWrapper__1SDiB, .header_logoWrapper__1SDiB > img {
	z-index: 1;
}
.header_logoWrapper__1SDiB {
	display: flex;
	justify-content: center;
	background-color: transparent!important;
	height: inherit;
}
.header_logoWrapper__1SDiB > img {
	max-width: unset;
	display: inline-block;
	position: static;
}
.header_logo__2gzAt {
	height: inherit;
}

div.header_headerButton__nbL-0 {
	height: inherit;
	box-sizing: border-box;
	border-bottom: transparent 2px solid;
	word-wrap: break-word;
	font-size: 1.5vw;
	font-weight: 300;
}
div.header_headerButton__nbL-0:hover a.header_button__3Dvcf {
	background-color: #43853d;
	color: white!important;
}
div.header_headerButton__nbL-0:hover {
	border-bottom: blue 2px solid;
}
div.header_headerButton__nbL-0.header_selected__3ZcvA {
	border-bottom: gold 2px solid;
}

a.header_button__3Dvcf {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
	background-color: transparent;
	height: calc(100% - 1.5vh);
	margin: 1vh 30px;
	padding: 0px 5px;
	box-sizing: border-box;
	border-radius: 15px;
	color: #333;
	transition: color 1s ease;
}
.dark .header_header__3AnVH:not(.header_small__1FEFj) a.header_button__3Dvcf {
	color: white;
}

@media only screen and (max-width: 700px) {
    div.header_installer__3SXUE {
        flex-direction: column;
    }
    div.header_icons__2zG98 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: unset;
    }
    .header_header__3AnVH > .header_logoWrapper__1SDiB {
		display: flex;
		justify-content: center;
		background-color: transparent;
	}
	.header_logoWrapper__1SDiB > img {
		height: 100%;
	}
}

a.header_button__3Dvcf.header_native__2zjQ6 {
	text-align: center;
	background-color: #43853d55;
	margin: 1vh 10px;
	position: relative;
}
.header_native__2zjQ6 > svg, .header_native__2zjQ6 {
	font-size: 0.8em;
}

.header_notifications__27OsP {
	position: absolute;
	top: 100%;
	max-width: 400px;
	min-width: 200px;
	min-height: 200px;
	max-height: 80vh;
	background-color: rgba(200, 200, 200, 0.95);
	margin-top: 10px;
	right: 15%;
	transition: top 0.68s ease, opacity 0.33s ease;
	border-radius: 15px;
	font-family: Josefin Sans, sans-serif;
	padding: 20px 10px;
	box-sizing: border-box;
	z-index: 3;
	overflow-y: auto;
}
.header_notifications__27OsP::-webkit-scrollbar {
	width: 10px;
}
.header_notifications__27OsP::-webkit-scrollbar-track {
	overflow: hidden;
}
.header_notifications__27OsP::-webkit-scrollbar-corner {
	display: none;
}
.header_notifications__27OsP::-webkit-scrollbar-button {
	display: none;
}
.header_notifications__27OsP::-webkit-resizer {
	display: none;
}
.header_notifications__27OsP::-webkit-scrollbar-thumb  {
	background-color: rgba(232, 232, 248, 0.95);
	-webkit-transition: background-color 2s ease;
	transition: background-color 2s ease;
	border-radius: 15px;
}
.header_notifications__27OsP::-webkit-scrollbar-thumb:hover {
	position: relative;
}

/* Mobile */


.header_mobileMenu__1JasY {
	position: fixed;
	top: 0px;
	font-family: Josefin sans, sans-serif;
	background-color: rgba(235, 235, 235, 0.95);
	width: 100%;
	height: 100%;
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: 80%;
	justify-content: center;
	transition: all 0.5s ease;
	padding-top: 12vh;
	box-sizing: border-box;
	z-index: 2;
	overflow: hidden;
}

.header_mobileMenu__1JasY a.header_button__3Dvcf {
	color: rgb(25, 25, 25);
    -webkit-text-emphasis: none;
            text-emphasis: none;
	text-decoration: none;
	border-bottom: rgba(255, 255, 255, 0.8) solid 2px;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 0px;
}

.header_mobileMenu__1JasY hr {
	background-color: white;
	width: 80%;
	height: 5px;
}

@media only screen and (max-width: 700px) {
    div.header_header__3AnVH {
        grid-template-columns: 80% 20%;
        grid-column-gap: 0%;
	}
	a.header_logoWrapper__1SDiB {
		margin-left: 20vw;
		box-sizing: border-box;
	}
}

.header_menuButton__1iKql {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 4vh;
	height: inherit;
}

.header_unseen__z38Fn {
	position: absolute;
	background-color: var(--col-alert1);
	border-radius: 50%;
	width: 1.3em;
	height: 1.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	color: white;
	bottom: -0.31em;
	right: -0.31em;
}
.header_inlineNotifTitle__2-Ono {
	padding: 0 10px;
}

a.header_inlineNotif__2g-5s {
	margin: 5px 0;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	align-items: center;
	border-radius: 15px;
	padding: 10px;
	grid-template-areas:
		'icon content'
		'icon date';
}
.header_inlineNotif__2g-5s.header_unseenN__mphpe {
	background-color: var(--col-accent5);
}

.header_inlineNotifContent__1dD3f {
	grid-area: content;
}
.header_inlineNotifContent__1dD3f p {
	-webkit-margin-before: 0em;
	        margin-block-start: 0em;
	-webkit-margin-after: 0em;
	        margin-block-end: 0em;
}
.header_inlineNotifDate__1useH {
	grid-area: date;
	color: black;
	font-size: small;
}
.footer_footer__3JrgB {
	display: grid;
	grid-template-columns: auto auto;
	grid-column-gap: 50px;
	-webkit-column-gap: 50px;
	        column-gap: 50px;
	max-width: 90vw;
	align-self: center;
	justify-self: center;
	justify-content: center;
	margin-bottom: 50px;
}
@media only screen and (max-width: 1050px) {
	.footer_footer__3JrgB {
		grid-template-columns: unset;
		grid-template-rows: auto auto;
		align-items: center;
	}
}

.footer_col1__1qvnn {
	display: grid;
	grid-template-columns: 34% 1fr;
	grid-template-rows: auto auto;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}
@media only screen and (max-width: 1050px) {
	.footer_col1__1qvnn {
		width: unset;
	}
}

.footer_logoWrapper__cNWw0 {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	justify-self: right;
}
.footer_logo__1UHL_ {
	width: 100px;
	cursor: pointer;
}
.footer_text__3rv_o {
	padding-top: 70px;
	box-sizing: border-box;
	cursor: pointer;
}

/* Depth: 2 */

.footer_copyright__3FrmR, .footer_version__1joZ_ {
	display: flex;
	justify-content: center;
	grid-column: span 2;
	white-space: pre-wrap;
}

/* Depth: 3 */

.footer_col2__2wzJ0 {
	display: flex;
	align-items: flex-end;
}

.footer_shortcuts__2x3Je {
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	grid-auto-rows: auto;
	grid-auto-flow: column;
	grid-row-gap: 0.5em;
	row-gap: 0.5em;
}
@media only screen and (max-width: 1050px) {
	.footer_shortcuts__2x3Je {
		display: flex;
		flex-direction: column;
		width: 100%;
		text-align: center;
	}
}
.footer_shortcuts__2x3Je a {
	border-radius: 10px;
	padding: 3px 5px;
	min-width: 20ch;
	color: black;
	font-weight: 300;
}
#root.dark .footer_shortcuts__2x3Je a {
	color: white;
}
.news_news__3JwX8 {
	max-width: 1080px;
}
/* Depth: 3 */

.contact_content__3nm_K {
	font-weight: 300;
}

/* Depth: 4 */

.contact_fieldSet__21EWO {
	border-radius: 15px;
	display: grid;
	grid-row-gap: 10px;
	row-gap: 10px;
	margin: 20px 0;
	padding: 20px;
	box-sizing: border-box;
	border: 1px solid grey;
}

.contact_list__2yoGo {
	display: grid;
	grid-row-gap: 10px;
	row-gap: 10px;
	margin: 1em;
	max-width: 500px;
}

/* Depth: 5.0 */

.contact_list__2yoGo a, .contact_list__2yoGo div {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}

/* Depth: 5.1 */

.contact_fieldSet__21EWO *:not(legend) {
	width: 100%;
	margin: 10px 0;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 15px;
}

.contact_fieldSet__21EWO input, .contact_fieldSet__21EWO input:focus, .contact_fieldSet__21EWO select:focus, .contact_fieldSet__21EWO textarea:focus {
	border: 1px solid grey;
	outline: none;
}
.contact_fieldSet__21EWO legend {
	font-family: Josefin Sans;
	font-size: 20px;
}
.contact_fieldSet__21EWO input::-webkit-input-placeholder, .contact_fieldSet__21EWO select::-webkit-input-placeholder, .contact_fieldSet__21EWO textarea::-webkit-input-placeholder {
	font-family: Josefin Sans;
	font-size: 16px;
}
.contact_fieldSet__21EWO input:-ms-input-placeholder, .contact_fieldSet__21EWO select:-ms-input-placeholder, .contact_fieldSet__21EWO textarea:-ms-input-placeholder {
	font-family: Josefin Sans;
	font-size: 16px;
}
.contact_fieldSet__21EWO input::-ms-input-placeholder, .contact_fieldSet__21EWO select::-ms-input-placeholder, .contact_fieldSet__21EWO textarea::-ms-input-placeholder {
	font-family: Josefin Sans;
	font-size: 16px;
}
.contact_fieldSet__21EWO input, .contact_fieldSet__21EWO select, .contact_fieldSet__21EWO textarea, .contact_fieldSet__21EWO input::placeholder, .contact_fieldSet__21EWO select::placeholder, .contact_fieldSet__21EWO textarea::placeholder {
	font-family: Josefin Sans;
	font-size: 16px;
}

.contact_textArea__2-djk {
	min-height: 25vh;
}

.contact_submit__3HK4a {
	outline: none;
	border: none;
}
/* Depth: 1 */

.floated_floated__118Mz {
	display: flex;
	position: fixed;
	z-index: 3;
	font-size: 16px;
	justify-content: center;
	align-items: center;
	font-family: Josefin Sans;
}
.floated_container__2tXHo {
	position: fixed;
	max-width: 300px;
	z-index: 2;
	font-size: smaller;
	display: flex;
	flex-direction: column;
}
@media only screen and (max-width: 1050px) {
	.floated_floated__118Mz, .floated_container__2tXHo {
		display: none;
	}
}

@-webkit-keyframes floated_fly-in-from-right__2J-y2 {
	from {
		right: -100vw;
		opacity: 0;
	}
	to {
		right: 1em;
		opacity: 1;
	}
}

@keyframes floated_fly-in-from-right__2J-y2 {
	from {
		right: -100vw;
		opacity: 0;
	}
	to {
		right: 1em;
		opacity: 1;
	}
}
@-webkit-keyframes floated_fly-in-from-left__tlTt0 {
	from {
		left: -100vw;
		opacity: 0;
	}
	to {
		left: 1em;
		opacity: 1;
	}
}
@keyframes floated_fly-in-from-left__tlTt0 {
	from {
		left: -100vw;
		opacity: 0;
	}
	to {
		left: 1em;
		opacity: 1;
	}
}
.floated_left__3BIxl {
	left: 1em;
	-webkit-animation: floated_fly-in-from-left__tlTt0 1s ease 1.5s both;
	        animation: floated_fly-in-from-left__tlTt0 1s ease 1.5s both;
}
.floated_bottom__2l4p2 {
	bottom: 1em;
}
.floated_top__1EBos, .floated_top__1EBos:hover {
	top: 9vh;
}
.floated_right__lbqAX {
	right: 1em;
	-webkit-animation: floated_fly-in-from-right__2J-y2 1s ease 1.5s both;
	        animation: floated_fly-in-from-right__2J-y2 1s ease 1.5s both;
}

/* Depth: 2 */

.floated_linkSwitcher__3R5fI {
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 5px;
	-webkit-column-gap: 5px;
	        column-gap: 5px;
	padding: 10px;
	align-items: right;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	align-self: flex-end;
	opacity: 0.9;
}

.floated_container__2tXHo div.container {
	box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 7px 0px;
	max-width: 300px;
	background-color: rgba(200, 200, 200, 0.9);
	font-size: smaller;
}

.floated_wrapper__PNN0c {
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	overflow: hidden;
}

/* Depth: 3 */

.floated_formWrapper__1yAc7 {
	display: grid;
}

.floated_dot__1hSH3 {
	cursor: pointer;
	font-size: small;
}

/* Depth: 4 */

.floated_floated__118Mz svg {
	padding: 0 10px;
}

a.floated_link__3_GJM {
	display: flex;
	flex-direction: row;
	color: white;
	border-radius: 10px;
	padding: 3px 10px;
	justify-content: center;
	align-items: center;
	background-color: var(--col-accent4);
	box-sizing: border-box;
	z-index: 1;
}

.floated_dotExpando__3Wf4b {
	cursor: pointer;
}
.floated_expando__MiW_b {
	position: relative;
	display: flex;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: var(--col-alert1);
	color: white;
	max-width: 10ch;
	padding: 3px 15px 3px 20px;
	justify-content: center;
	align-items: center;
	margin-left: -10px;
	left: 0px;
	z-index: 0;
	overflow: hidden;
	transition: 0.333s ease all;
	box-sizing: border-box;
	cursor: pointer;
}
.floated_wrapper__PNN0c:not(:hover) .floated_expando__MiW_b {
	max-width: 0px;
	left: -30px;
}

/* Depth: 4.1 */

.floated_title__37QlP {
	white-space: pre-line;
	text-align: center;
	background-color: var(--col-accent2);
	border-radius: 10px;
	padding: 5px 2px;
	margin-bottom: 5px;
}

.floated_input__3iheT input {
	background-color: var(--col-alert1);
	border: none;
	outline: none;
	padding: 8px 5px;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
}
.floated_input__3iheT input::-webkit-input-placeholder {
	font-family: Josefin Sans;
	color: white;
	opacity: 0.5;
}
.floated_input__3iheT input:-ms-input-placeholder {
	font-family: Josefin Sans;
	color: white;
	opacity: 0.5;
}
.floated_input__3iheT input::-ms-input-placeholder {
	font-family: Josefin Sans;
	color: white;
	opacity: 0.5;
}
.floated_input__3iheT input::placeholder {
	font-family: Josefin Sans;
	color: white;
	opacity: 0.5;
}

.floated_buttons__uLTYZ {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr auto 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	max-height: 5em;
	overflow: hidden;
	margin-top: 10px;
	transition: 0.333s ease all;
}
.floated_container__2tXHo:not(:hover) .floated_buttons__uLTYZ {
	max-height: 0px;
	margin-top: 0px;
}

.floated_buttons__uLTYZ button, .floated_buttons__uLTYZ .floated_viewTournament__10Rw- {
	background-color: var(--col-accent2);
	color: var(--col-text1);
	padding: 5px 10px;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	border: none;
	outline: none;
	font-family: Josefin Sans;
	position: relative;
}
.floated_buttons__uLTYZ *:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.floated_buttons__uLTYZ *:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}
.floated_viewTournament__10Rw- svg {
	padding: 0px;
}
/* Depth: 1 */

.embed_cover__354uy {
	position: fixed;
	background-color: white;
	z-index: 9;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
}

.embed_canvas__3b5lE {
	transform-origin: top left;
	position: relative;
}

.embed_brand__2tsAG {
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 20%;
	z-index: 10;
}
