.stage {
	display: grid;
	column-gap: 15px;
	grid-template-columns: 68.1fr 31.9fr;
	grid-template-rows: 50px 1fr;
	grid-template-areas:
		'info next'
		'viewer next';
	height: 100vh;
	margin-bottom: 100px;
}

.viewer {
	grid-area: viewer;
}
.rightCol {
	grid-area: next;
	display: grid;
	grid-template-rows: auto 1fr auto;
	row-gap: 10px;
}

div.more {
	display: grid;
	row-gap: 15px;
	height: 100%;
	border-radius: 15px;
	overflow-y: hidden;
	position: relative;
}

div.scroll {
	border-radius: 10px;
}
div.info {
	grid-area: info;
	height: max-content;
	font-family: Josefin Sans, sans-serif;
}

.viewer :global(.pgnViewerMain) {
	height: 490px;
}
div.more > div {
	height: max-content;
	cursor: pointer;
}

.more :global(.pgnViewerMoveList), .more :global(.pgnViewerFooter)  {
	display: none;
	visibility: hidden;
}
.more :global(.pgnViewerMain) {
	grid-template-columns: unset;
}