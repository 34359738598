.featured {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.headerItems {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 20px;
	font-weight: bold;
	text-align: center;

	display: flex;
	align-items: flex-end;
	flex-direction: row;

	display: grid;
	grid-column: 1 / span 2;
	width: 100%;
}

.headingContainer {
	padding: 5px;
	margin-bottom: 5px;
}

.table {
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.featured_table tbody::before {
	line-height: 0.5em !important;
}

.table :global(.playerName.left) {
	width: 100%;
	justify-content: flex-start;
}

.cell, a:global(.playerName) {
	font-family: Arial, Helvetica, sans-serif;
}
th.headerCell {
	display: none;
}

.flag {
	position: relative;
	width: 2.5em;
	height: 2.5em;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	text-align: center;
}

/* TODO: Centre this flag */
.flag > * {
	display: block;
	padding-left: 9px;
	padding-top: 4px;
}

.tr {
	height: 30px;
}
.showAllButton {
	border-radius: 50%;
	border: none;
	
	position: absolute;
	height: 2em;
	width: 2em;
	outline: none;
	cursor: pointer;

	bottom: -1em;
}

@media only screen and (max-width: 700px) {
	th.headerCell {
		display: table-cell;
	}
	th.headerCell > div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: black;
	}
	table > tbody > tr.tr {
		display: grid;
		grid-template-columns: 50% 50%;
		background-color: #f0f0f0;
	}
	table td {
		text-align: center;
	}
	tr.tr > th:nth-child(4n), tr.tr > th:nth-child(4n-1), tr.tr > td:nth-child(4n) {
		background-color: lightblue;
	}
}

.menuItems.selectedItem {
	background-color: cyan;
}

a.menuItems.nullRound, a.pairingRound.nullRound:hover > * {
	background-color: lightgrey;
	color: grey;
	cursor: default;
}