
.container {
	max-width: min(100vw, 1180px);
	width: inherit;
	margin: 0 20px;
	box-sizing: border-box;
	font-family: 'Josefin Sans', sans-serif;
	align-self: center;
	display: grid;
	column-gap: 25px;
	row-gap: 25px;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'gallery gallery'
		'col1 col2'
		'online new'
}

.section {
	display: grid;
	overflow: hidden;
}

@media only screen and (max-width: 700px) {
	div.container {
		grid-template-columns: 1fr;
		grid-template-areas:
			'gallery'
			'col1'
			'col2'
			'online'
			'new';
	}
	div.section {
		grid-column: unset;
		grid-row: unset;
	}
	div.title {
		grid-column: unset;
	}
}

.title {
	margin: 0px 5px;
}
.title h1 {
	margin: 20px 0;
}
.title small {
	font-size: small;
	margin: 0 10px;
}
.title h3 {
	margin: 10px 0;
}

.wrapper {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 10px;
	align-items: baseline;
}

.col1 {
	grid-area: col1;
	grid-template-rows: auto 1fr;
}
.col2 {
	grid-area: col2;	
	grid-template-rows: auto 1fr;
}
.col1, .col2 {
	display: grid;
	row-gap: 25px;
	width: 100%;
	max-width: 98vw;
	height: max-content;
}

.announcements, .tournaments {
	width: 100%;
	display: flex;
    flex: 0 1 auto;
	flex-shrink: 0;
	box-sizing: border-box;
	margin: 0px;
	height: max-content;
}

.tableAnnounce {
	width: 100%;
	overflow: hidden;
	border-radius: 15px;
	margin-bottom: 0px;
	table-layout: fixed;
}
.tableAnnounce tbody tr:not(:last-child) {
	border-bottom: 2px white solid;
}

@media only screen and (max-width: 700px) {
	.container {
		grid-template-columns: 1fr;
	}
	.section > table {
		margin-bottom: 0px;
	}
	.section > table thead {
		display: table-header-group;
	}
	.section > table th {
		width: 100%;
		display: flex;
	}
	.section > table td {
		width: 100%;
		display: flex;
		text-align: center;
	}
}

.yourTournaments {
	grid-column: 1 / span 2;
	grid-row: 3 / span 1;
	overflow: hidden;
}

.sectionHeader {
	background-color: rgba(135, 207, 235, 0.5);
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	border-radius: 15px;
	overflow: hidden;
}
.sectionHeader.live {
	background-color: rgb(73, 73, 255, 0.3);
}
.sizer > th:first-child {
	width: 40%
}
.sizer > th:last-child {
	width: 5%
}
.tournaments tr > *:first-child {
	overflow: hidden;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.tournaments tr > *:last-child {
	overflow: hidden;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.tournaments tbody tr:hover > th, .tournaments tbody tr:hover > td, .tournaments tbody tr:hover a {
	background-color: var(--col-accent1);
	color: white;
}

.notificationRow {
	overflow-x: hidden;
	white-space: nowrap;
	width: 100%;
}
.notificationRow::-webkit-scrollbar, .notificationRow > td::-webkit-scrollbar {
	display: none;
}
.notificationRow > td {
	display: inline-block;
	width: 100%;
}

.notificationRow > td > div {
	display: grid;
	grid-template-columns: 5% auto 1fr 5%;
	column-gap: 10px;
	align-items: center;
	overflow: hidden;
	border-radius: 15px;
	padding: 0 5px;
}

.notificationRow > td > div > div, .notificationRow > td > div > a {
	display: flex;
    flex: 0 1 auto;
	flex-shrink: 0;
	width: 100%;
	overflow: hidden;
	align-items: center;
}

.expando {
	cursor: pointer;
}

:global(.download-table) > tbody .rowLink:hover {
	background-color: var(--col-accent1);
	color: white;
}

:global(.download-table) > tbody .rowLink:hover > * > * {
	color: white;
}

:not(:global(#profileTournaments)).tournamentLink {
	width: 100%;
	display: grid;
	grid-template-columns: auto 5%;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
}

.notificationRow > td > div:hover, .notificationRow > td > div:hover > a.iconLink {
    color: #fff;
	background-color: var(--col-accent1);
	cursor: pointer;
}

.connected, .disconnected {
	display: flex;
	align-items: center;
	font-size: small;
}

.connected > svg {
	color: var(--col-accent1);
	height: 1em;
	padding-right: 10px;
}

.disconnected {
	color: var(--col-alert1);
	height: 1em;
	padding: 0 5px;
}
.container .logout:hover {
	color: white;
}

.info {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-areas:
		'Name . Buttons'
		'Details . Buttons'
}

.buttons {
	grid-area: Buttons;
	display: grid;
	grid-auto-rows: 2em;
	grid-auto-flow: row;
	row-gap: 5px;
	transform: scaleY(-1);
	justify-content: right;
}
.buttons > .row {
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	column-gap: 5px;
	transform: scaleY(-1);
	width: max-content;
	justify-self: right;
}
.buttons > .row > div, .buttons > .row > a {
	padding: 0 20px;
	border-radius: 10px;
}
.details {
	grid-area: Details
}

a.account {
	display: grid;
	grid-template-columns: minmax(auto, 1fr) auto minmax(auto, 1fr);
	justify-content: center;
	align-items: center;
	grid-auto-flow: column;
}

.sync {
	padding: 0 5px;
	box-sizing: border-box;
}
.row > span > button:global(.kep-login-facebook) {
	height: 100%;
	padding: 0 20px;
	border-radius: 10px;
	font-family: Josefin Sans, sans-serif;
}

.container .new {
	border-radius: 10px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	text-align: right;
	width: minmax(max-content, 100%);
	padding: 5px;
	background-color: var(--col-accent2);
}