
.switcher {
	grid-area: modes;
    display: grid;
	grid-auto-columns: 1fr;
	grid-auto-rows: 1fr;
	grid-auto-flow: column;
    overflow: hidden;
	column-gap: 15px;
	row-gap: 20px;
	font-size: 1em;
}

@media only screen and (max-width: 700px) {
	.switcher {
		grid-auto-flow: row;
	}
}

.switcher > :global(.header-button):first-child {
	overflow: hidden;
}

.switcher > :global(.header-button) {
	border: none;
	border-radius: 10px;
	overflow: hidden;
}

.switcher > :global(.header-button) > div {
	margin: 0px;
	grid-template-rows: unset;
	padding: 7px 0;
	display: flex;
}