.header {
    top: 0px;
    width: 100%;
    height: 9vh;
    z-index: 3; /* On account of react-carousel */
	background-color: rgba(255, 255, 255, 0.0);
	display: grid;
	position: fixed;
    grid-template-columns: 15vh 1fr;
	align-items: start;
	font-family: Roboto, "Source Sans Pro", "Open Sans", "San Francisco", Helvetica, Arial, sans-serif;
	transition: height 0.68s ease, box-shadow 0.68s ease, background-color 0.68s ease 0.31s;
}
.header.small {
	background-color: rgb(217, 244, 255);
	height: 6vh;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
}

.buttonContainer {
    display: grid;
    grid-template-columns: 1.5fr repeat(3, 1fr) repeat(3, auto) 2fr;
    grid-auto-flow: column;
	grid-area: 'name link link link link link';
	align-items: center;
	height: inherit;
}

.buttonContainer .button:first-child {
	padding: 5px;
}

@media only screen and (max-width: 700px) {
	.header {
		grid-template-columns: 100%;
	}
	.buttonContainer, div.header .nameWrapper {
		display: none;
	}
}

.logoWrapper, .logoWrapper > img {
	z-index: 1;
}
.logoWrapper {
	display: flex;
	justify-content: center;
	background-color: transparent!important;
	height: inherit;
}
.logoWrapper > img {
	max-width: unset;
	display: inline-block;
	position: static;
}
.logo {
	height: inherit;
}

div.headerButton {
	height: inherit;
	box-sizing: border-box;
	border-bottom: transparent 2px solid;
	word-wrap: break-word;
	font-size: 1.5vw;
	font-weight: 300;
}
div.headerButton:hover a.button {
	background-color: #43853d;
	color: white!important;
}
div.headerButton:hover {
	border-bottom: blue 2px solid;
}
div.headerButton.selected {
	border-bottom: gold 2px solid;
}

a.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
	background-color: transparent;
	height: calc(100% - 1.5vh);
	margin: 1vh 30px;
	padding: 0px 5px;
	box-sizing: border-box;
	border-radius: 15px;
	color: #333;
	transition: color 1s ease;
}
:global(.dark) .header:not(.small) a.button {
	color: white;
}

@media only screen and (max-width: 700px) {
    div.installer {
        flex-direction: column;
    }
    div.icons {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: unset;
    }
    .header > .logoWrapper {
		display: flex;
		justify-content: center;
		background-color: transparent;
	}
	.logoWrapper > img {
		height: 100%;
	}
}

a.button.native {
	text-align: center;
	background-color: #43853d55;
	margin: 1vh 10px;
	position: relative;
}
.native > svg, .native {
	font-size: 0.8em;
}

.notifications {
	position: absolute;
	top: 100%;
	max-width: 400px;
	min-width: 200px;
	min-height: 200px;
	max-height: 80vh;
	background-color: rgba(200, 200, 200, 0.95);
	margin-top: 10px;
	right: 15%;
	transition: top 0.68s ease, opacity 0.33s ease;
	border-radius: 15px;
	font-family: Josefin Sans, sans-serif;
	padding: 20px 10px;
	box-sizing: border-box;
	z-index: 3;
	overflow-y: auto;
}
.notifications::-webkit-scrollbar {
	width: 10px;
}
.notifications::-webkit-scrollbar-track {
	overflow: hidden;
}
.notifications::-webkit-scrollbar-corner {
	display: none;
}
.notifications::-webkit-scrollbar-button {
	display: none;
}
.notifications::-webkit-resizer {
	display: none;
}
.notifications::-webkit-scrollbar-thumb  {
	background-color: rgba(232, 232, 248, 0.95);
	transition: background-color 2s ease;
	border-radius: 15px;
}
.notifications::-webkit-scrollbar-thumb:hover {
	position: relative;
}

/* Mobile */


.mobileMenu {
	position: fixed;
	top: 0px;
	font-family: Josefin sans, sans-serif;
	background-color: rgba(235, 235, 235, 0.95);
	width: 100%;
	height: 100%;
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: 80%;
	justify-content: center;
	transition: all 0.5s ease;
	padding-top: 12vh;
	box-sizing: border-box;
	z-index: 2;
	overflow: hidden;
}

.mobileMenu a.button {
	color: rgb(25, 25, 25);
    text-emphasis: none;
	text-decoration: none;
	border-bottom: rgba(255, 255, 255, 0.8) solid 2px;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 0px;
}

.mobileMenu hr {
	background-color: white;
	width: 80%;
	height: 5px;
}

@media only screen and (max-width: 700px) {
    div.header {
        grid-template-columns: 80% 20%;
        grid-column-gap: 0%;
	}
	a.logoWrapper {
		margin-left: 20vw;
		box-sizing: border-box;
	}
}

.menuButton {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 4vh;
	height: inherit;
}

.unseen {
	position: absolute;
	background-color: var(--col-alert1);
	border-radius: 50%;
	width: 1.3em;
	height: 1.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	color: white;
	bottom: -0.31em;
	right: -0.31em;
}
.inlineNotifTitle {
	padding: 0 10px;
}

a.inlineNotif {
	margin: 5px 0;
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 10px;
	align-items: center;
	border-radius: 15px;
	padding: 10px;
	grid-template-areas:
		'icon content'
		'icon date';
}
.inlineNotif.unseenN {
	background-color: var(--col-accent5);
}

.inlineNotifContent {
	grid-area: content;
}
.inlineNotifContent p {
	margin-block-start: 0em;
	margin-block-end: 0em;
}
.inlineNotifDate {
	grid-area: date;
	color: black;
	font-size: small;
}