
section.container {
	margin: 20px 0;
	font-family: 'Josefin Sans', sans-serif;
	font-size: 16px;
}

.streamHeader {
	display: grid;
	grid-template-columns: auto 1fr 100px;
	margin: 10px;
	align-items: center;
}

.streamHeader > *:not(:global(.button)) {
	padding: 0 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
@media only screen and (max-width: 700px) {
	.popOut {
		display: none;
	}
}

.streamHeader > span {
	width: 90%;
}

.streamContainer {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 700px) {
	.streamHeader {
		flex-direction: column;
	}
	.streamContainer {
		flex-direction: column;
	}
	div.player {
		width: 100%;
		height: 68vh;
	}
	div#twitchChat {
		height: 80vh;
		width: 100%;
	}
}

.player {
	height: calc((1132px - 40px) / 1.78);
	width: 68%;
}

div:global(.popped) .player {
	height: calc(30vw / 1.68);
	width: 100%;
	animation: move-to-corner 0.5s ease;
}

.chat {
	height: calc((1132px - 40px) / 1.78);
	width: 32%;
}

@media screen and (max-width: 1132px) {
	.player {
		height: calc((100vw - 40px) / 1.78);
		width: 68%;
	}
	.chat {
		height: calc((100vw - 40px) / 1.78);
		width: 32%;
	}
}

.footer {
	padding-top: 10px;
	margin: 20px 0;
	width: 100%;
	text-align: center;
	font-size: 13px;
	letter-spacing: -0.5px;
	text-align: left;
}

#twitchPlayer, #twitchChat {
	visibility: visible;
}

@keyframes move-to-corner {
	from {
		bottom: 20vh;
		right: 50vw;
	}
	to {
		bottom: 10px;
		right: 10px;
	}
}