.footer {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 50px;
	max-width: 90vw;
	align-self: center;
	justify-self: center;
	justify-content: center;
	margin-bottom: 50px;
}
@media only screen and (max-width: 1050px) {
	.footer {
		grid-template-columns: unset;
		grid-template-rows: auto auto;
		align-items: center;
	}
}

.col1 {
	display: grid;
	grid-template-columns: 34% 1fr;
	grid-template-rows: auto auto;
	width: max-content;
	height: max-content;
}
@media only screen and (max-width: 1050px) {
	.col1 {
		width: unset;
	}
}

.logoWrapper {
	width: max-content;
	justify-self: right;
}
.logo {
	width: 100px;
	cursor: pointer;
}
.text {
	padding-top: 70px;
	box-sizing: border-box;
	cursor: pointer;
}

/* Depth: 2 */

.copyright, .version {
	display: flex;
	justify-content: center;
	grid-column: span 2;
	white-space: pre-wrap;
}

/* Depth: 3 */

.col2 {
	display: flex;
	align-items: flex-end;
}

.shortcuts {
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	grid-auto-rows: auto;
	grid-auto-flow: column;
	row-gap: 0.5em;
}
@media only screen and (max-width: 1050px) {
	.shortcuts {
		display: flex;
		flex-direction: column;
		width: 100%;
		text-align: center;
	}
}
.shortcuts a {
	border-radius: 10px;
	padding: 3px 5px;
	min-width: 20ch;
	color: black;
	font-weight: 300;
}
:global(#root.dark) .shortcuts a {
	color: white;
}