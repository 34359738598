.columns {
	display: grid;
	grid-template-columns: 68.1fr 31.8fr;
	column-gap: 20px;
}

/* Depth: 2 */

.controls {
	display: grid;
	row-gap: 5px;
	justify-content: stretch;
	width: 100%;
	height: max-content;
}

.arrows {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

/* Depth: 3 */

.title {
	display: flex;
}

.arrow {
	border: grey 5px solid;
	background-color: var(--col-accent2);
	border-radius: 50%;
	width: 1.5em;
	height: 1.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.link input {
	width: 100%;
	padding: 5px;
	user-select: all;
}