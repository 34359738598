.container {
    max-width: 1180px;
	margin: 0 20px 50px;
	box-sizing: border-box;
	overflow: hidden;

	display: grid;
	column-gap: 25px;
	row-gap: 25px;
	grid-template-columns: 1fr 1fr;
	font-family: 'Josefin Sans', sans-serif;
}

.userTable {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
}
.status {
	color: #43853d;
	display: flex;
	justify-content: center;
}
.status:not(.online) {
	color: darkgrey;
}

a.profile {
	border-radius: 15px;
	padding: 0 10px;
}

.title {
	font-size: xx-large;
	margin: 20px 0;
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	column-gap: 20px;
	align-items: center;
	width: max-content;
}
:global(.light) {
	font-weight: 300;
}