.sidebar {
	position: fixed;
	width: calc(1132px / 4);
	top: 8vh;
	right: 0px;
	display: grid;
	row-gap: 10px;
	transition: 3s ease transform;
	opacity: 1;
}

@media only screen and (max-width: 1050px) {
	div.sidebar {
		position: static;
		width: unset;
	}
	.sidebar :global(.ct-chart) {
		display: none;
	}
}

.name {
	font-weight: bold;
	text-align: center;
}

/* Announcement */

.announcement {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		'title title'
		'body body'
		'dates more';
}
.announcement .title {
	grid-area: title;
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 5px;
	border-radius: 10px;
	padding: 0px 5px;
	align-items: center;
	font-size: medium;
}
.announcement .body {
	grid-area: body;
}
.announcement .body *, .announcement .dates {
    font-family: "Roboto", Consolas, Monaco, "Andale Mono", monospace;
	font-size: x-small!important;
	margin-block-start: 0em;
	margin-block-end: 0em;
}
.announcement .dates {
	grid-area: dates;
	font-weight: bold;
}
.announcement .more {
	display: flex;
	align-items: center;
	grid-area: more;
	border-radius: 6px;
	background-color: var(--col-accent2);
	font-size: x-small;
	padding: 0 8px;
}

/* Chart */

.sidebar :global(.ct-chart) {
	max-height: 120px;
}
.sidebar :global(.gauge) :global(.ct-label) {
	font-size: 16px;
}

/* Floating buttons */


.sidebar :global(.button) {
	display: grid;
	grid-template-columns: minmax(1.5ch, auto) 1fr minmax(1.5ch, auto);
	text-align: center;
	justify-content: center;
	align-items: center;
}

div.statusPicker > div:global(.button) {
	border-radius: 10px;
}
div.timezone, div.download, div.jumpTo, div.expandAll, .statusPicker > div {
	border-radius: 10px;
	padding: 0 5px;
	box-sizing: border-box;
}

.searchBar {
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent2);
	align-items: center;
	justify-content: stretch;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	padding: 5px 10px;
}

.searchBar > * {
	height: 100%;
	display: flex;
	align-items: center;
}
.searchBar * {
	background-color: transparent;
}
.searchBar form {
	padding: 2px 0;
}

.searchBar div, .searchBar form, .searchBar input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.searchBar input, .searchBar input:focus {
	border: none;
	margin: 5px 0;
}
