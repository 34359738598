

/* Full screen */

.container {
	display: grid;
	grid-template-areas:
		'header header header'
		'title title title'
		'content content content'
		'brand . url';
	grid-template-rows: 50px auto auto 50px;

	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 3;
	background-color: white;

	-webkit-font-smoothing: antialiased;
	font: 400 20px/1.5 'Roboto', "Source Sans Pro", "Open Sans", "San Francisco", Helvetica, Arial, sans-serif;
	color: #333;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1200;
	justify-content: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s, opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	transform: translate3d(0px, 0px, 0px);
	opacity: 1;
}

.container > .fullScreenHeader, .container > :global(.header) {
	grid-area: header;
	width: 100vw;
	display: flex;
	position: absolute;
}

.container > .fullScreenHeader {
	opacity: 0;
	transition: opacity 0.333s ease;
}

.container > .fullScreenHeader > * {
	width: 100%;
}

.container > .fullScreenHeader:hover {
	width: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0));
	opacity: 1;
}

.stage {
	grid-area: title / content / footer / content;
	width: 100%;
	padding: 0px 80px;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	display: grid;
	grid-template-rows: auto auto 50px;
	grid-template-areas:
		'title title title'
		'content content content'
		'brand . url';
}

@media only screen and (max-width: 700px) {

	.stage {
		grid-area: unset;
	}
}

.container :global(.chartHeader), .container :global(.chartFooter) {
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.container :global(.chartHeader) {
	top: 50px;
}
.container :global(.chartFooter) {
	bottom: 50px;
}

.container button svg {
	color: white;
	cursor: pointer;
	height: 100%;
}

.container .fullScreenHeader span:last-child {
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
}

.container .fullScreenHeader button {
	width: 50px;
	background: none;
	border: none;
	color: white;
	transition: opacity 300ms, -webkit-transform 300ms, top 300ms;
	justify-self: right;
	box-sizing: border-box;
	opacity: 0;
	position: relative;
	top: -50%;
}

.container .fullScreenHeader:hover button {
	top: 0px;
    opacity: 1;
}
:global(.css-1rkjyjh) {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -webkit-flex: 0 0 auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
}