/* Depth: 1 */

.container {
	box-sizing: border-box;
	overflow: hidden;

	display: grid;
	column-gap: 25px;
	row-gap: 25px;
	grid-template-columns: 30.8fr 68.1fr;
	grid-template-areas:
		'box modes';
	font-family: 'Josefin Sans', sans-serif;
	min-height: 68vh;
}

/* Depth: 2 */
.col1 {
	grid-area: box;
}
.col2 {
	grid-area: modes;
	height: max-content;
	display: flex;
	flex-direction: column;
}

/* Depth: 3 */
.infoWrapper {
	position: relative;
}

.container > * {
	height: max-content;
	margin: 5px;
}

.section {
	display: grid;
	overflow: hidden;
}

@media only screen and (max-width: 700px) {
	div.container {
		grid-template-columns: 100%;
	}
	div.section {
		grid-column: unset;
		grid-row: unset;
	}
	div.title {
		grid-column: unset;
	}
}

/* Depth: 4 */
.wrapper {
	display: grid;
	grid-auto-flow: column;
	column-gap: 10px;
}

section.accountSection:not(:first-of-type) {
	margin-top: 5px;
}
section.accountSection:first-of-type {
	margin-top: 25px;
}
.optionsContainer {
	grid-auto-columns: 1fr;
}
.optionsContainer > *:first-child:nth-last-child(2) {
	grid-column: span 2;
}
.pendingContainer {
	grid-template-columns: 1fr auto;
}
.pendingContainer > div:first-child:last-child {
	grid-column: 1 / -1;
}
div.account {
	border-radius: 7px;
}
div.submit {
    background-color: var(--col-accent1);
}
div.claim {
	cursor: default;
	padding: 10px;
	width: max-content;
}
a.account {
	display: grid;
	grid-template-columns: repeat(3, auto);
	column-gap: 5px;
	justify-content: center;
	align-items: center;
	grid-auto-flow: column;
}
.account.inline > img.icon, .account.inline > svg.icon {
	height: 40px;
	width: 40px;
}
.account.inline, .account.inline:active {
	box-sizing: border-box;
	grid-template-columns: unset;
	border-radius: 15px;
	column-gap: 10px;
	cursor: pointer;
}
.account.inline:global(.sent,) .account.inline:global(.sent).active, .account.inline:global(.sent):hover {
	cursor: default;
}
.account.inline > span, .facebookLink, .facebookLink > button {
	background-color: transparent;
	border: none;
	outline: none;
	font-family: Josefin Sans, sans-serif;
	font-size: 20px;
	font-weight: bold;
	color: unset;
	display: flex;
	align-items: center;
	align-self: center;
	cursor: unset;
}
div.accountButton {
	padding: 0 20px;
	border-radius: 15px;
	display: flex;
	cursor: pointer;
}
.accountButton.remove {
	background-color: var(--col-alert1);
}
.accountButton:hover {
	opacity: 0.68;
}

.notes {
    border-radius: 5px;
    justify-content: center;
    align-items: center;
	display: flex;
    background-color: hsla(0,0%,58.8%,.68);
	font-weight: 700;
	font-size: small;
	box-sizing: border-box;
	align-self: baseline;
	padding: 10px 40px;
	height: 100%;
	
}
.sync {
	padding: 0 5px;
	box-sizing: border-box;
}

.auth {
	margin: 20px auto;
	width: max-content;
	display: grid;
	grid-template-columns: auto minmax(250px, auto);
	column-gap: 20px;
	row-gap: 20px;
}
.auth input {
	margin: unset;
	width: unset;
	border-radius: 10px;
	padding: 5px 10px;
}
.auth > a:global(.link):global(.button), .auth > div:global(.link):global(.button) {
	border-radius: 10px;
	display: grid;
	grid-template-columns: auto 1fr auto;
	column-gap: 10px;
	min-height: 40px;
}
.auth > div:global(.link):global(.button).null {
	background-color: grey!important;
	color: lightgrey;
	cursor: default;
}
.auth > div:global(.link):global(.button).null > img {
	filter: invert(0.8);
}
.authMessage {
	font-size: 12px;
}

/* Play */

.upcomingBattle {
	position: relative;
	margin-top: 40px;
}

.upcomingBattle > .stage {
	display: grid;
	column-gap: 20px;
	row-gap: 15px;
	grid-template-columns: 28.8% 1fr 5ch 1fr 28.8%;
	grid-template-rows: 50px 1fr auto;
	grid-template-areas:
		'wFlag wFlag round bFlag bFlag'
		'wName wName vs bName bName'
		'Buttons Buttons Info Info Score'
		'Buttons Buttons Info Info Score';
}
.upcomingBattle > .stage.reversed {
	grid-template-areas:
		'wFlag wFlag round bFlag bFlag'
		'wName wName vs bName bName'
		'Score Score Info Info Buttons'
		'Score Score Info Info Buttons';
}

.upcomingBattle .flag {
	position: relative;
	top: -50px;
	display: flex;
	justify-content: center;
}

.upcomingBattle .name {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 24px;
	background-color: hsla(0,0%,58.8%,.68);
	border-radius: 20px;
	padding: 5px;
}
.upcomingBattle .vs {
	grid-area: vs;
	font-weight: 700;
	font-size: 30px;
	display: flex;
	padding: 0 20px;
	justify-content: center;
	align-items: center;
}
.upcomingBattle .round, .upcomingBattle .round {
	grid-area: round;
	position: relative;
	top: -10px;
	font-size: 42px;
	color: var(--col-light1);
	background-color: transparent;
}
.upcomingBattle .round:hover {
	color: var(--col-accent1);
	background-color: transparent;
}

.upcomingBattle .buttons {
	grid-area: Buttons;
	display: grid;
	grid-template-columns: repeat(3, auto);
    grid-auto-rows: auto;
    overflow: hidden;
    column-gap: 15px;
    row-gap: 15px;
    font-size: 1em;
}

.buttons > :global(.header-button) {
	border-radius: 10px;
	padding: 3px 5px;
	grid-row: 2;
	align-items: center;
}
.upcomingBattle .buttons > :global(.header-button):nth-child(2) {
	grid-column-start: 1;
}
.upcomingBattle .buttons > :global(.header-button):last-child {
	grid-column-end: -1;
}
.upcomingBattle .buttons > :global(.header-button) > * {
	margin: 0 5px;
}

.upcomingBattle .buttons > :global(.header-button).join {
	grid-row: 1;
	grid-column: 1 / -1;
}
.upcomingBattle .buttons > :global(.header-button).gameLink {
	background-color: var(--col-accent2-hover);
	color: var(--col-accent4);
}

.upcomingBattle div.null {
	cursor: default;
	background-color: lightgrey;
	color: grey;
}

.upcomingBattle .infoBox {
	grid-area: Info;
	display: grid;
	grid-template-columns: 50fr 68.1fr;
	grid-auto-rows: auto;
	column-gap: 10px;
	overflow: hidden;
	font-size: 16px;
}
.upcomingBattle .scoreBox {
	grid-area: Score;
	white-space: pre-line;
}
.scoreBox .warning {
	color: grey;
}

.scoreBox table thead tr th {
	padding: unset;
}
.scoreBox > div {
	margin: 0;
	grid-template-columns: unset;
	font-size: small;
}

.upcomingBattle .infoBox, .upcomingBattle .scoreBox {
	padding: 10px;
	background-color: rgb(150, 150, 150, 0.31);
	border-radius: 15px;
	align-items: center;
}

.upcomingBattle .clickButton {
	background-color: rgba(150, 150, 150, 0.4);
	display: flex;
	text-align: center;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    border-radius: 15px;
    background-color: rgba(150, 150, 150, 0.68);
    text-decoration: none;
    padding: 2px;
}

.darkMode {
	padding: 10px;
	display: flex;
	align-items: center;
}
.darkMode svg {
	font-size: 32px;
}

.merge, .teamMerge {
	border-radius: 10px;
	padding: 5px;
}
.merge:hover, .teamMerge:hover {
	background-color: var(--col-accent2-hover);
	color: var(--col-accent1-hover);
}
.merge {
	grid-column: 1 / -1;
}
.teamMerge {
	font-weight: 700;
}