/* Depth: 0 */
.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}

.th {
	cursor: pointer;
}

.round {
	min-width: 20px;
}

.pool {
	text-align: center;
	font-weight: bold;
	margin: 5px;
}

/* Standings */

.table {
	width: 100%;
}
.table tbody::before {
    content: '@';
    display: block;
    line-height: 2em;
	color: transparent;
	opacity: 0;
}

.table :global(.playerName.left) {
	width: 100%;
	justify-content: flex-start;
}
th.headerCell {
	display: none;
}

.moreHidden {
	/* visibility: hidden; */
}

@media only screen and (max-width: 700px) {
	th.headerCell {
		display: table-cell;
	}
	th.headerCell > div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: black;
	}
	table > tbody > tr.tr {
		display: grid;
		grid-template-columns: 50% 50%;
		background-color: #f0f0f0;
	}
	table td {
		text-align: center;
	}
	tr.tr > th:nth-child(4n), tr.tr > th:nth-child(4n-1), tr.tr > td:nth-child(4n) {
		background-color: lightblue;
	}
}

.footer {
	font-family: Josefin Sans, sans-serif;
	font-size: 14px;
	font-weight: 400;
}

/* Col 2 stuff */

.col2 {
	display: grid;
	height: 50vh;
}

.button {
	border-radius: 50%;
	border: grey 5px solid;
	background-color: var(--col-accent2);
	display: none;
}

/* Search Bar */
.searchBar {
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent2);
	align-items: center;
	justify-content: stretch;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	padding: 5px 10px;
}

.searchBarDiv {
	margin-top: 2vh;
	display: grid;
	grid-template-columns: minmax(auto, 400px);
	align-self:start;
}

.searchBar > * {
	height: 100%;
	display: flex;
	align-items: center;
}

.searchBar * {
	background-color: transparent;
}

.searchBar form {
	padding: 2px 0;
}

.searchBar div, .searchBar form, .searchBar input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.searchBar input, .searchBar input:focus {
	border: none;
	margin: 5px 0;
}