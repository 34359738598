.columns {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
}

.userTable {
	display: grid;
	row-gap: 5px;
}

a.row {
	display: grid;
	grid-template-columns: 1em 1fr;
	border-radius: 5px;
	background-color: var(--col-accent2-hover);
	color: white;
}

.status {
	color: #43853d;
	display: flex;
	justify-content: center;
	align-items: center;
}
.status:not(.online) {
	color: darkgrey;
}

div.profile {
	padding: 0 10px;
}

.searchBar {
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent2);
	align-items: center;
	align-content: center;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	margin-bottom: 20px;
}

.searchBar > * {
	height: 100%;
	padding: 0 5px;
}

.searchBar * {
	background-color: transparent;
}

.searchBar form {
	padding: 2px 0;
}

.searchBar div, .searchBar form, .searchBar input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.searchBar input, .searchBar input:focus {
	border: none;
	padding: 0;
	margin: 0;
}
