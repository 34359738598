/* Depth: 1 */

.cover {
	position: fixed;
	background-color: white;
	z-index: 9;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
}

.canvas {
	transform-origin: top left;
	position: relative;
}

.brand {
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 20%;
	z-index: 10;
}