/* Depth: 0 */
.fixed, div.backContainer {
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100vw;
	z-index: 0;
	animation: fade-in ease 3s;
	grid-area: unset;

}

.parent, .background, .logoBackground {
	width: 100%;
	height: 100vh;
}
.background {
	opacity: 0.1;
}
.background.faded {
	opacity: 0.2;
	filter: saturate(2);
}
.background.lighter {
	filter: brightness(2);
}
.logoBackground {
	background-repeat: no-repeat;
	background-position: 68% 68%;
	background-size: 50vh;
	opacity: 0.3;
	display: flex;
	justify-content: center;
	align-items: center;
}
:global(#root.dark) .logoBackground {
	background-color: white;
	filter: brightness(0.3);
}
.logoBackground img {
	mix-blend-mode: multiply;
	position: relative;
	left: 12vw;
	height: 50vh;
}
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.2;
	}
}


/* Depth: 1 */

.rotator {
	grid-area: gallery;
	display: flex;
	width: 100%;
	position: relative;
	margin-bottom: 20px;
	justify-items: center;
}

/* Depth: 2 */

.stage {
    position: relative;
	left: -100vw;
    transition: left 0.5s;
	display: grid;
	grid-auto-flow: column;
    grid-auto-columns: 100vw;
}

.selection {
	position: absolute;
    display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	bottom: -10px;
	z-index: 2;
}

/* Depth: 3 */

.child {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

/* Depth: 4 */

.curr {
	display: grid;
	grid-template-areas:
		'description logo'
		'title logo';
	grid-template-columns: 1fr 20vw;
	padding: 0px 10vw 0px 10vw;
	column-gap: 10vw;
	height: inherit;
	position: relative;
	animation: from-right 1s ease;
}

@keyframes from-right {
	from {
		opacity: 0;
		right: -50%;
	}
	to {
		opacity: 1;
		right: 0px;
	}
}

/* Depth 3 */

.description {
	grid-area: description;
	display: flex;
	font-style: italic;
	font-weight: 300;
	font-size: large;
	text-align: center;
	justify-content: center;
	align-items: center;
	white-space: pre-wrap;
	padding: 5px;
}

.title {
	grid-area: title;
	height: 100%;
	width: 100%;
	font-size: xx-large;
	font-weight: 800;
	z-index: 1;
	display: flex;
	position: relative;
	justify-content: center;
	text-shadow: 0px 0.5px rgba(0, 0, 0, 0.3);
}

.palette {
	position: absolute;
	bottom: 0px;
	left: 0px;
	display: flex;
	flex-direction: row;
	visibility: hidden;
}
.box {
	width: 20px;
	height: 20px;
}

a.new {
	position: absolute;
	left: 55vw;
	bottom: -15px;
	border-radius: 15px;
	text-align: center;
	padding: 5px 15px;
	display: flex;
	align-items: center;
	height: max-content;
	width: max-content;
	background-color: var(--col-accent2);
	font-size: small;
	border: #e3e3e3 5px solid;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
	z-index: 2;
}
a.new svg {
	padding: unset;
}

/* Depth: 6 */
a.frame {
	grid-area: logo;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	width: 150px;
	height: 150px;
	position: relative;
	overflow: hidden;
	justify-self: center;
	border: #e3e3e3 5px solid;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
}
.logo {
	display: flex;
	position: relative;
	align-self: flex-end;
}

/* Text Gallery */

.dot {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
    border-radius: 50%;
    display: inline-block;
	margin: 0 10px;
	cursor: pointer;
}

.dotSelected {
    background-color: #002147;
}