.reddit-poll, .reddit-poll * {
	cursor: pointer;
	font-family: Helvetica, sans-serif;
	font-weight: bold;
	font-size: small;
}

.reddit-poll {
	padding: 0 10px;
	margin: 2px;
	border: 1px solid #c5c7cf;
	pointer-events: all;
}
.reddit-poll * {
	user-select: none;
}

.reddit-poll label {
	display: grid;
	grid-template-columns: 2em auto;
	position: relative;
	margin: 0.2em 0;
}

.reddit-poll input {
	user-select: none;
	z-index: -1;
	position: relative;
}
.reddit-poll input:after {
	top: 0px;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background: tranparent;
	content: '';
	display: block;
	z-index: 1;
}

.reddit-card {
	color: white;
}