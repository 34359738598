/* Depth: 1 */

.info {
	display: grid;
	grid-template-rows: 50px repeat(3, auto) 1fr;
	row-gap: 5px;
	column-gap: 15px;
	justify-content: center;
	overflow: visible;
	margin: 30px;
	margin-right: 0px;
	width: max-content;
	max-width: 300px;
	background-color: var(--container-bg);
}
.info.small {
	width: unset;
	align-self: stretch;
	grid-template-rows: 5px repeat(3, auto) 1fr;
}
.info * {
	display: flex;
	justify-content: center;
	text-align: center;
	transition: all 0.333s ease;
}

.info h1 {
	display: flex;
	margin: 20px 0;
	margin-block-end: 0em;
	font-size: min(2vw, 36px);
}
.info.small h1 {
	margin: 15px 0px 0px;
	font-size: smaller;
}
.info h3 {
	margin: 0;
}

/* Depth 1.5 */

.cols {
	display: grid;
	grid-template-rows: auto auto;
	padding: 0 5px;
	column-gap: 10px;
}

/* Depth: 2.0 */

.flag {
	position: relative;
	top: -35px;
	height: 100px;
	display: flex;
	justify-content: center;
	border-radius: 10px;
}
.small .flag {
	height: 50px;
}
.flag > img {
	width: unset;
	max-width: unset;
	height: 100px;
	height: 100%;
	border-radius: 10px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

/* Depth 2.1 */

.name, .name *, .name:hover, a.name, a.name * {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 900;
	margin-top: 10px;
	text-transform: uppercase;
	padding: 5px;
	background-color: unset;
	margin: unset;
	transition: unset;
}
a.name {
	color: black;
}
a.name:hover {
	background-color: unset;
	color: var(--col-accent1);
}

/* Depth 2.2 */

.bio:not(:empty) {
	display: flex;
	justify-content: center;
	padding: 0 10px;
	border-radius: 15px;
	font-style: italic;
	font-weight: 300;
	font-size: small;
	margin-bottom: 10px;
}

/* Depth 2.3 */

.details {
	display: grid;
	row-gap: 10px;
	margin: 10px 0;
	height: max-content;
}
.small .details {
	font-size: small;
	margin: 5px 0;
	row-gap: 5px;
}
.detail {
	display: flex;
	flex-direction: column;
	position: relative;
	white-space: pre-wrap;
	word-break: break-all;
	justify-content: flex-start;
}
.detail .title {
	opacity: 0.68;
	text-transform: uppercase;
	font-size: small;
}

/* Depth 2.4 */

.buttons {
	display: grid;
	grid-auto-flow: row;
	row-gap: 12px;
	padding-top: 10px;
	justify-content: stretch;
	height: max-content;
}
.small .buttons {
	font-size: small;
}
.buttons div:global(.button), .buttons a:global(.button) {
	padding: 10px 20px;
	border-radius: 7px;
	width: inherit;
	word-break: break-word;
}
.small .buttons > div, .small .buttons > a {
	padding: 5px 10px;
}
.buttons :global(.button) svg, .buttons :global(.button) img {
	padding: 0 5px;
}

/* Depth 2.5 */

.memberBox {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
	top: 0px;
	left: 0px;
	white-space: pre-wrap;
	font-size: small;
}
.memberBox :global(.playerName) {
	display: flex;
}
.small .memberBox :global(.playerName) {
	min-height: unset;
}

/* Depth 2.6 */

a.more {
	display: flex;
	height: max-content;
	align-self: end;
	width: max-content;
	justify-self: center;
	justify-content: center;
	align-items: center;
	padding: 2px 20px;
	border-radius: 1em;
	transition: unset;
	font-size: small;
	text-transform: uppercase;
}
a.more svg {
	padding: 0 5px;
}

@media only screen and (max-width: 700px) {
	.container {
		grid-template-columns: 1fr;
	}
	.section > table {
		margin-bottom: 0px;
	}
	.section > table thead {
		display: table-header-group;
	}
	.section > table th {
		width: 100%;
		display: flex;
	}
	.section > table td {
		width: 100%;
		display: flex;
		text-align: center;
	}
}
.sectionHeader {
	background-color: rgba(135, 207, 235, 0.5);
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	border-radius: 15px;
	overflow: hidden;
}

.expando {
	cursor: pointer;
}

.status {
	color: #43853d;
	display: flex;
	justify-content: center;
	padding: 0 10px 0.5em;
}
.status > svg {
	font-size: 2em;
}
.status:not(.online) {
	color: darkgrey;
}