/* Depth: 3 */

.content {
	font-weight: 300;
}

/* Depth: 4 */

.fieldSet {
	border-radius: 15px;
	display: grid;
	row-gap: 10px;
	margin: 20px 0;
	padding: 20px;
	box-sizing: border-box;
	border: 1px solid grey;
}

.list {
	display: grid;
	row-gap: 10px;
	margin: 1em;
	max-width: 500px;
}

/* Depth: 5.0 */

.list a, .list div {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 10px;
}

/* Depth: 5.1 */

.fieldSet *:not(legend) {
	width: 100%;
	margin: 10px 0;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 15px;
}

.fieldSet input, .fieldSet input:focus, .fieldSet select:focus, .fieldSet textarea:focus {
	border: 1px solid grey;
	outline: none;
}
.fieldSet legend {
	font-family: Josefin Sans;
	font-size: 20px;
}
.fieldSet input, .fieldSet select, .fieldSet textarea, .fieldSet input::placeholder, .fieldSet select::placeholder, .fieldSet textarea::placeholder {
	font-family: Josefin Sans;
	font-size: 16px;
}

.textArea {
	min-height: 25vh;
}

.submit {
	outline: none;
	border: none;
}