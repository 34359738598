

/* Table business */

table {
	box-sizing: border-box;
}

table thead tr {
	box-sizing: border-box;
	border-radius: 15px;
	overflow: hidden;
}
table thead td, table thead tr:not([class*="sizer"]) th {
	padding: 12px 3px;
	box-sizing: border-box;
	background-color: var(--col-accent2);
	font-family: Josefin Sans;
	vertical-align: middle;
}
div#root:not(.dark) table thead th {
	color: black;
}
.dark table thead td, .dark table thead th {
	color: white;
}


table thead td:first-child, table thead th:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
table thead td:last-child, table thead th:last-child  {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}


table.download-table tbody::before {
    content: '@';
    display: block;
    line-height: 1em;
	color: transparent;
	opacity: 0;
}
table tbody tr.odd {
    background-color: rgba(220, 220, 220, 0.8);
}
.dark table tbody tr.odd {
    background-color: rgba(220, 220, 220, 0.2);
}
table tbody tr.even {
    background-color: rgba(255, 255, 255, 0.8);
}
.dark table tbody tr.even {
    background-color: rgba(255, 255, 255, 0.1);
}
table tbody tr:first-child td:first-of-type {
	border-top-left-radius: 15px;
}
table tbody tr:first-child td:last-child {
	border-top-right-radius: 15px;
}
table tbody tr:last-child td:first-of-type {
	border-bottom-left-radius: 15px;
}
table tbody tr:last-child td:last-child {
	border-bottom-right-radius: 15px;
}
table tbody tr td:first-child {
	padding-left: 8px;
}
table tbody tr td:last-child {
	padding-right: 8px;
}

.collapsed {
	display: none;
}