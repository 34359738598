.container {
    max-width: 1180px;
	margin: 0 20px 50px;
	box-sizing: border-box;
	overflow: hidden;

	display: grid;
	column-gap: 25px;
	row-gap: 25px;
	grid-template-columns: 1fr 1fr;
	font-family: 'Josefin Sans', sans-serif;
}
.title {
	grid-column: 1 / span 2;
	grid-row: 1 / span 1;
	padding: 10px;
	margin-top: 60px;
}
.title h1 {
	margin: 20px 0;
}
.title h3 {
	margin: 0;
}

.grid {
	display: grid;
	row-gap: 5px;
}

.details {
	grid-area: Details;
	display: flex;
	flex-direction: column;
}
.details > * {
	padding: 10px 0;
}
.flag {
	display: flex;
	height: 50px;
	align-items: center;
}
.flag > img {
	width: unset;
	max-width: unset;
	height: 100%;
	border-radius: 5px;
	margin-right: 20px;
}

/* Contact details */

.info {
	display: grid;
	grid-template-rows: 1fr auto;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        'Name . Buttons'
        'Details . Buttons';
}

.contact {
	grid-area: Buttons;
	display: grid;
	grid-auto-rows: 2em;
	row-gap: 10px;
	column-gap: 10px;
	justify-content: right;
	align-items: center;
	text-align: center;
	transform: scaleY(-1);
	direction: rtl;
}
.contact > * {
	transform: scaleY(-1);
	direction: ltr;
}
.contact h1 {
	margin: 0;
	text-align: left;
}
.notes {
    border-radius: 5px;
    justify-content: center;
    align-items: center;
	display: flex;
    background-color: hsla(0,0%,58.8%,.68);
	font-weight: 700;
	font-size: small;
	box-sizing: border-box;
	grid-row: span 2;
	align-self: baseline;
	width: 68.1%;
	padding: 10px 40px;
}

/* Tournaments */

.background {
	grid-column: 1 / span 2;
	width: unset;
	overflow: hidden;
}

.stage {
	position: relative;
	display: grid;
	column-gap: 20px;
	row-gap: 20px;
	grid-template-columns: 1fr 21.3% 30.8%;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		'name name history'
		'scores midButtons history'
		'action action history'
}
.teamStage {
	position: relative;
	display: grid;
	column-gap: 20px;
	row-gap: 20px;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'name name'
		'action action'
}
.midButtons {
	display: grid;
	row-gap: 20px;
}

a.box, div.box {
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 7px;
}
div.box {
	cursor: default;
}

a.name{
	display: flex;
    background-color: hsla(0,0%,58.8%,.68);
    font-weight: 700;
	grid-area: name;
	font-size: 24px;
	color: black;
}

div.history {
	grid-area: history;
	display: grid;
	grid-auto-rows: 1fr;
	grid-template-columns: 100%;
	background-color: hsla(0,0%,58.8%,.31);
	align-items: start;
	row-gap: 5px;
}
.resultsRow {
	display: grid;
	grid-template-columns: 2ch auto repeat(3, 2ch);
	column-gap: 5px;
	padding: 5px;
	box-sizing: border-box;
	background-color: darkgrey;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	font-size: 90%;
}
.resultsRow.small {
	grid-template-columns: 2ch auto repeat(2, 2ch);
}
.resultsRow > * {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 5px;
	border-radius: 5px;
}
.resultsRow:hover > a.fieldBox:not(:empty) {
    color: #fff;
    background-color: #43853d
}
.subtitle {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	text-align: right;
	font-weight: 700;
	font-size: 22px;
}

.scores {
	grid-area: scores;
	background-color: hsla(0,0%,58.8%,.31);
	display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    font-size: 18px;
}
.row {
	display: grid;
	grid-template-columns: [key] 1fr [value] auto;
}
.key {
	grid-column: key;
	padding: 0 10px;
	font-weight: 300;
}
.value {
	grid-column: value;
	padding: 0 10px;
}

.action {
	grid-area: action;
	display: grid;
	grid-auto-flow: column;
	column-gap: 10px;
}
.action > a:global(.header-button), .action > div:global(.header-button) {
	border-radius: 10px;
	display: flex;
	align-items: center;
	padding: 3px 5px;
}
.action > div:global(.header-button) {
	cursor: default;
}
.action > :global(.header-button) > * {
	margin: 0 10px;
}

/* Teams */

.inactive {
	opacity: 0.68;
}

/* Flags */

.flag {
	position: relative;
	top: -35px;
	height: 80px;
	display: flex;
	justify-content: center;
	border-radius: 10px;
}
.small .flag {
	height: 50px;
}
.flag > img {
	width: unset;
	max-width: unset;
	height: 100%;
	border-radius: 10px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}
