.container {
	position: relative;
}

.header {
	display: flex;
	flex-direction: row;
	position: relative;
	height: unset;
}
div.header:hover:not(.sent) {	
	color: var(--col-accent1-hover);
}


.wrapper {
	position: absolute;
	overflow: hidden;
	width: 100%;
	transition: height 0.33s ease;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	z-index: 1;
}

.list {
	margin: 0px;
	padding: 0px;
	width: 100%;
	display: grid;
	row-gap: 1px;
}

.list li {
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: var(--col-accent2);
	padding: 5px;
	box-sizing: border-box;
	cursor: pointer;
	font-weight: 600;
}

.list li:hover {
	color: var(--col-accent1-hover);
	background-color: var(--col-accent2-hover);
}