.score {
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 700px) {
	.score {
		min-height: 20px;
	}
}

.confirmed {
	color: var(--col-accent4);
	background-color: gold;
	font-weight: bold;
}
.finished {
	background-color: #43853d;
	color: white;
}
.live {
	background-color: rgb(73, 73, 255);
	color: white;
	font-weight: 400;
}
.watchLive, div.watchLive {
	background-color: rgb(73, 73, 255);
}
.watchLive:global(.button) > a {
	color: gold;
}
.registered {
	background-color: turquoise;
}
.scheduled {
	background-color: turquoise;
}
.pending {
	background-color: grey;
}
.aborted {
	background-color: var(--col-alert1);
}

.pairingRound.roundSelected {
	background-color: cyan;
}

a.pairingRound.nullRound, a.pairingRound.nullRound:hover > * {
	background-color: lightgrey;
	color: grey;
	cursor: default;
}

td.inner {
	padding: 0;
}

.subheader {
	display: grid;
	grid-template-columns: 45% 2fr 3fr;
	column-gap: 5%;
	margin: 20px 0;
}
.subheader, .subheader input {
	font-family: Josefin sans, sans-serif;
}

@media screen and (max-width: 700px) {
	.subheader {
		grid-template-columns: unset;
		grid-auto-flow: row;
		row-gap: 20px;
	}
}

.subheader > div {
	display: flex;
	width: 100%;
}

.subheader > div:first-child {
	align-items: center;
	justify-content: flex-start;
}

.subheader > div:last-child {
	justify-content: flex-end;
}

.pieMatrix {
	display: grid;
	row-gap: 10px;
	grid-template-columns: repeat(4, 1fr) minmax(auto, 480px) repeat(4, 1fr);
	grid-template-areas:
		'timezone timezone timezone timezone pie . search search search'
		'download download download . pie . expandAll expandAll expandAll'
		'. . . . pie . jumpTo jumpTo jumpTo'
		'. . . . pie .  picker picker picker'
		'. . . . pie . . . .'
}
@media only screen and (max-width: 700px) {
	.pieMatrix {
		grid-template-columns: 1fr;
		grid-template-areas:
			'pie'
			'search'
			'timezone'
			'download'
			'jumpTo'
			'expandAll'
			'picker';
	}
}
.pieMatrix > :not(.pie) {
	z-index: 1;
}
.pieMatrix :global(.button) {
	display: grid;
	grid-template-columns: minmax(1.5ch, auto) 1fr minmax(1.5ch, auto);
	text-align: center;
	justify-content: center;
	align-items: center;
}

div.statusPicker {
	grid-area: picker;
}
div.statusPicker > div:global(.button) {
	border-radius: 10px;
}
.pie {
	grid-column: timezone / search;
	grid-row: pie;
}
div.timezone {
	grid-area: timezone;
	font-size: smaller;
}
div.timezone svg {
	font-size: normal;
}
div.download {
	grid-area: download;
}
div.jumpTo {
	grid-area: jumpTo;
}
div.expandAll {
	grid-area: expandAll;
}
div.timezone, div.download, div.jumpTo, div.expandAll, .statusPicker > div {
	border-radius: 10px;
	padding: 0 5px;
	box-sizing: border-box;
}

.searchBar {
	grid-area: search;
	display: flex;
	flex-direction: row;
	background-color: var(--col-accent2);
	align-items: center;
	justify-content: stretch;
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	padding: 0 10px;
}

.searchBar > * {
	height: 100%;
	display: flex;
	align-items: center;
}
.searchBar * {
	background-color: transparent;
}
.searchBar form {
	padding: 2px 0;
}

.searchBar div, .searchBar form, .searchBar input {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.searchBar input, .searchBar input:focus {
	border: none;
	margin: 5px 0;
}

.description {
	padding: 10px 0;
	font-family: Josefin Sans;
}

@media only screen and (max-width: 700px) {
	div.buttonList {
		grid-template-columns: unset;
	}
}

.flag {
	box-sizing: border-box;
	max-height: 2.5em;
	display: flex;
	justify-content: center;
}

.join {
	grid-column: 1 / span 3;
	grid-row: 4 / span 1;
	border: none;
	border-radius: 10px;
	overflow: hidden;
}

.join > div {
	margin: 0px;
	grid-template-rows: unset;
	padding: 7px 0;
	display: flex;
}

.pool {
	text-align: center;
	font-weight: bold;
}

.table {
	width: 72%;
}
.table tbody::before {
    content: '@';
    display: block;
    line-height: 2em;
	color: transparent;
	opacity: 0;
}
@media only screen and (max-width: 700px) {
	.table {
		width: 100%;
	}
}

a.watchButton {
	color: white;
}